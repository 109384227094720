import React, { useEffect, useMemo, useState } from 'react'

import { Link } from 'react-router-dom'

import { Box, Divider, useMediaQuery } from '@mui/material'

import Button from '../../../../../../../common/components/atoms/Button'
import Heading from '../../../../../../../common/components/atoms/Heading'
import Modal from '../../../../../../../common/components/atoms/Modal'
import Text from '../../../../../../../common/components/atoms/Text'
import Markdown from '../../../../../../../common/components/molecules/Markdown'
import amplitude from '../../../../../../../common/lib/amplitude'
import {
  themeColors,
  themeColorTypes,
  themeColorVariants,
} from '../../../../../../../common/styles/muiTheme'
import { InvestmentAccountTypes } from '../../../../../../Questionnaire/components/Question/InvestmentAccounts/utils/getInvestmentAccountsOptions'
import {
  BaseInformationalModalData,
  InformationalModalData,
} from '../../types/informationalModalData.types'
import isTriggered from '../../utils/isTriggered'

interface Props {
  show: boolean
  triggerObj: any
  onClose: () => void
  data: InformationalModalData
  answerStore: any
}

const InformationalModal = ({
  triggerObj,
  show,
  onClose,
  data,
  answerStore,
}: Props) => {
  const [triggeredData, setTriggeredData] =
    useState<BaseInformationalModalData | null>(null)

  useEffect(() => {
    /*
     * Because 'data' can be an array of objects (with a trigger property)
     * or a single object (without a trigger property), we need to determine
     * if 'data' is an array, and if so, find the first object that is triggered,
     * or select the last object in the array if none are triggered.
     */
    if (Array.isArray(data)) {
      const theTriggeredData = data.find(({ trigger }) =>
        isTriggered(triggerObj)(trigger),
      )
      setTriggeredData(theTriggeredData || data[data.length - 1])
    } else {
      setTriggeredData(data)
    }
  }, [data, triggerObj])

  const amplitudeEventProps = useMemo(() => {
    const {
      hasResps,
      hasLifeInsurance,
      hasForeignProperty,
      canadianRealEstate,
      investmentAccounts = [],
    } = answerStore

    return {
      hasResps,
      hasLifeInsurance,
      hasForeignProperty,
      canadianRealEstate,
      hasRrsp: investmentAccounts.includes(InvestmentAccountTypes.RRSP),
      hasFhsa: investmentAccounts.includes(InvestmentAccountTypes.FHSA),
      hasTfsa: investmentAccounts.includes(InvestmentAccountTypes.TFSA),
      hasRrif: investmentAccounts.includes(InvestmentAccountTypes.RRIF),
    }
  }, [answerStore])

  useEffect(() => {
    if (show) {
      amplitude.sendEvent('ViewedInformationalModal', {
        header: triggeredData?.header?.label,
        article: triggeredData?.article?.label,
        ...amplitudeEventProps,
      })
    }
  }, [amplitudeEventProps, show, triggeredData])

  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('lg'))
  const ARTICLE_IMAGE_WIDTH = isMobile ? '100%' : '39.5%'

  return (
    <Modal
      show={show}
      maxWidth="lg"
      onClose={onClose}
      data-testid="informational-modal"
    >
      <Box
        sx={{
          pb: '6rem',
          pt: '1.5rem',
          px: isMobile ? '0.55rem' : '1.4rem',
        }}
      >
        {triggeredData && (
          <>
            {/* Header */}
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              {triggeredData.header.icon && (
                <Box
                  component="img"
                  alt="Information Modal Icon"
                  src={triggeredData.header.icon}
                  sx={{
                    height: triggeredData.header?.iconMaxWidth
                      ? undefined
                      : '5.5rem',
                    mb: '1.2rem',
                    maxWidth: triggeredData.header?.iconMaxWidth,
                  }}
                />
              )}
              <Heading align="center" variant="h2">
                {triggeredData.header.label}
              </Heading>

              {triggeredData.header.subLabel && (
                <Text align="center" variant="paragraph" sx={{ mt: '0.5rem' }}>
                  {triggeredData.header.subLabel}
                </Text>
              )}
            </Box>

            <Box sx={{ mt: '1.6rem', mb: '2rem' }}>
              <Divider />
            </Box>

            {/* Body */}
            {React.Children.toArray(
              triggeredData.body.map(
                ({ label, children, buttons, buttonsSubLabel }) => (
                  <Box>
                    {label && (
                      <Heading sx={{ mb: '1.5rem' }} variant="h4">
                        {label}
                      </Heading>
                    )}

                    {React.Children.toArray(
                      children.map((childItem) => (
                        <Box sx={{ mt: '1.85rem' }}>
                          {childItem.label && (
                            <Text
                              size="lg"
                              weight="medium"
                              sx={{ mb: '0.55rem' }}
                            >
                              {childItem.label}
                            </Text>
                          )}
                          {childItem.body && (
                            <>
                              {React.Children.toArray(
                                childItem.body.map((text, index) => (
                                  <Text
                                    size="sm"
                                    sx={{
                                      lineHeight: '1.4',
                                      mt: index !== 0 ? '0.85rem' : '0',
                                    }}
                                  >
                                    {text}
                                  </Text>
                                )),
                              )}
                            </>
                          )}
                          {childItem.markdown && (
                            <Markdown>{childItem.markdown}</Markdown>
                          )}
                        </Box>
                      )),
                    )}

                    {buttons && (
                      <Box
                        sx={{
                          mt: '2.5rem',
                          width: '100%',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          flexDirection: isMobile ? 'column' : 'row',
                        }}
                      >
                        {React.Children.toArray(
                          buttons.map((button) => (
                            <Link
                              to={button.url}
                              target="_blank"
                              rel="noopener noreferrer"
                              onClick={() => {
                                amplitude.sendEvent(
                                  'ClickedInformationalModalButton',
                                  {
                                    url: button.url,
                                    ...amplitudeEventProps,
                                  },
                                )
                              }}
                            >
                              <Button
                                size="sm"
                                variant="contained"
                                color={button.color}
                                label={button.label}
                                endIcon={button.endIcon}
                                colorVariant={button.colorVariant}
                                sx={{ m: '0.35rem' }}
                              />
                            </Link>
                          )),
                        )}
                      </Box>
                    )}

                    {buttonsSubLabel && (
                      <Text
                        size="xxs"
                        align="center"
                        color={themeColorTypes.GREY}
                        colorVariant={themeColorVariants.MAIN}
                        sx={{ mt: '0.65rem', cursor: 'default' }}
                      >
                        {buttonsSubLabel}
                      </Text>
                    )}
                  </Box>
                ),
              ),
            )}

            {/* Article */}
            {triggeredData.article && (
              <Box
                component="a"
                target="_blank"
                rel="noopener noreferrer"
                href={triggeredData.article.url}
                onClick={() => {
                  amplitude.sendEvent('ClickedInformationalModalArticle', {
                    url: triggeredData?.article?.url,
                    ...amplitudeEventProps,
                  })
                }}
                sx={{
                  mx: 'auto',
                  mt: '2.4rem',
                  display: 'flex',
                  cursor: 'pointer',
                  maxWidth: isMobile ? '340px' : '100%',
                  flexDirection: isMobile ? 'column' : 'row',
                  borderRadius: (theme: any) => theme.shape.borderRadiusLarge,
                  border: `1px solid ${
                    themeColors[themeColorTypes.GREY][
                      themeColorVariants.LIGHTER
                    ]
                  }`,
                }}
              >
                <Box sx={{ width: ARTICLE_IMAGE_WIDTH }}>
                  <Box
                    alt="Article"
                    component="img"
                    src={triggeredData.article?.imgSrc}
                    sx={{ height: '100%', width: '100%', objectFit: 'cover' }}
                  />
                </Box>

                <Box
                  sx={{
                    mt: isMobile ? '0.25rem' : '0',
                    p: isMobile ? '1.2rem' : '1.4rem',
                    width: isMobile
                      ? '100%'
                      : `calc(100% - ${ARTICLE_IMAGE_WIDTH})`,
                  }}
                >
                  <Text
                    weight="medium"
                    sx={{ '&:hover': { textDecoration: 'underline' } }}
                  >
                    {triggeredData.article.label}
                  </Text>

                  {triggeredData.article.description && (
                    <Text
                      variant="paragraph"
                      color={themeColorTypes.GREY}
                      colorVariant={themeColorVariants.DARKER}
                      sx={{ mt: '0.375rem', fontSize: '0.94rem' }}
                    >
                      {triggeredData.article.description}
                    </Text>
                  )}
                </Box>
              </Box>
            )}
          </>
        )}
      </Box>
    </Modal>
  )
}

export default InformationalModal
