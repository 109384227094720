"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RepeatTypes = void 0;
exports.RepeatTypes = {
    never: 'never',
    daily: 'daily',
    weekly: 'weekly',
    monthly: 'monthly',
    yearly: 'yearly',
};
