import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'

import { useQuery } from '@tanstack/react-query'

import { errorHandler, PATHS, request } from '../../../../../../common/request'
import featureFlag, {
  flagTypes,
} from '../../../../../../common/utils/featureFlag'
import { SUPPORTED_PROVINCES } from '../../../../../../common/utils/provinces'
import {
  queryKeys,
  queryKeysBase,
} from '../../../../../../common/utils/queryKeys'

export default ({
  referrer,
  province,
  dropOffType,
  flatFee = false,
  dispatchSetGlobalErrorMessage,
}) => {
  if (SUPPORTED_PROVINCES.includes(province)) {
    return useQuery({
      queryKey: queryKeys[queryKeysBase.lawyers](province),

      queryFn: async () => {
        const res = await request({
          method: 'GET',
          url: flatFee
            ? `${PATHS.LAWYER_FLAT_FEE}/${province}/${dropOffType}`
            : `${PATHS.LAWYER_ACTIVE}/${province}`,
          params: {
            ...referrer,
          },
        })

        const lawyers = _get(res, 'data.payload.lawyers', [])

        if (_isEmpty(lawyers) && featureFlag(flagTypes.SENTRY)) {
          window.captureException(
            new Error(
              `No lawyers returned for ${province} lawyer-referral page ${dropOffType}`,
            ),
          )
        }
        return lawyers
      },

      retry: process.env.REACT_APP_ENV === 'production' ? 1 : 0,
      refetchOnWindowFocus: false,

      onError: (error) => {
        errorHandler({
          error,
          reportError: true,
          onError: (resolvedErrorMessage) =>
            dispatchSetGlobalErrorMessage(resolvedErrorMessage),
        })
      },
    })
  }

  // If the user's province is not supported
  return { data: [] }
}
