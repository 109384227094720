import React, { useEffect, useMemo, useState } from 'react'

import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { Box, useMediaQuery } from '@mui/material'

import { CONSTANTS } from '@epilogue/common'
import { AnimatePresence, motion } from 'framer-motion'
import { useTranslation } from 'react-i18next'

import DocumentPageSkeleton from '../../../../../../../common/assets/images/document-page-skeleton-purple-background.svg'
import ManualPrint from '../../../../../../../common/assets/images/manual-print-purple-background.svg'
import PrintRequest from '../../../../../../../common/assets/images/print-request-purple-background.svg'
import { ReactComponent as PurpleStar } from '../../../../../../../common/assets/images/purple-star.svg'
import SignWill from '../../../../../../../common/assets/images/sign-will-purple-background.svg'
import WillDocument from '../../../../../../../common/assets/images/will-document-card-purple-background.svg'
import Button from '../../../../../../../common/components/atoms/Button'
import Checkbox from '../../../../../../../common/components/atoms/Checkbox'
import Heading from '../../../../../../../common/components/atoms/Heading'
import Modal from '../../../../../../../common/components/atoms/Modal'
import Text from '../../../../../../../common/components/atoms/Text'
import StepDots from '../../../../../../../common/components/molecules/StepDots'
import usePreloadImages from '../../../../../../../common/hooks/usePreloadImages'
import useUpdateToggles from '../../../../../../../common/hooks/useUpdateToggles'
import amplitude from '../../../../../../../common/lib/amplitude'
import {
  themeColors,
  themeColorTypes,
  themeColorVariants,
} from '../../../../../../../common/styles/muiTheme'

const animateDistance = 350
const variants = {
  start: (direction: number) => ({
    opacity: 0,
    x: direction > 0 ? animateDistance : -animateDistance,
  }),
  enter: {
    x: 0,
    zIndex: 1,
    opacity: 1,
  },
  leave: (direction: number) => ({
    zIndex: 0,
    opacity: 0,
    x: direction < 0 ? animateDistance : -animateDistance,
  }),
}

const preloadedImages = [
  WillDocument,
  PrintRequest,
  ManualPrint,
  SignWill,
  DocumentPageSkeleton,
]

interface Step {
  img: string
  label: string
  body: string[]
  interceptCheckboxLabel?: string
}

const steps = ({
  answerStore,
  hasPrintCredit,
}: {
  answerStore: any
  hasPrintCredit: boolean
}): Step[] => {
  const { product } = answerStore
  return [
    {
      label: 'Step 1: Review your documents',
      body: [
        `Review ${
          product === 'willPoa' ? "the documents you've created" : 'your Will'
        } by using the "Download" or "Preview" buttons. If you need to make changes to your ${
          product === 'willPoa' ? 'documents' : 'Will'
        }, use the "Edit" button.`,
      ],
      img: WillDocument,
    },
    {
      label: 'Step 2: Print your documents',
      body: hasPrintCredit
        ? [
            `You've paid for your ${
              product === 'willPoa' ? 'Will and Incapacity Documents' : 'Will'
            } to be printed and mailed to you. When you're ready for us to do that, click the "Print + Send" button and follow the instructions.`,
          ]
        : [
            `You've chosen to print your ${
              product === 'willPoa' ? 'documents' : 'Will'
            } yourself. Don't forget this important step.`,
          ],
      img: hasPrintCredit ? PrintRequest : ManualPrint,
    },
    {
      label: 'Step 3: Sign your documents',
      body: [
        `We have included step-by-step signing instructions with your ${
          product === 'willPoa' ? 'Will and Incapacity Documents' : 'Will'
        }. Follow the instructions carefully to ensure that your ${
          product === 'willPoa' ? 'documents are' : 'Will is'
        } legally binding.`,
      ],
      interceptCheckboxLabel: 'I understand',
      img: SignWill,
    },
    {
      label: 'Step 4: Explore your estate plan',
      body: [
        `A comprehensive estate plan includes more than just a ${
          product === 'willPoa' ? 'Will and Incapacity Documents' : 'Will'
        }. Explore your estate plan to learn about other estate and financial planning opportunities.`,
      ],
      img: DocumentPageSkeleton,
    },
  ]
}

interface Props {
  show: boolean
  token: string
  answerStore: any
  onClose: () => void
  hasPrintCredit: boolean
  questionnaireId: string
}

const DocumentsGuideModal = ({
  show,
  token,
  onClose,
  answerStore,
  hasPrintCredit,
  questionnaireId,
}: Props) => {
  const { t } = useTranslation()

  const { mutate: handleUpdateToggles } = useUpdateToggles()

  usePreloadImages({ imageUrls: preloadedImages })

  const resolvedSteps = useMemo(
    () => [...steps({ answerStore, hasPrintCredit })],
    [answerStore, hasPrintCredit],
  )

  const [direction, setDirection] = useState(1)
  const [activeStep, setActiveStep] = useState(0)

  const isFirstStep = activeStep === 0
  const isLastStep = activeStep === resolvedSteps.length - 1

  const [interceptCheckboxesChecked, setInterceptCheckboxesChecked] = useState<
    number[]
  >([])
  const [checkboxIsInvalid, setCheckboxIsInvalid] = useState(false)

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
    if (checkboxIsInvalid) {
      setCheckboxIsInvalid(false)
    }
  }

  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('lg'))

  useEffect(() => {
    if (show)
      amplitude.sendEvent('ViewedDocumentsGuideModal', {
        hasPrintCredit,
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show])

  return (
    <Modal
      fullBleed
      show={show}
      preventClose
      maxWidth="md"
      onClose={onClose}
      data-testid="documents-guide-modal"
    >
      <Box
        sx={{
          width: isMobile ? '100%' : (theme) => theme.breakpoints.values.md,
        }}
      >
        <AnimatePresence initial={false} custom={direction} exitBeforeEnter>
          <Box
            sx={{
              width: '100%',
              height: isMobile ? '100%' : '330px',
              backgroundColor:
                themeColors[themeColorTypes.ACCENT_2][
                  themeColorVariants.LIGHTEST
                ],
            }}
          >
            <motion.img
              key={activeStep}
              src={resolvedSteps[activeStep].img}
              custom={direction}
              variants={variants}
              initial="start"
              animate="enter"
              exit="leave"
              transition={{
                x: { type: 'spring', stiffness: 280, damping: 30 },
                opacity: { duration: 0.3 },
              }}
            />
          </Box>
        </AnimatePresence>

        {/* White background area */}
        <Box
          sx={{
            px: '2rem',
            py: '1rem',
            height: '290px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <Box>
            <Box
              sx={{
                mb: '1rem',
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
            >
              <Box sx={{ mb: '0.9rem' }}>
                <StepDots
                  preventDotClick
                  steps={resolvedSteps.length}
                  activeStep={activeStep}
                />
              </Box>

              <Heading align="center" variant={isMobile ? 'h4' : 'h3'}>
                <PurpleStar
                  style={{
                    width: '19px',
                    bottom: '3px',
                    display: 'inline',
                    position: 'relative',
                    marginRight: '0.4rem',
                  }}
                />
                {resolvedSteps[activeStep].label}
              </Heading>
            </Box>

            <Box sx={{ maxWidth: '445px', mx: 'auto' }}>
              {React.Children.toArray(
                resolvedSteps[activeStep].body.map((text) => (
                  <Text
                    size="sm"
                    align="center"
                    sx={{ mt: '0.4rem', lineHeight: '1.5' }}
                  >
                    {text}
                  </Text>
                )),
              )}
            </Box>
          </Box>

          {resolvedSteps[activeStep].interceptCheckboxLabel && (
            <Box
              sx={{
                mb: '0.8rem',
                display: 'flex',
                alignItems: 'center',
                position: 'relative',
                justifyContent: 'center',
              }}
            >
              <Checkbox
                isInvalid={checkboxIsInvalid}
                checked={interceptCheckboxesChecked.includes(activeStep)}
                label={
                  resolvedSteps[activeStep].interceptCheckboxLabel ||
                  'I understand'
                }
                onChange={(checkStatus: boolean) => {
                  amplitude.sendEvent('CheckedSignReminder')

                  if (checkboxIsInvalid) {
                    setCheckboxIsInvalid(false)
                  }

                  if (checkStatus) {
                    setInterceptCheckboxesChecked([
                      ...interceptCheckboxesChecked,
                      activeStep,
                    ])
                  } else {
                    setInterceptCheckboxesChecked(
                      interceptCheckboxesChecked.filter(
                        (step) => step !== activeStep,
                      ),
                    )
                  }
                }}
              />

              {checkboxIsInvalid && (
                <Text
                  sx={{
                    right: '50%',
                    width: '100%',
                    bottom: '-11px',
                    textAlign: 'center',
                    position: 'absolute',
                    transform: 'translateX(50%)',
                  }}
                  size="xxs"
                  color={themeColorTypes.RED}
                >
                  *{t('validation:validation.checkBoxAbove')}
                </Text>
              )}
            </Box>
          )}

          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Button
              size="xxs"
              label="Back"
              onClick={() => {
                amplitude.sendEvent('ClickedDocumentsGuideModalBackButton')
                handleBack()
                setDirection(-1)
              }}
              iconFontSize="0.9rem"
              color={themeColorTypes.GREY}
              colorVariant={themeColorVariants.DARK}
              startIcon={<ArrowBackIcon sx={{ mr: '-0.2rem' }} />}
              sx={{ visibility: isFirstStep ? 'hidden' : 'visible' }}
            />

            <Button
              variant="contained"
              iconFontSize="1.15rem"
              color={themeColorTypes.ACCENT_2}
              colorVariant={themeColorVariants.MAIN}
              label={isLastStep ? 'Got it!' : 'Next'}
              data-testid="documents-guide-modal-primary-button"
              onDisabledClick={() => {
                if (!checkboxIsInvalid) {
                  setCheckboxIsInvalid(true)
                }
              }}
              disabled={
                !!(
                  resolvedSteps[activeStep].interceptCheckboxLabel &&
                  !interceptCheckboxesChecked.includes(activeStep)
                )
              }
              onClick={() => {
                if (isLastStep) {
                  amplitude.sendEvent('ClickedAcceptDocumentsGuideModal')
                  onClose()
                  handleUpdateToggles({
                    token,
                    questionnaireId,
                    toggleOn: [
                      CONSTANTS.toggleTypes
                        .HAS_ACCEPTED_STEPPED_DOCUMENTS_MODAL,
                    ],
                  })
                } else {
                  amplitude.sendEvent('ClickedDocumentsGuideModalForwardButton')
                  handleNext()
                  setDirection(1)
                }
              }}
              endIcon={
                !isLastStep && <ArrowForwardIcon sx={{ ml: '-0.15rem' }} />
              }
            />
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}

export default DocumentsGuideModal
