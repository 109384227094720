"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const provinces = {
    ALBERTA: 'Alberta',
    BRITISH_COLUMBIA: 'British Columbia',
    MANITOBA: 'Manitoba',
    NEW_BRUNSWICK: 'New Brunswick',
    NEWFOUNDLAND_AND_LABRADOR: 'Newfoundland and Labrador',
    NORTHWEST_TERRITORIES: 'Northwest Territories',
    NOVA_SCOTIA: 'Nova Scotia',
    NUNAVUT: 'Nunavut',
    ONTARIO: 'Ontario',
    PRINCE_EDWARD_ISLAND: 'Prince Edward Island',
    QUEBEC: 'Quebec',
    SASKATCHEWAN: 'Saskatchewan',
    YUKON: 'Yukon',
};
const provinceAlphaCodes = {
    [provinces.ALBERTA]: 'AB',
    [provinces.BRITISH_COLUMBIA]: 'BC',
    [provinces.MANITOBA]: 'MB',
    [provinces.NEW_BRUNSWICK]: 'NB',
    [provinces.NEWFOUNDLAND_AND_LABRADOR]: 'NL',
    [provinces.NORTHWEST_TERRITORIES]: 'NT',
    [provinces.NOVA_SCOTIA]: 'NS',
    [provinces.NUNAVUT]: 'NU',
    [provinces.ONTARIO]: 'ON',
    [provinces.PRINCE_EDWARD_ISLAND]: 'PE',
    [provinces.QUEBEC]: 'QC',
    [provinces.SASKATCHEWAN]: 'SK',
    [provinces.YUKON]: 'YT',
};
const states = {
    ALABAMA: 'Alabama',
    ALASKA: 'Alaska',
    ARIZONA: 'Arizona',
    ARKANSAS: 'Arkansas',
    CALIFORNIA: 'California',
    COLORADO: 'Colorado',
    CONNECTICUT: 'Connecticut',
    DELAWARE: 'Delaware',
    FLORIDA: 'Florida',
    GEORGIA: 'Georgia',
    HAWAII: 'Hawaii',
    IDAHO: 'Idaho',
    ILLINOIS: 'Illinois',
    INDIANA: 'Indiana',
    IOWA: 'Iowa',
    KANSAS: 'Kansas',
    KENTUCKY: 'Kentucky',
    LOUISIANA: 'Louisiana',
    MAINE: 'Maine',
    MARYLAND: 'Maryland',
    MASSACHUSETTS: 'Massachusetts',
    MICHIGAN: 'Michigan',
    MINNESOTA: 'Minnesota',
    MISSISSIPPI: 'Mississippi',
    MISSOURI: 'Missouri',
    MONTANA: 'Montana',
    NEBRASKA: 'Nebraska',
    NEVADA: 'Nevada',
    NEW_HAMPSHIRE: 'New Hampshire',
    NEW_JERSEY: 'New Jersey',
    NEW_MEXICO: 'New Mexico',
    NEW_YORK: 'New York',
    NORTH_CAROLINA: 'North Carolina',
    NORTH_DAKOTA: 'North Dakota',
    OHIO: 'Ohio',
    OKLAHOMA: 'Oklahoma',
    OREGON: 'Oregon',
    PENNSYLVANIA: 'Pennsylvania',
    RHODE_ISLAND: 'Rhode Island',
    SOUTH_CAROLINA: 'South Carolina',
    SOUTH_DAKOTA: 'South Dakota',
    TENNESSEE: 'Tennessee',
    TEXAS: 'Texas',
    UTAH: 'Utah',
    VERMONT: 'Vermont',
    VIRGINIA: 'Virginia',
    WASHINGTON: 'Washington',
    WEST_VIRGINIA: 'West Virginia',
    WISCONSIN: 'Wisconsin',
    WYOMING: 'Wyoming',
};
const relationshipValues = {
    MY_PARTNER: 'my partner',
    MY_MOTHER: 'my mother',
    MY_FATHER: 'my father',
    MY_SISTER: 'my sister',
    MY_BROTHER: 'my brother',
    MY_DAUGHTER: 'my daughter',
    MY_SON: 'my son',
    MY_STEPCHILD: 'my stepchild',
    MY_SISTER_IN_LAW: 'my sister-in-law',
    MY_BROTHER_IN_LAW: 'my brother-in-law',
    MY_MOTHER_IN_LAW: 'my mother-in-law',
    MY_FATHER_IN_LAW: 'my father-in-law',
    MY_DAUGHTER_IN_LAW: 'my daughter-in-law',
    MY_SON_IN_LAW: 'my son-in-law',
    MY_GRANDCHILD: 'my grandchild',
    MY_FRIEND: 'my friend',
    MY_AUNT: 'my aunt',
    MY_UNCLE: 'my uncle',
    MY_NIECE: 'my niece',
    MY_NEPHEW: 'my nephew',
    MY_COUSIN: 'my cousin',
    NONE: 'none',
};
const partnerValues = {
    MY_WIFE: 'my wife',
    MY_HUSBAND: 'my husband',
    MY_SPOUSE: 'my spouse',
    MY_PARTNER: 'my partner',
    MY_FIANCEE: 'my fiancee',
};
const docTypes = {
    WILL: 'will',
    PERSONAL_POA: 'personalPoa',
    PROPERTY_POA: 'propertyPoa',
    WISHES: 'wishes',
    ASSET_LIST: 'assetList',
    /*
     * Do not remove, even after COVID situation. Some users could still have
     * COVID doc in their documents array. This doc must remain defunct forever.
     */
    COVID: 'covid',
};
const toastTypes = {
    INFO: 'info',
    SUCCESS: 'success',
    WARNING: 'warning',
    ERROR: 'error',
};
const interceptActionTypes = {
    CLOSE_MODAL: 'closeModal',
    TO_QUESTION: 'toQuestion',
    NEXT_QUESTION: 'nextQuestion',
    PREVIOUS_QUESTION: 'previousQuestion',
};
const paymentOptions = {
    PAYMENT_PLAN: 'paymentPlan',
    ONE_TIME_PAYMENT: 'oneTimePayment',
};
const sessionStorageKeys = {
    PREVENT_SIGNING_MODALS: 'preventSigningModals',
    UPDATE_QUESTIONNAIRE_ERROR_COUNT: 'updateQuestionnaireErrorCount',
    DISMISSED_QUESTIONS_CHANGED_MODAL: 'dismissedQuestionsChangedModal',
};
const questionnaireSectionTypes = {
    BASICS: {
        id: 'The Basics',
        tKey: 'questionnaire.basics:section',
    },
    CHARITIES: {
        id: 'Charities',
        tKey: 'questionnaire.charities:section',
    },
    COMPLETE: {
        id: 'Complete',
        tKey: 'questionnaire.complete:section',
    },
    DISTRIBUTION: {
        id: 'Distribution',
        tKey: 'questionnaire.distribution:section',
    },
    FAMILY: {
        id: 'Family',
        tKey: 'questionnaire.family:section',
    },
    GUARDIANS: {
        id: 'Guardians',
        tKey: 'questionnaire.guardians:section',
    },
    PERSONAL_POA: {
        id: 'POA for Care',
        tKey: 'questionnaire.personalPoa:section',
    },
    PROPERTY_POA: {
        id: 'POA for Property',
        tKey: 'questionnaire.propertyPoa:section',
    },
    SOCIAL: {
        id: 'Social Media Will',
        tKey: 'questionnaire.social:section',
    },
    SOCIAL_COMPLETE: {
        id: 'Social Complete',
        tKey: 'questionnaire.socialComplete:section',
    },
    TRUSTEE: {
        id: 'Executor',
        tKey: 'questionnaire.trustee:section',
    },
};
const languageTypes = {
    EN_CA: { id: 'en-CA', label: 'English', shortLabel: 'EN' },
    FR_CA: { id: 'fr-CA', label: 'Français', shortLabel: 'FR' },
};
const docSectionTypes = {
    COVER: 'cover',
    MAIN: 'main',
    FEE_AGREEMENT: 'fee-agreement',
    ROYAL_TRUST_CONTACT: 'royal-trust-contact',
    END: 'end',
};
const docSectionFileTypes = {
    HBS: 'hbs',
    PDF: 'pdf',
};
const socialDocTypes = {
    social: {
        type: 'social',
        isFree: true,
        answerStoreUpdatedSinceGenerateType: 'socialAnswerStoreUpdatedSinceGenerate',
    },
};
const worldTypes = {
    will: {
        type: 'will',
        label: { tKey: 'common:worldTypes.will.label' },
        namespaceId: undefined,
        dashboardPath: '/dashboard',
        answerStoreType: 'answerStore',
        dashboardDescription: {
            tKey: 'common:worldTypes.will.dashboardDescription',
        },
        questionnaireDescription: {
            tKey: 'common:worldTypes.will.questionnaireDescription',
        },
        answerStoreUpdatedSinceGenerateType: 'answerStoreUpdatedSinceGenerate',
    },
    social: {
        type: 'social',
        label: { tKey: 'common:worldTypes.social.label' },
        // Don't change namespaceId, it affects field names in social.js
        namespaceId: 'social_',
        dashboardPath: '/social-dashboard',
        answerStoreType: 'socialAnswerStore',
        dashboardDescription: {
            tKey: 'common:worldTypes.social.dashboardDescription',
        },
        questionnaireDescription: {
            tKey: 'common:worldTypes.social.questionnaireDescription',
        },
        answerStoreUpdatedSinceGenerateType: 'socialAnswerStoreUpdatedSinceGenerate',
    },
};
const dropOffTypes = {
    SPOUSAL_TRUST: 'spousalTrust',
    FAMILY_DISABILITY: 'familyDisability',
    NOT_RIGHT_FOR_ME: 'notRightForMe',
    FOREIGN_PROPERTY: 'foreignProperty',
};
const cookiePrefixes = {
    PRODUCTION: 'epi-',
    STAGING: 'epi_staging-',
    DEVELOPMENT: 'epi-',
};
const cookieNames = {
    SESSION: 'episid',
    STAGING_SESSION: 'episid_staging',
    REF: 'ref',
    REFTAG: 'reftag',
    QID: 'qid',
    TOKEN: 'token',
    UTM_SOURCE: 'utm-source',
    UTM_MEDIUM: 'utm-medium',
    UTM_CAMPAIGN: 'utm-campaign',
    UTM_CONTENT: 'utm-content',
    UTM_ID: 'utm-id',
    UTM_TERM: 'utm-term',
};
/*
 * This a list of the questionnaire properties that may come from the backend
 * that are safe to store in Redux immediately upon.
 *
 * There are other questionnaire properties that get stored to Redux, but they are
 * generated by the front-end of the app
 */
const acceptedIncomingQuestionnaireProperties = [
    'token',
    'abTest',
    'referrer',
    'answerStore',
    'answerCache',
    'accountCreated',
    'questionnaireId',
    'questionnairesStarted',
    'questionnaireFirstCompleted',
    'answerStoreUpdatedSinceGenerate',
];
/*
 * The User object has a 'codes' field that takes an array of objects.
 * The objects in the 'codes' array contains a property of 'label', these
 * are the potential options for that label
 */
const codeLabels = {
    // TAG: notice connect noticeConnect notice-connect
    NOTICE_CONNECT: 'notice-connect',
    CUSTOMER_REFERRAL: 'customer-referral',
    PERSONAL_DROP_OFF: 'personal-drop-off',
};
const corporateExecutorTypes = {
    EXECUTOR: 'executor',
    CO_EXECUTOR: 'coExecutor',
    ALT_EXECUTOR: 'altExecutor',
};
const productTypes = {
    WILL: 'will',
    WILL_POA: 'willPoa',
};
const oauth = {
    providers: { GOOGLE: 'Google' },
    types: { LOGIN: 'login', CREATE_ACCOUNT: 'createAccount' },
};
const sidebar = {
    types: {
        ACCORDION: 'accordion',
        TEXT: 'text',
        BUBBLE: 'bubble',
        YOUTUBE: 'youtube',
        IMAGE: 'image',
        LIST: 'list',
        RT_CALCULATOR: 'rtCalculator',
        // TAG: Will Power
        WILL_POWER_CALCULATOR: 'willPowerCalculator',
        CORPORATE_EXECUTOR_CONTACT_FORM: 'corporateExecutorContactForm',
    },
    bubbleTypes: {
        ALERT: 'alert',
        TIP: 'tip',
    },
    listTypes: {
        ORDERED: 'ordered',
        UNORDERED: 'unordered',
    },
};
const standardGraphics = {
    BALLOON: 'balloon',
    CLOUDS: 'clouds',
    ORANGES: 'oranges',
    UMBRELLA: 'umbrella',
    CHARITY_LOGO: 'charityLogo',
};
const notifierTypes = {
    TEST: 'test',
    ERROR: 'error',
    PAYMENT: 'payment',
    PROBATE: 'probate',
    NOTIFICATION: 'notification',
    PRINT_REQUEST: 'printRequest',
};
const addOnTypes = {
    PRINTING: {
        category: 'printing',
        type: 'printing',
        label: 'Printing + Shipping',
        tKey: 'common:addOnTypes.printing.label',
        storeReferenceUntilUsed: true,
        oneTimeUse: true,
        price: {
            individual: 29,
            couples: 49,
        },
    },
    VIRTUAL_WITNESSING_WILL: {
        category: 'witnessing',
        type: 'virtualWitnessingWill',
        label: 'Witness by Video Conference (Will Only)',
        tKey: 'common:addOnTypes.virtualWitnessingWill.label',
        storeReferenceUntilUsed: true,
        oneTimeUse: true,
        price: {
            individual: 119,
            couples: 199,
        },
    },
    VIRTUAL_WITNESSING_WILL_INCAPACITY: {
        category: 'witnessing',
        type: 'virtualWitnessingWillIncapacity',
        label: 'Witness by Video Conference (Will + Incapacity)',
        tKey: 'common:addOnTypes.virtualWitnessingWillIncapacity.label',
        storeReferenceUntilUsed: true,
        oneTimeUse: true,
        price: {
            individual: 139,
            couples: 249,
        },
    },
    GIFT_WILL_ONLY_INDIVIDUAL: {
        category: 'gift',
        type: 'gift_willOnlyIndividual',
        label: 'Gift - Will Only (Individual)',
        tKey: 'common:addOnTypes.gift_willOnlyIndividual.label',
        storeReferenceUntilUsed: false,
        price: 139,
    },
    GIFT_WILL_POA_INDIVIDUAL: {
        category: 'gift',
        type: 'gift_willPoaIndividual',
        label: 'Gift - Will + Incapacity Planning (Individual)',
        tKey: 'common:addOnTypes.gift_willPoaIndividual.label',
        storeReferenceUntilUsed: false,
        price: 199,
    },
    GIFT_WILL_ONLY_COUPLES: {
        category: 'gift',
        type: 'gift_willOnlyCouples',
        label: 'Gift - Will Only (Couples)',
        tKey: 'common:addOnTypes.gift_willOnlyCouples.label',
        storeReferenceUntilUsed: false,
        price: 269,
    },
    GIFT_WILL_POA_COUPLES: {
        category: 'gift',
        type: 'gift_willPoaCouples',
        label: 'Gift - Will + Incapacity Planning (Couples)',
        tKey: 'common:addOnTypes.gift_willPoaCouples.label',
        storeReferenceUntilUsed: false,
        price: 329,
    },
};
const roleTypes = {
    user: {
        type: 'user',
        roles: ['user'],
    },
    charityPartnerAdmin: {
        type: 'charityPartnerAdmin',
        roles: ['user', 'charityPartnerAdmin'],
    },
    admin: {
        type: 'admin',
        roles: ['admin', 'user', 'charityPartnerAdmin'],
    },
    superAdmin: {
        type: 'superAdmin',
        roles: ['superAdmin', 'admin', 'user', 'charityPartnerAdmin'],
    },
};
const corporateExecutors = {
    rbc: { contactFormEmail: 'rbctru@rbc.com' },
};
const referrerProperties = ['ref', 'reftag'];
const logLevelTypes = {
    INFO: 'info',
    WARN: 'warn',
    ERROR: 'error',
};
const documentDownloadEventTypes = {
    EMAIL: 'email',
    PAID_PREVIEW: 'paidPreview',
    UNPAID_PREVIEW: 'unpaidPreview',
    STATIC_INDIVIDUAL: 'staticIndividual',
    GENERATED_INDIVIDUAL: 'generatedIndividual',
};
const probateMeetingTypes = {
    TEAMS_MEETING: 'Teams Meeting',
    PHONE_CALL: 'Phone Call',
};
const probatePackages = {
    ESSENTIAL: 'essentials',
    PREMIUM: 'premium',
    DELUXE: 'deluxe',
    DIRECT_CONSULTATION: 'directConsultation',
};
const toggleTypes = {
    HAS_SIGNED_CORE_DOCUMENTS: 'hasSignedCoreDocuments',
    HAS_ACCEPTED_SIGN_REMINDER: 'hasAcceptedSignReminder',
    // TAG: notify-appointees
    HAS_SEEN_NOTIFY_APPOINTEES: 'hasSeenNotifyAppointees',
    HAS_SEEN_MY_ESTATE_PLAN_PAGE: 'hasSeenMyEstatePlanPage',
    HAS_SEEN_LIFE_INSURANCE_QUOTE: 'hasSeenLifeInsuranceQuote',
    HAS_SEEN_PRE_PLANNED_CREMATION: 'hasSeenPrePlannedCremation',
    HAS_CLICKED_LIFE_INSURANCE_LINK: 'hasClickedLifeInsuranceLink',
    HAS_CLICKED_REGISTER_GIFT_OF_LIFE: 'hasClickedRegisterGiftOfLife',
    HAS_SEEN_CANADA_WILL_REGISTRY_CODE: 'hasSeenCanadaWillRegistryCode',
    HAS_ACCEPTED_STEPPED_DOCUMENTS_MODAL: 'hasAcceptedSteppedDocumentsModal',
};
const contactTypes = {
    CHANGE_EMAIL: {
        type: 'changeEmail',
        metadataTypes: {
            NEW_EMAIL: 'newEmail',
        },
    },
    CHANGE_PARTNER_EMAIL: {
        type: 'changePartnerEmail',
        metadataTypes: {
            NEW_PARTNER_EMAIL: 'newPartnerEmail',
            CURRENT_PARTNER_EMAIL: 'currentPartnerEmail',
        },
    },
};
const bonusDocs = [];
const tagAlongDocs = [];
const defunctDocs = [docTypes.COVID];
const staticPdfDocs = [docTypes.ASSET_LIST];
const freeDocs = [docTypes.WISHES, docTypes.ASSET_LIST];
// TAG: province-specific
const supportedProvinces = [
    provinces.ALBERTA,
    provinces.BRITISH_COLUMBIA,
    provinces.MANITOBA,
    provinces.NEW_BRUNSWICK,
    provinces.NEWFOUNDLAND_AND_LABRADOR,
    provinces.NOVA_SCOTIA,
    provinces.ONTARIO,
    provinces.PRINCE_EDWARD_ISLAND,
    provinces.SASKATCHEWAN,
];
const externalUserPermissions = ['create:questionnaire'];
const epiloguePhoneNumber = '(289) 678-1689';
const insuranceAvailabilityStatus = {
    AVAILABLE: 'available',
    UNAVAILABLE: 'unavailable',
};
const paymentTypes = {
    IN_FULL: 'inFull',
    PROMO_CODE: 'promoCode',
    PAYMENT_PLAN: 'paymentPlan',
};
const eventActionTypes = {
    USED: 'used',
    OPENED: 'opened',
    CLOSED: 'closed',
    VIEWED: 'viewed',
    BOOKED: 'booked',
    CLICKED: 'clicked',
    SUCCESS: 'success',
    CANCELLED: 'cancelled',
};
const eventNames = {
    PRINT_CREDIT: 'printCredit',
    EMAIL_DOCUMENTS: 'emailDocuments',
    MY_ESTATE_PLAN_PAGE: 'myEstatePlanPage',
    LIFE_INSURANCE_MODAL: 'lifeInsuranceModal',
    CHARITY_LANDING_PAGE: 'charityLandingPage',
    LIFE_INSURANCE_SUBMIT: 'lifeInsuranceSubmit',
    DOWNLOAD_CORE_DOCUMENT: 'downloadCoreDocument',
    WITNESSING_APPOINTMENT: 'witnessingAppointment',
    LIFE_INSURANCE_QUOTE_LINK: 'lifeInsuranceQuoteLink',
};
const uuidNamespaces = {
    DEFAULT: '04e67cf3-6c99-4e22-8cf7-6eefef2a5c7d',
    RBC_INSURANCE_USER_ID: '14211548-b494-4a1e-bc3f-92ac3934c77e',
    CALENDAR_KEYS: '14fa0e39-33e1-451b-8adc-b8c4fa2108af',
    ESTATE_ESTIMATION: '382a89e1-e747-4dc2-9aff-ac7848be7aab',
};
const authenticationEventTypes = {
    LOGIN: 'login',
    CHANGE_PASSWORD: 'change_password',
    REGISTER: 'register',
};
const authenticationEventMethods = {
    OAUTH: 'oauth',
    LOCAL: 'local',
    RESET: 'reset',
    UPDATE: 'update',
    PARTNER: 'partner',
};
const charityCategories = {
    ANIMALS: 'Animals',
    ARTS_AND_CULTURE: 'Arts & Culture',
    COMMUNITY_BENEFIT: 'Community Benefit',
    EDUCATION: 'Education',
    ENVIRONMENT: 'Environment',
    HEALTH: 'Health',
    INDIGENOUS_LED: 'Indigenous Led',
    INTERNATIONAL: 'International',
    RELIGION: 'Religion',
    SOCIAL_SERVICE: 'Social Service',
    SPORTS_RECREATION: 'Sports & Recreation',
};
const charityTypes = {
    WILLPOWER: 'willpower',
    CRA: 'cra',
    PARTNER: 'partner',
};
const appointmentTypes = {
    WITNESSING: 'witnessing',
    PROBATE: 'probate',
};
const appointmentSubtype = {
    INDIVIDUAL_WILL: 'individualWill',
    INDIVIDUAL_WILL_INCAPACITY: 'individualWillIncapacity',
    COUPLES_WILL: 'couplesWill',
    COUPLES_WILL_INCAPACITY: 'couplesWillIncapacity',
};
const appointmentStatus = {
    PENDING: 'pending',
    CANCELED: 'canceled',
    COMPLETE: 'complete',
};
const autoOpenModalTypes = {
    PRINT_CREDIT: 'printCredit',
    LIFE_INSURANCE: 'lifeInsurance',
    NOTIFY_APPOINTEES: 'notifyAppointees',
    WITNESSING_CREDIT: 'witnessingCredit',
};
const queuedMessageStatus = {
    PENDING: 'pending',
    COMPLETE: 'complete',
    CANCELED: 'canceled',
    FAILED: 'failed',
};
const queuedMessageType = {
    HTML_EMAIL: 'htmlEmail',
    TEMPLATE_EMAIL: 'templateEmail',
};
const urlTypes = {
    API_PATH: 'API_PATH',
    ROOT_URL: 'ROOT_URL',
    QUESTIONNAIRE_URL: 'QUESTIONNAIRE_URL',
    DASHBOARD_URL: 'DASHBOARD_URL',
    PARTIALLY: 'PARTIALLY',
};
exports.default = {
    oauth,
    states,
    sidebar,
    urlTypes,
    freeDocs,
    docTypes,
    bonusDocs,
    provinces,
    roleTypes,
    addOnTypes,
    worldTypes,
    codeLabels,
    toastTypes,
    defunctDocs,
    toggleTypes,
    dropOffTypes,
    productTypes,
    tagAlongDocs,
    contactTypes,
    paymentTypes,
    logLevelTypes,
    staticPdfDocs,
    notifierTypes,
    languageTypes,
    partnerValues,
    socialDocTypes,
    paymentOptions,
    docSectionTypes,
    eventActionTypes,
    standardGraphics,
    sessionStorageKeys,
    corporateExecutors,
    supportedProvinces,
    referrerProperties,
    autoOpenModalTypes,
    relationshipValues,
    docSectionFileTypes,
    epiloguePhoneNumber,
    probateMeetingTypes,
    probatePackages,
    interceptActionTypes,
    corporateExecutorTypes,
    externalUserPermissions,
    questionnaireSectionTypes,
    documentDownloadEventTypes,
    acceptedIncomingQuestionnaireProperties,
    insuranceAvailabilityStatus,
    provinceAlphaCodes,
    cookiePrefixes,
    cookieNames,
    eventNames,
    uuidNamespaces,
    authenticationEventTypes,
    authenticationEventMethods,
    charityCategories,
    charityTypes,
    appointmentTypes,
    appointmentSubtype,
    appointmentStatus,
    queuedMessageStatus,
    queuedMessageType,
};
