import _get from 'lodash/get'

import { CONSTANTS, EvironmentTypes, utils } from '@epilogue/common'
import axios from 'axios'
import i18n from 'i18next'

import queryString from '../lib/queryString'
import featureFlag, { flagTypes } from '../utils/featureFlag'

// url paths
export const PATHS = {
  ADD_ON_ADD_ONS: '/add_on/add_ons',
  ADD_ON_PRINT_REQUEST: '/add_on/print_request',

  // TAG: notify-appointees
  APPOINTEE_SEND_EMAIL: '/appointee/send_email',

  APPOINTMENT: '/appointment',

  CHARITY: '/charity',

  CHARITY_PARTNER: '/charity_partner',

  CONTACT: '/contact',

  CORPORATE_EXECUTOR_CONTACT_FORM: '/corporate_executor_contact_form',

  DOCUMENT_DOCUMENTS: '/document/documents',
  DOCUMENT_DOWNLOAD_DOC: '/document/download_doc',
  DOCUMENT_DOCUMENT_DOWNLOADS: '/document/document_downloads',
  DOCUMENT_DOWNLOAD_STATIC_DOC: '/document/download_static_doc',
  DOCUMENT_DOWNLOAD_DOC_PREVIEW: '/document/download_doc_preview',
  DOCUMENT_EMAIL: '/document/email_docs',
  DOCUMENT_GENERATE_DOC: '/document/generate_doc',
  DOCUMENT_GENERATE_DOCS: '/document/generate_docs',
  DOCUMENT_SIGNED_DOC: '/document/signed_doc',
  DOCUMENT_ALL_DOCS_SIGNED: '/document/all_docs_signed',

  EVENT: '/event',
  EVENT_PRINT_CREDITS_USED: '/event/print_credits_used',

  HOW_DID_YOU_HEAR_ABOUT_US: '/how_did_you_hear_about_us',

  INSURANCE_QUOTE: '/insurance/quote',

  LAWYER: '/lawyer',
  LAWYER_ACTIVE: '/lawyer/active',
  LAWYER_FLAT_FEE: '/lawyer/flat_fee',

  LAWYER_REFERRAL: '/lawyer_referral',

  LOCATION_PLACE: '/location/place',
  LOCATION_SUGGESTIONS: '/location/suggestions',

  NOTIFY: '/notify',
  NOTIFY_PARTNER_SPOUSAL_TRUST: '/notify/partner_spousal_trust',

  PAYMENT: '/payment',
  PAYMENT_PRODUCT_CHARGE: '/payment/product_charge',
  PAYMENT_START_PAYMENT_PLAN: '/payment/start_payment_plan',
  PAYMENT_CREATE_PAYMENT_PLAN: '/payment/create_payment_plan',

  PROMO_CODE: '/promo_code',
  PROMO_CODE_CHECK_VALID: '/promo_code/check_valid',

  SOCIAL_DOCUMENT: '/social_document',
  SOCIAL_DOCUMENT_DOWNLOAD_DOC: '/social_document/download_doc',

  TOGGLE: '/toggle',

  QUESTIONNAIRE: '/questionnaire',
  QUESTIONNAIRE_QUESTIONNAIRE_FIRST_COMPLETED:
    '/questionnaire/questionnaire_first_completed',

  USER: '/user',
  USER_ID: '/user/id',
  USER_ME: '/user/me',
  USER_AUTH_GOOGLE: '/user/auth/google',
  USER_REGISTER: '/user/register',
  USER_LOGIN: '/user/login',
  USER_EMAIL: '/user/email',
  USER_EMAIL_AVAIL: '/user/email_avail',
  USER_LOGOUT: '/user/logout',
  USER_MAILING_LIST: '/user/mailing_list',
  USER_FORGOT_PASSWORD: '/user/forgot_password',
  USER_UPDATE_PASSWORD: '/user/update_password',

  UNSUPPORTED_PROVINCE: '/unsupported_province',
}

// returns a promise
export const request = ({
  url,
  data,
  params,
  method,
  responseType,
  shouldSerializeParams = false,
  timeout = 10000, // default timeout after 10s
}: any) => {
  if (process.env.REACT_APP_ENV === 'development' && url.includes('?')) {
    // eslint-disable-next-line no-debugger
    debugger
    throw new Error(
      `The following url should not contain query params: ${url}  —>  Instead use the 'params' method.`,
    )
  }

  const axiosClient = axios.create({
    baseURL: utils.url(
      process.env.REACT_APP_ENV as EvironmentTypes,
      CONSTANTS.urlTypes.API_PATH,
    ),
  })

  return axiosClient.request({
    url,
    data,
    method,
    params: { ...params, lang: i18n.language },
    timeout,
    responseType,
    withCredentials: true,
    paramsSerializer: shouldSerializeParams ? queryString.stringify : undefined,
  })
}

export const errorHandler = ({
  error,
  onError,
  reportError = true,
  fallbackErrorMessage,
}: any) => {
  const resData = _get(error, ['response', 'data'])
  const status = _get(error, ['response', 'status'])
  const resType = _get(error, ['request', 'responseType'])
  const errorMessage = _get(error, ['response', 'data', 'errorMessage'])

  // if rate-limit is exceeded
  if (
    status === 429 &&
    resData &&
    error?.response?.headers['x-ratelimit-remaining'] === '0'
  ) {
    // report when rate-limit is exceeded regardless of the report variable
    if (featureFlag(flagTypes.SENTRY)) {
      // eslint-disable-next-line @typescript-eslint/no-extra-semi
      ;(window as any).captureException(error)
    }
    // eslint-disable-next-line no-alert
    alert(error.response.data)
  }

  let resolvedErrorMessage =
    (status < 500 && errorMessage) ||
    fallbackErrorMessage ||
    'Uh oh! Something went wrong. Please refresh your browser and try again.'

  /*
   * If responseType is arraybuffer, we need to decode the response and check if
   * an errorMessage is attached to the response object. If so, we use that as
   * the error message. If not, we use the fallbacks.
   */
  if (resType === 'arraybuffer' && resData instanceof ArrayBuffer) {
    const decoder = new TextDecoder('utf-8')

    const theErrorMessage = _get(
      JSON.parse(decoder.decode(resData)),
      'errorMessage',
    )

    if (theErrorMessage) {
      resolvedErrorMessage = theErrorMessage
    }
  }

  /*
   * The variable `report` comes from the server and is given precedence over
   * the `reportError` variable, which comes from the front-end. Only if `report`
   * is not provided do we default to the `reportError` variable.
   */
  const report = _get(error, ['response', 'data', 'report'])
  const shouldReport = report || (report === undefined && reportError)

  if (featureFlag(flagTypes.SENTRY) && shouldReport) {
    // eslint-disable-next-line @typescript-eslint/no-extra-semi
    ;(window as any).captureException(error, resolvedErrorMessage)
  }

  if (onError && typeof onError === 'function') {
    onError(resolvedErrorMessage)
  }
}
