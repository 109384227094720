import React, { useEffect } from 'react'

import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined'
import { Box } from '@mui/material'

import Button from '../../../../../../../common/components/atoms/Button'
import Heading from '../../../../../../../common/components/atoms/Heading'
import Modal from '../../../../../../../common/components/atoms/Modal'
import Text from '../../../../../../../common/components/atoms/Text'
import EverythingButton from '../../../../../../../common/components/molecules/EverythingButton'
import amplitude from '../../../../../../../common/lib/amplitude'
import {
  themeColors,
  themeColorTypes,
  themeColorVariants,
} from '../../../../../../../common/styles/muiTheme'

interface Props {
  show: boolean
  onClose: () => void
  questionnaireValid: boolean
  generateDocumentsWorking: boolean
}

const StaleDocumentsModal = ({
  show,
  onClose,
  questionnaireValid,
  generateDocumentsWorking,
}: Props) => {
  useEffect(() => {
    if (!generateDocumentsWorking && show)
      amplitude.sendEvent('ViewedStaleDocumentsModal', {
        questionnaireValid,
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show, generateDocumentsWorking])

  return (
    <Modal
      maxWidth="md"
      onClose={onClose}
      data-testid="stale-documents-modal"
      show={!generateDocumentsWorking && show}
    >
      <Box sx={{ mx: 'auto', py: '2.1rem' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <WarningAmberOutlinedIcon
            sx={{
              fontSize: '3.5rem',
              fill: themeColors[themeColorTypes.RED][themeColorVariants.MAIN],
            }}
          />
          <Box sx={{ my: '1.15rem', textAlign: 'center' }}>
            <Heading variant="h4">
              Your Documents May Not Be Up To Date!
            </Heading>
          </Box>
        </Box>

        <Box sx={{ textAlign: 'center' }}>
          {questionnaireValid ? (
            <Text data-testid="stale-documents-questionnaire-valid">
              We see that you may have changed some of your answers. Re-generate
              your documents to make sure they&apos;re up to date.
            </Text>
          ) : (
            <Text data-testid="stale-documents-questionnaire-invalid">
              We see that you may have changed some of your answers, and now we
              just have a few more questions for you. Please click below to
              finish your questionnaire.
            </Text>
          )}
        </Box>

        <Box
          sx={{
            mt: '1.6rem',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <EverythingButton />
          <Button
            size="xs"
            label="Close"
            onClick={() => {
              amplitude.sendEvent('ClosedStaleDocumentsModal')
              onClose()
            }}
            sx={{ mt: { min: '1rem', sm: '0.6rem' } }}
          />
        </Box>
      </Box>
    </Modal>
  )
}

export default StaleDocumentsModal
