import React from 'react'

import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'
import _keyBy from 'lodash/keyBy'

import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import LightbulbOutlinedIcon from '@mui/icons-material/LightbulbOutlined'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import OpenInNewRoundedIcon from '@mui/icons-material/OpenInNewRounded'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import { Box } from '@mui/material'

import { CONSTANTS, Referrer, utils } from '@epilogue/common'
import i18n from 'i18next'
import { useTranslation } from 'react-i18next'

import DrinkIconNavy from '../../../../../../common/assets/images/drink-icon-navy.svg'
import DrinkIconOrange from '../../../../../../common/assets/images/drink-icon-orange.svg'
import EireneLogo from '../../../../../../common/assets/images/eirene-logo.png'
import GiftIconNavy from '../../../../../../common/assets/images/gift-icon-navy.svg'
import GraduationHatIconNavy from '../../../../../../common/assets/images/graduation-hat-icon-navy.svg'
import GraduationHatIconOrange from '../../../../../../common/assets/images/graduation-hat-icon-orange.svg'
import HouseIconNavy from '../../../../../../common/assets/images/house-icon-navy.svg'
import HouseIconOrange from '../../../../../../common/assets/images/house-icon-orange.svg'
import MegaphoneIconNavy from '../../../../../../common/assets/images/megaphone-icon-navy.svg'
import UmbrellaIconNavy from '../../../../../../common/assets/images/umbrella-icon-navy.svg'
import UmbrellaIconOrange from '../../../../../../common/assets/images/umbrella-icon-orange.svg'
import VaultIconNavy from '../../../../../../common/assets/images/vault-icon-navy.svg'
import VaultIconOrange from '../../../../../../common/assets/images/vault-icon-orange.svg'
import WalletIconNavy from '../../../../../../common/assets/images/wallet-icon-navy.svg'
import WalletIconOrange from '../../../../../../common/assets/images/wallet-icon-orange.svg'
import useProvinceDictionary from '../../../../../../common/hooks/useProvinceDictionary'
import useUpdateToggles from '../../../../../../common/hooks/useUpdateToggles'
import event from '../../../../../../common/lib/event'
import {
  themeColors,
  themeColorTypes,
  themeColorVariants,
} from '../../../../../../common/styles/muiTheme'
import featureFlag, {
  flagTypes,
} from '../../../../../../common/utils/featureFlag'
import { ONTARIO_TERMS } from '../../../../../../common/utils/provinceDictionary'
import getInvestmentAccountsOptions, {
  InvestmentAccountTypes,
} from '../../../../../Questionnaire/components/Question/InvestmentAccounts/utils/getInvestmentAccountsOptions'
import hasCoreDocument from '../../../../utils/hasCoreDocument'
import { RbcAdvisorBanner } from '../components/banners'
import LifeInsuranceQuotes from '../components/LifeInsuranceQuotes'
import { InsuranceQuotesParams } from '../components/LifeInsuranceQuotes/quote.types'
import generateCoverageAmounts from '../components/LifeInsuranceQuotes/utils/generateCoverageAmounts'
import isLifeInsuranceAge from '../components/LifeInsuranceQuotes/utils/isLifeInsuranceAge'
import NotifyAppointees from '../components/NotifyAppointees'
import ReferralLink from '../components/ReferralLink'
import usePrefetchInsuranceQuotes from '../hooks/usePrefetchInsuranceQuotes'
import canadaWillRegistryBcNo from '../markdown/canadaWillRegistryBcNo'
import canadaWillRegistryBcYes from '../markdown/canadaWillRegistryBcYes'
import fhsaNo from '../markdown/fhsaNo'
import fhsaYes from '../markdown/fhsaYes'
import lifeInsuranceNo from '../markdown/lifeInsuranceNo'
import lifeInsuranceYes from '../markdown/lifeInsuranceYes'
import prePlannedCremationMarkdown from '../markdown/prePlannedCremationMarkdown'
import respNo from '../markdown/respNo'
import respYes from '../markdown/respYes'
import rrifNo from '../markdown/rrifNo'
import rrifYes from '../markdown/rrifYes'
import rrspNo from '../markdown/rrspNo'
import rrspYes from '../markdown/rrspYes'
import tfsaNo from '../markdown/tfsaNo'
import tfsaYes from '../markdown/tfsaYes'
import { ButtonTypes } from '../types/button.types'
import { DocumentCardsInfo, StatusTypes } from '../types/documentCards.types'

const poaContactModalData = {
  icon: <LightbulbOutlinedIcon sx={{ fontSize: '3.3rem' }} />,
  body: [
    "If you'd like to add Incapacity Documents to your plan, we'd be happy to help you out. Let us know by sending an email to support@epiloguewills.com",
  ],
}

const sectionLabels = (t: any) => ({
  will: { tKey: 'documents:data.documentCardsInfo.sectionLabels.will' },
  estatePlanning: {
    tKey: 'documents:data.documentCardsInfo.sectionLabels.estatePlanning',
  },
  financialPlanning: {
    tKey: 'documents:data.documentCardsInfo.sectionLabels.financialPlanning',
  },
  miscellaneous: {
    tKey: 'documents:data.documentCardsInfo.sectionLabels.miscellaneous',
  },
})

export default ({
  token,
  userId,
  toggles,
  // TAG: TD setup
  referrer,
  answerStore,
  questionnaireId,
  noticeConnectCode,
  autoOpenModalType,
  generatedDocuments,
  customerReferralCode,
  hasCoreProductPayment,
  answerStoreUpdatedSinceGenerate,
}: {
  // TS-TODO: update once toggles from CONSTANTS are typed
  toggles: any
  token: string
  userId: string
  // TAG: TD setup
  referrer: Referrer
  questionnaireId: string
  generatedDocuments: any[]
  noticeConnectCode?: string
  autoOpenModalType?: string
  customerReferralCode?: string
  hasCoreProductPayment: boolean
  answerStore: { [key: string]: any }
  answerStoreUpdatedSinceGenerate: boolean
}): DocumentCardsInfo[] => {
  const { t } = useTranslation()

  const keyedGeneratedDocsByType = _keyBy(
    generatedDocuments,
    ({ docType }) => docType,
  )

  const gender = answerStore?.gender
  const age = utils.dobToAge(answerStore.dateOfBirth)
  const hasGeneratedCoreDocument = hasCoreDocument(generatedDocuments)
  const hasPaidCoreDocument = hasCoreProductPayment && hasGeneratedCoreDocument

  const investmentAccountOptions: InvestmentAccountTypes[] =
    getInvestmentAccountsOptions(answerStore?.dateOfBirth).map(
      ({ value }) => value,
    )

  const { mutate: handleUpdateToggles } = useUpdateToggles()

  const coverageAmounts = generateCoverageAmounts(gender, age)
  const initialInsuranceQuotesParams: InsuranceQuotesParams = {
    age,
    gender,
    term: 10,
    smoker: false,
    coverageAmounts,
  }
  /*
   * Prefetch insurance quotes here because RBC's API is super slow.
   * By the time the user opens the life insurance modal, the initial
   * quotes should have already been fetched and cached.
   */
  if (!_isEmpty(coverageAmounts)) {
    usePrefetchInsuranceQuotes({
      insuranceQuotesParams: initialInsuranceQuotesParams,
    })
  }

  const { provinceDictionary } = useProvinceDictionary()

  return [
    //
    //
    // WILL & INCAPACITY DOCUMENTS
    //
    //
    {
      label: t(sectionLabels(t).will.tKey),
      cards: [
        // WILL //
        {
          name: 'will',
          label: t('documents:data.documentCardsInfo.will.label'),
          docType: CONSTANTS.docTypes.WILL,
          paid: _get(keyedGeneratedDocsByType, [
            CONSTANTS.docTypes.WILL,
            'paid',
          ]),
          created: _get(keyedGeneratedDocsByType, [
            CONSTANTS.docTypes.WILL,
            'created',
          ]),
          description: [
            t('documents:data.documentCardsInfo.will.description.item1'),
          ],
          // TS-TODO: update when MUI is typed
          stripeColor: themeColors[themeColorTypes.BRAND][
            themeColorVariants.MAIN
          ] as string,
          status: ({ documentCreated, documentPaid }) => {
            switch (true) {
              case documentCreated && documentPaid:
                return StatusTypes.COMPLETE

              case documentCreated && !documentPaid:
                return StatusTypes.PAYMENT_REQUIRED

              default:
                return StatusTypes.IN_PROGRESS
            }
          },
          highlightPills: {
            [StatusTypes.GET_STARTED]: t(
              'documents:data.documentCardsInfo.highlightPills.notStarted',
            ),
            [StatusTypes.IN_PROGRESS]: t(
              'documents:data.documentCardsInfo.highlightPills.inProgress',
            ),
            [StatusTypes.COMPLETE]: t(
              'documents:data.documentCardsInfo.highlightPills.complete',
            ),
          },
          buttons: {
            [StatusTypes.COMPLETE]: {
              [ButtonTypes.PRIMARY]: {
                label: t(
                  'documents:data.documentCardsInfo.buttonLabels.download',
                ),
                color: themeColorTypes.GREY,
                action: { type: 'download' },
                colorVariant: themeColorVariants.DARKER,
                startIcon: <FileDownloadOutlinedIcon sx={{ mr: '-0.2rem' }} />,
              },
              [ButtonTypes.SECONDARY]: {
                label: t(
                  'documents:data.documentCardsInfo.buttonLabels.preview',
                ),
                color: themeColorTypes.GREY,
                action: {
                  type: 'modal',
                  modalType: 'preview',
                  omitPageNumbers: [1, 2],
                  previewModalType: 'generated',
                },
                colorVariant: themeColorVariants.DARKER,
                startIcon: <VisibilityOutlinedIcon sx={{ mr: '-0.2rem' }} />,
              },
              [ButtonTypes.TERTIARY]: {
                label: t('documents:data.documentCardsInfo.buttonLabels.edit'),
                color: themeColorTypes.GREY,
                colorVariant: themeColorVariants.DARKER,
                startIcon: <EditOutlinedIcon sx={{ mr: '-0.25rem' }} />,
                action: {
                  type: 'navigate',
                  path: '/dashboard/summary#the-basics',
                },
              },
            },
            [StatusTypes.PAYMENT_REQUIRED]: {
              [ButtonTypes.PRIMARY]: {
                fullWidth: true,
                variant: 'outlined',
                label: t(
                  'documents:data.documentCardsInfo.buttonLabels.completePayment',
                ),
                color: themeColorTypes.ACCENT_1,
                action: {
                  type: 'navigate',
                  path: '/dashboard/add-ons/printing',
                },
                endIcon: <LockOutlinedIcon sx={{ ml: '-0.1rem' }} />,
              },
            },
            [StatusTypes.IN_PROGRESS]: {},
          },
        },

        // POA FOR PROPERTY //
        {
          name: 'propertyPoa',
          label: (resolvedStatus) => (
            <Box>
              {resolvedStatus === StatusTypes.GET_STARTED && (
                <LockOutlinedIcon sx={{ fontSize: '0.875em', mr: '0.3rem' }} />
              )}
              {answerStore?.province === CONSTANTS.provinces.SASKATCHEWAN
                ? provinceDictionary(
                    ONTARIO_TERMS.POA_FOR_PROPERTY,
                    CONSTANTS.provinces.SASKATCHEWAN,
                  )
                : provinceDictionary(
                    ONTARIO_TERMS.POWER_OF_ATTORNEY_FOR_PROPERTY,
                    answerStore?.province,
                  )}
            </Box>
          ),
          description: [
            t(
              `documents:data.documentCardsInfo.propertyPoa.description.item1.case${answerStore?.province === CONSTANTS.provinces.SASKATCHEWAN ? '1' : '2'}`,
            ),
          ],
          paid: _get(keyedGeneratedDocsByType, [
            CONSTANTS.docTypes.PROPERTY_POA,
            'paid',
          ]),
          created: _get(keyedGeneratedDocsByType, [
            CONSTANTS.docTypes.PROPERTY_POA,
            'created',
          ]),
          docType: CONSTANTS.docTypes.PROPERTY_POA,
          // TS-TODO: update when MUI is typed
          stripeColor: themeColors[themeColorTypes.ACCENT_1][
            themeColorVariants.MAIN
          ] as string,
          status: ({ documentCreated, documentPaid }) => {
            switch (true) {
              case documentCreated && documentPaid:
                return StatusTypes.COMPLETE

              case documentCreated &&
                !documentPaid &&
                !hasCoreProductPayment &&
                answerStore?.product === 'willPoa':
                return StatusTypes.PAYMENT_REQUIRED

              case hasCoreProductPayment &&
                !documentPaid &&
                answerStore?.product !== 'willPoa':
                return StatusTypes.CONTACT_US

              case !documentCreated && answerStore?.product === 'willPoa':
                return StatusTypes.IN_PROGRESS

              default:
                return StatusTypes.GET_STARTED
            }
          },
          highlightPills: {
            [StatusTypes.GET_STARTED]: t(
              'documents:data.documentCardsInfo.highlightPills.notStarted',
            ),
            [StatusTypes.IN_PROGRESS]: t(
              'documents:data.documentCardsInfo.highlightPills.inProgress',
            ),
            [StatusTypes.COMPLETE]: t(
              'documents:data.documentCardsInfo.highlightPills.complete',
            ),
          },
          buttons: {
            [StatusTypes.COMPLETE]: {
              [ButtonTypes.PRIMARY]: {
                label: t(
                  'documents:data.documentCardsInfo.buttonLabels.download',
                ),
                color: themeColorTypes.GREY,
                action: { type: 'download' },
                colorVariant: themeColorVariants.DARKER,
                startIcon: <FileDownloadOutlinedIcon sx={{ mr: '-0.2rem' }} />,
              },
              [ButtonTypes.SECONDARY]: {
                label: t(
                  'documents:data.documentCardsInfo.buttonLabels.preview',
                ),
                color: themeColorTypes.GREY,
                action: {
                  type: 'modal',
                  modalType: 'preview',
                  omitPageNumbers: [1, 2],
                  previewModalType: 'generated',
                },
                colorVariant: themeColorVariants.DARKER,
                startIcon: <VisibilityOutlinedIcon sx={{ mr: '-0.2rem' }} />,
              },
              [ButtonTypes.TERTIARY]: {
                label: t('documents:data.documentCardsInfo.buttonLabels.edit'),
                color: themeColorTypes.GREY,
                colorVariant: themeColorVariants.DARKER,
                startIcon: <EditOutlinedIcon sx={{ mr: '-0.25rem' }} />,
                action: {
                  type: 'navigate',
                  path: '/dashboard/summary#poa-for-property',
                },
              },
            },
            [StatusTypes.PAYMENT_REQUIRED]: {
              [ButtonTypes.PRIMARY]: {
                fullWidth: true,
                variant: 'outlined',
                label: t(
                  'documents:data.documentCardsInfo.buttonLabels.completePayment',
                ),
                color: themeColorTypes.ACCENT_1,
                action: {
                  type: 'navigate',
                  path: '/dashboard/add-ons/printing',
                },
                endIcon: <LockOutlinedIcon sx={{ ml: '-0.1rem' }} />,
              },
            },
            [StatusTypes.CONTACT_US]: {
              [ButtonTypes.PRIMARY]: {
                fullWidth: true,
                label: t(
                  'documents:data.documentCardsInfo.buttonLabels.getStarted',
                ),
                variant: 'contained',
                color: themeColorTypes.NEUTRAL,
                action: {
                  type: 'modal',
                  data: poaContactModalData,
                  modalType: 'contact',
                },
                endIcon: <ArrowForwardIcon sx={{ ml: '-0.1rem' }} />,
              },
            },
          },
        },

        // POA FOR CARE //
        {
          name: 'personalPoa',
          label: (resolvedStatus) => (
            <Box>
              {resolvedStatus === StatusTypes.GET_STARTED && (
                <LockOutlinedIcon sx={{ fontSize: '0.875em', mr: '0.3rem' }} />
              )}
              {provinceDictionary(
                ONTARIO_TERMS.POWER_OF_ATTORNEY_FOR_PERSONAL_CARE,
                answerStore?.province,
              )}
            </Box>
          ),
          description: [
            [
              CONSTANTS.provinces.SASKATCHEWAN,
              CONSTANTS.provinces.PRINCE_EDWARD_ISLAND,
            ].includes(answerStore?.province)
              ? t(
                  'documents:data.documentCardsInfo.personalPoa.description.item1.case1',
                )
              : t(
                  'documents:data.documentCardsInfo.personalPoa.description.item1.case2',
                ),
          ],
          paid: _get(keyedGeneratedDocsByType, [
            CONSTANTS.docTypes.PERSONAL_POA,
            'paid',
          ]),
          created: _get(keyedGeneratedDocsByType, [
            CONSTANTS.docTypes.PERSONAL_POA,
            'created',
          ]),
          docType: CONSTANTS.docTypes.PERSONAL_POA,
          // TS-TODO: update when MUI is typed
          stripeColor: themeColors[themeColorTypes.ACCENT_2][
            themeColorVariants.MAIN
          ] as string,

          status: ({ documentCreated, documentPaid }) => {
            switch (true) {
              case documentCreated && documentPaid:
                return StatusTypes.COMPLETE

              case documentCreated &&
                !documentPaid &&
                !hasCoreProductPayment &&
                answerStore?.product === 'willPoa':
                return StatusTypes.PAYMENT_REQUIRED

              case hasCoreProductPayment &&
                !documentPaid &&
                answerStore?.product !== 'willPoa':
                return StatusTypes.CONTACT_US

              case !documentCreated && answerStore?.product === 'willPoa':
                return StatusTypes.IN_PROGRESS

              default:
                return StatusTypes.GET_STARTED
            }
          },
          highlightPills: {
            [StatusTypes.GET_STARTED]: t(
              'documents:data.documentCardsInfo.highlightPills.notStarted',
            ),
            [StatusTypes.IN_PROGRESS]: t(
              'documents:data.documentCardsInfo.highlightPills.inProgress',
            ),
            [StatusTypes.COMPLETE]: t(
              'documents:data.documentCardsInfo.highlightPills.complete',
            ),
          },
          buttons: {
            [StatusTypes.COMPLETE]: {
              [ButtonTypes.PRIMARY]: {
                label: t(
                  'documents:data.documentCardsInfo.buttonLabels.download',
                ),
                color: themeColorTypes.GREY,
                action: { type: 'download' },
                colorVariant: themeColorVariants.DARKER,
                startIcon: <FileDownloadOutlinedIcon sx={{ mr: '-0.2rem' }} />,
              },
              [ButtonTypes.SECONDARY]: {
                label: t(
                  'documents:data.documentCardsInfo.buttonLabels.preview',
                ),
                color: themeColorTypes.GREY,
                action: {
                  type: 'modal',
                  modalType: 'preview',
                  omitPageNumbers: [1, 2],
                  previewModalType: 'generated',
                },
                colorVariant: themeColorVariants.DARKER,
                startIcon: <VisibilityOutlinedIcon sx={{ mr: '-0.2rem' }} />,
              },
              [ButtonTypes.TERTIARY]: {
                label: t('documents:data.documentCardsInfo.buttonLabels.edit'),
                color: themeColorTypes.GREY,
                colorVariant: themeColorVariants.DARKER,
                startIcon: <EditOutlinedIcon sx={{ mr: '-0.25rem' }} />,
                action: {
                  type: 'navigate',
                  path: '/dashboard/summary#poa-for-care',
                },
              },
            },
            [StatusTypes.PAYMENT_REQUIRED]: {
              [ButtonTypes.PRIMARY]: {
                fullWidth: true,
                variant: 'outlined',
                label: t(
                  'documents:data.documentCardsInfo.buttonLabels.completePayment',
                ),
                color: themeColorTypes.ACCENT_1,
                action: {
                  type: 'navigate',
                  path: '/dashboard/add-ons/printing',
                },
                endIcon: <LockOutlinedIcon sx={{ ml: '-0.1rem' }} />,
              },
            },
            [StatusTypes.CONTACT_US]: {
              [ButtonTypes.PRIMARY]: {
                fullWidth: true,
                label: t(
                  'documents:data.documentCardsInfo.buttonLabels.getStarted',
                ),
                variant: 'contained',
                color: themeColorTypes.NEUTRAL,
                action: {
                  type: 'modal',
                  data: poaContactModalData,
                  modalType: 'contact',
                },
                endIcon: <ArrowForwardIcon sx={{ ml: '-0.1rem' }} />,
              },
            },
          },
        },
      ],
    },

    //
    //
    // ESTATE PLANNING
    //
    //
    {
      label: t(sectionLabels(t).estatePlanning.tKey),
      cards: [
        // TAG: notify-appointees
        // NOTIFY APPOINTEES //
        {
          name: 'notifyAppointees',
          label: t('documents:data.documentCardsInfo.notifyAppointees.label'),
          description: [
            t(
              'documents:data.documentCardsInfo.notifyAppointees.description.item1',
            ),
          ],
          trigger: {
            $where: () =>
              hasPaidCoreDocument && !answerStoreUpdatedSinceGenerate,
          },
          status: () =>
            toggles?.[CONSTANTS.toggleTypes.HAS_SEEN_NOTIFY_APPOINTEES]
              ? StatusTypes.COMPLETE
              : StatusTypes.GET_STARTED,
          buttons: {
            [StatusTypes.GET_STARTED]: {
              [ButtonTypes.PRIMARY]: {
                label: t(
                  'documents:data.documentCardsInfo.buttonLabels.notify',
                ),
                variant: 'contained',
                color: themeColorTypes.NEUTRAL,
                action: {
                  type: 'modal',
                  modalType: 'custom',
                  autoOpenModal:
                    autoOpenModalType ===
                    CONSTANTS.autoOpenModalTypes.NOTIFY_APPOINTEES,
                  data: { element: <NotifyAppointees /> },
                },
                endIcon: <ArrowForwardIcon sx={{ ml: '-0.15rem' }} />,
                onClick: () => {
                  handleUpdateToggles({
                    token,
                    questionnaireId,
                    toggleOn: [
                      CONSTANTS.toggleTypes.HAS_SEEN_NOTIFY_APPOINTEES,
                    ],
                  })
                },
              },
            },
            [StatusTypes.COMPLETE]: {
              [ButtonTypes.PRIMARY]: {
                label: t(
                  'documents:data.documentCardsInfo.buttonLabels.notify',
                ),
                color: themeColorTypes.GREY,
                colorVariant: themeColorVariants.DARKER,
                action: {
                  type: 'modal',
                  modalType: 'custom',
                  autoOpenModal:
                    autoOpenModalType ===
                    CONSTANTS.autoOpenModalTypes.NOTIFY_APPOINTEES,
                  data: { element: <NotifyAppointees /> },
                },
                endIcon: <ArrowForwardIcon sx={{ ml: '-0.15rem' }} />,
              },
            },
          },
        },

        // LIFE INSURANCE (RBC - Gender === male | felmale) //
        /*
         * Keep in mind that there is an RBC life insurance card
         * below that captures users within the life insurance quote
         * age range, but are NOT male or female. As well, there is
         * a non-RBC life insurance card in the FINANCIAL PLANNING DOCUMENTS
         * section below that captures users older than the life insurance quote
         * age. Changes made to this card may need to be made to the two other
         * life insurance cards below, too.
         */
        {
          name: 'lifeInsurance',
          label: t(
            'documents:data.documentCardsInfo.lifeInsuranceGender.label',
          ),
          trigger: {
            $where: () =>
              !_isEmpty(coverageAmounts) &&
              // TAG: TD setup
              referrer?.ref !== 'td',
          },
          description:
            answerStore?.hasLifeInsurance === 'yes'
              ? [
                  t(
                    'documents:data.documentCardsInfo.lifeInsuranceGender.description.item1.case1.item1',
                  ),
                  t(
                    'documents:data.documentCardsInfo.lifeInsuranceGender.description.item1.case1.item2',
                  ),
                ]
              : [
                  t(
                    'documents:data.documentCardsInfo.lifeInsuranceGender.description.item1.case2.item1',
                  ),
                  t(
                    'documents:data.documentCardsInfo.lifeInsuranceGender.description.item1.case2.item2',
                  ),
                ],
          status: () =>
            toggles?.[CONSTANTS.toggleTypes.HAS_SEEN_LIFE_INSURANCE_QUOTE]
              ? StatusTypes.COMPLETE
              : StatusTypes.GET_STARTED,
          buttons: {
            [StatusTypes.GET_STARTED]: {
              [ButtonTypes.PRIMARY]: {
                label: t(
                  'documents:data.documentCardsInfo.buttonLabels.seeMyQuote',
                ),
                variant: 'contained',
                color: themeColorTypes.NEUTRAL,
                action: {
                  type: 'modal',
                  modalType: 'custom',
                  autoOpenModal:
                    autoOpenModalType ===
                    CONSTANTS.autoOpenModalTypes.LIFE_INSURANCE,
                  data: {
                    element: (
                      <LifeInsuranceQuotes
                        initialInsuranceQuotesParams={
                          initialInsuranceQuotesParams
                        }
                      />
                    ),
                    fullBleed: true,
                  },
                },
                endIcon: <ArrowForwardIcon sx={{ ml: '-0.15rem' }} />,
                onClick: () => {
                  handleUpdateToggles({
                    token,
                    questionnaireId,
                    toggleOn: [
                      CONSTANTS.toggleTypes.HAS_SEEN_LIFE_INSURANCE_QUOTE,
                    ],
                  })

                  event.send({
                    name: CONSTANTS.eventNames.LIFE_INSURANCE_MODAL,
                    metadata: { userId, page: 1 },
                    action: CONSTANTS.eventActionTypes.VIEWED,
                  })
                },
              },
            },
            [StatusTypes.COMPLETE]: {
              [ButtonTypes.PRIMARY]: {
                label: t(
                  'documents:data.documentCardsInfo.buttonLabels.seeMyQuote',
                ),
                variant: 'contained',
                color: themeColorTypes.BRAND,
                onClick: () => {
                  event.send({
                    name: CONSTANTS.eventNames.LIFE_INSURANCE_MODAL,
                    metadata: { userId, page: 1 },
                    action: CONSTANTS.eventActionTypes.VIEWED,
                  })
                },
                endIcon: <ArrowForwardIcon sx={{ ml: '-0.15rem' }} />,
                action: {
                  type: 'modal',
                  modalType: 'custom',
                  autoOpenModal:
                    autoOpenModalType ===
                    CONSTANTS.autoOpenModalTypes.LIFE_INSURANCE,
                  data: {
                    element: (
                      <LifeInsuranceQuotes
                        initialInsuranceQuotesParams={
                          initialInsuranceQuotesParams
                        }
                      />
                    ),
                    fullBleed: true,
                  },
                },
              },
            },
          },
        },

        // LIFE INSURANCE (RBC - Gender !== male | felmale) //
        /*
         * Keep in mind that there is an RBC life insurance card
         * above that captures users within the life insurance quote
         * age range that are male or female. As well, there is a
         * non-RBC life insurance card below in the FINANCIAL PLANNING
         * DOCUMENTS section below that captures users older than the life
         * insurance quote age. Changes made to this card may need to be
         * made to the two other life insurance cards below, too.
         */
        {
          name: 'lifeInsurance',
          label: t(
            'documents:data.documentCardsInfo.lifeInsuranceGenderOther.label',
          ),
          trigger: {
            $where: () =>
              isLifeInsuranceAge(age) &&
              _isEmpty(coverageAmounts) &&
              // TAG: TD setup
              referrer?.ref !== 'td',
          },
          description:
            answerStore?.hasLifeInsurance === 'yes'
              ? [
                  t(
                    'documents:data.documentCardsInfo.lifeInsuranceGenderOther.description.item1.case1.item1',
                  ),
                  t(
                    'documents:data.documentCardsInfo.lifeInsuranceGenderOther.description.item1.case1.item2',
                  ),
                ]
              : [
                  t(
                    'documents:data.documentCardsInfo.lifeInsuranceGenderOther.description.item1.case2.item1',
                  ),
                  t(
                    'documents:data.documentCardsInfo.lifeInsuranceGenderOther.description.item1.case2.item2',
                  ),
                ],
          status: () =>
            toggles?.[CONSTANTS.toggleTypes.HAS_CLICKED_LIFE_INSURANCE_LINK]
              ? StatusTypes.COMPLETE
              : StatusTypes.GET_STARTED,
          buttons: {
            [StatusTypes.GET_STARTED]: {
              [ButtonTypes.PRIMARY]: {
                variant: 'contained',
                label: t(
                  'documents:data.documentCardsInfo.buttonLabels.seeMyQuote',
                ),
                color: themeColorTypes.NEUTRAL,
                endIcon: <OpenInNewRoundedIcon sx={{ ml: '-0.15rem' }} />,
                action: {
                  type: 'navigate',
                  openInNewTab: true,
                  path: 'https://www.rbcinsurance.com/life-insurance/life-insurance-quote/index.html',
                },
                onClick: () => {
                  handleUpdateToggles({
                    token,
                    questionnaireId,
                    toggleOn: [
                      CONSTANTS.toggleTypes.HAS_CLICKED_LIFE_INSURANCE_LINK,
                    ],
                  })

                  event.send({
                    metadata: { userId },
                    action: CONSTANTS.eventActionTypes.CLICKED,
                    name: CONSTANTS.eventNames.LIFE_INSURANCE_QUOTE_LINK,
                  })
                },
              },
            },
            [StatusTypes.COMPLETE]: {
              [ButtonTypes.PRIMARY]: {
                variant: 'contained',
                label: t(
                  'documents:data.documentCardsInfo.buttonLabels.seeMyQuote',
                ),
                color: themeColorTypes.BRAND,
                endIcon: <OpenInNewRoundedIcon sx={{ ml: '-0.15rem' }} />,
                action: {
                  type: 'navigate',
                  openInNewTab: true,
                  path: 'https://www.rbcinsurance.com/life-insurance/life-insurance-quote/index.html',
                },
              },
            },
          },
        },

        // FINAL WISHES //
        {
          name: 'wishes',
          label: t('documents:data.documentCardsInfo.wishes.label'),
          description: [
            t('documents:data.documentCardsInfo.wishes.description.item1'),
          ],
          docType: CONSTANTS.docTypes.WISHES,
          paid: _get(keyedGeneratedDocsByType, [
            CONSTANTS.docTypes.WISHES,
            'paid',
          ]),
          created: _get(keyedGeneratedDocsByType, [
            CONSTANTS.docTypes.WISHES,
            'created',
          ]),
          status: ({ documentCreated }) => {
            switch (true) {
              case documentCreated:
                return StatusTypes.COMPLETE

              default:
                return StatusTypes.GET_STARTED
            }
          },
          highlightPills: {
            [StatusTypes.GET_STARTED]: t(
              'documents:data.documentCardsInfo.highlightPills.notStarted',
            ),
            [StatusTypes.COMPLETE]: t(
              'documents:data.documentCardsInfo.highlightPills.complete',
            ),
          },
          buttons: {
            [StatusTypes.COMPLETE]: {
              [ButtonTypes.PRIMARY]: {
                label: t(
                  'documents:data.documentCardsInfo.buttonLabels.download',
                ),
                color: themeColorTypes.GREY,
                action: { type: 'download' },
                colorVariant: themeColorVariants.DARKER,
                startIcon: <FileDownloadOutlinedIcon sx={{ mr: '-0.15rem' }} />,
              },
              [ButtonTypes.SECONDARY]: {
                label: t(
                  'documents:data.documentCardsInfo.buttonLabels.preview',
                ),
                color: themeColorTypes.GREY,
                action: {
                  type: 'modal',
                  omitPageNumbers: [],
                  modalType: 'preview',
                  previewModalType: 'generated',
                },
                colorVariant: themeColorVariants.DARKER,
                startIcon: <VisibilityOutlinedIcon sx={{ mr: '-0.15rem' }} />,
              },
              [ButtonTypes.TERTIARY]: {
                label: t('documents:data.documentCardsInfo.buttonLabels.edit'),
                action: {
                  type: 'modal',
                  modalType: 'bonusDocQuestionnaire',
                  docType: CONSTANTS.docTypes.WISHES,
                },
                color: themeColorTypes.GREY,
                colorVariant: themeColorVariants.DARKER,
                startIcon: <EditOutlinedIcon sx={{ mr: '-0.25rem' }} />,
              },
            },
            [StatusTypes.GET_STARTED]: {
              [ButtonTypes.PRIMARY]: {
                fullWidth: true,
                variant: 'contained',
                label: t(
                  'documents:data.documentCardsInfo.buttonLabels.createForFree',
                ),
                action: {
                  type: 'modal',
                  modalType: 'bonusDocQuestionnaire',
                  docType: CONSTANTS.docTypes.WISHES,
                },
                color: themeColorTypes.NEUTRAL,
                endIcon: <ArrowForwardIcon sx={{ ml: '-0.1rem' }} />,
              },
            },
          },
        },

        // CREMATION //
        {
          name: 'prePlannedCremation',
          label: t(
            'documents:data.documentCardsInfo.prePlannedCremation.label',
          ),
          description: [
            t(
              'documents:data.documentCardsInfo.prePlannedCremation.description.item1',
            ),
          ],
          trigger: {
            $and: [
              { 'bonusDocsAnswerStore.wishes.wishes_body': 'cremated' },
              {
                $or: [
                  // These are the only provinces Eirene services
                  { 'answerStore.province': CONSTANTS.provinces.ALBERTA },
                  { 'answerStore.province': CONSTANTS.provinces.NOVA_SCOTIA },
                  { 'answerStore.province': CONSTANTS.provinces.ONTARIO },
                  { 'answerStore.province': CONSTANTS.provinces.SASKATCHEWAN },
                ],
              },
            ],
          },
          status: () =>
            toggles?.[CONSTANTS.toggleTypes.HAS_SEEN_PRE_PLANNED_CREMATION]
              ? StatusTypes.COMPLETE
              : StatusTypes.GET_STARTED,
          buttons: {
            [StatusTypes.GET_STARTED]: {
              [ButtonTypes.PRIMARY]: {
                label: t(
                  'documents:data.documentCardsInfo.buttonLabels.learnMore',
                ),
                color: themeColorTypes.GREY,
                colorVariant: themeColorVariants.DARKER,
                endIcon: <ArrowForwardIcon sx={{ ml: '-0.15rem' }} />,
                action: {
                  type: 'modal',
                  modalType: 'informational',
                  data: {
                    header: {
                      icon: EireneLogo,
                      iconMaxWidth: '160px',
                      label: t(
                        'documents:data.documentCardsInfo.prePlannedCremation.modal.label',
                      ),
                      subLabel: t(
                        'documents:data.documentCardsInfo.prePlannedCremation.modal.subLabel',
                      ),
                    },
                    body: [
                      {
                        children: [
                          {
                            markdown: prePlannedCremationMarkdown(
                              i18n.language,
                            ),
                          },
                        ],
                        buttons: [
                          {
                            label: t(
                              'documents:data.documentCardsInfo.prePlannedCremation.modal.buttonLabel',
                            ),
                            color: themeColorTypes.ACCENT_2,
                            url: 'https://eirene.ca/partners/epilogue',
                            endIcon: (
                              <ArrowForwardIcon sx={{ ml: '-0.1rem' }} />
                            ),
                          },
                        ],
                      },
                    ],
                  },
                },
                onClick: () => {
                  handleUpdateToggles({
                    token,
                    questionnaireId,
                    toggleOn: [
                      CONSTANTS.toggleTypes.HAS_SEEN_PRE_PLANNED_CREMATION,
                    ],
                  })
                },
              },
            },
            [StatusTypes.COMPLETE]: {
              [ButtonTypes.PRIMARY]: {
                label: t(
                  'documents:data.documentCardsInfo.buttonLabels.learnMore',
                ),
                color: themeColorTypes.GREY,
                colorVariant: themeColorVariants.DARKER,
                endIcon: <ArrowForwardIcon sx={{ ml: '-0.15rem' }} />,
                action: {
                  type: 'modal',
                  modalType: 'informational',
                  data: {
                    header: {
                      icon: EireneLogo,
                      iconMaxWidth: '160px',
                      label: t(
                        'documents:data.documentCardsInfo.prePlannedCremation.modal.label',
                      ),
                      subLabel: t(
                        'documents:data.documentCardsInfo.prePlannedCremation.modal.subLabel',
                      ),
                    },
                    body: [
                      {
                        children: [
                          {
                            markdown: prePlannedCremationMarkdown(
                              i18n.language,
                            ),
                          },
                        ],
                        buttons: [
                          {
                            label: t(
                              'documents:data.documentCardsInfo.prePlannedCremation.modal.buttonLabel',
                            ),
                            color: themeColorTypes.ACCENT_2,
                            url: 'https://eirene.ca/partners/epilogue',
                            endIcon: (
                              <ArrowForwardIcon sx={{ ml: '-0.1rem' }} />
                            ),
                          },
                        ],
                      },
                    ],
                  },
                },
              },
            },
          },
        },

        // GIFT OF LIFE
        {
          name: 'giftOfLife',
          label: t('documents:data.documentCardsInfo.giftOfLife.label'),
          description: [
            t('documents:data.documentCardsInfo.giftOfLife.description.item1'),
          ],
          trigger: {
            $or: [
              {
                'bonusDocsAnswerStore.wishes.wishes_organDonation':
                  'transplant',
              },
              {
                'bonusDocsAnswerStore.wishes.wishes_organDonation':
                  'transplantResearch',
              },
              {
                'bonusDocsAnswerStore.wishes.wishes_tissueDonation':
                  'transplant',
              },
              {
                'bonusDocsAnswerStore.wishes.wishes_tissueDonation':
                  'transplantResearch',
              },
            ],
          },
          status: () =>
            toggles?.[CONSTANTS.toggleTypes.HAS_CLICKED_REGISTER_GIFT_OF_LIFE]
              ? StatusTypes.COMPLETE
              : StatusTypes.GET_STARTED,
          buttons: {
            [StatusTypes.GET_STARTED]: {
              [ButtonTypes.PRIMARY]: {
                variant: 'contained',
                label: t(
                  'documents:data.documentCardsInfo.buttonLabels.registerNow',
                ),
                color: themeColorTypes.NEUTRAL,
                endIcon: <OpenInNewRoundedIcon sx={{ ml: '-0.15rem' }} />,
                action: {
                  type: 'navigate',
                  openInNewTab: true,
                  path: 'https://www.donateyourorgans.ca',
                },
                onClick: () => {
                  handleUpdateToggles({
                    token,
                    questionnaireId,
                    toggleOn: [
                      CONSTANTS.toggleTypes.HAS_CLICKED_REGISTER_GIFT_OF_LIFE,
                    ],
                  })
                },
              },
            },
            [StatusTypes.COMPLETE]: {
              [ButtonTypes.PRIMARY]: {
                variant: 'contained',
                label: t(
                  'documents:data.documentCardsInfo.buttonLabels.registerNow',
                ),
                endIcon: <OpenInNewRoundedIcon sx={{ ml: '-0.15rem' }} />,
                action: {
                  type: 'navigate',
                  openInNewTab: true,
                  path: 'https://www.donateyourorgans.ca',
                },
                color: themeColorTypes.BRAND,
              },
            },
          },
        },

        // CANADA WILL REGISTRY //
        {
          name: 'canadaWillRegistry',
          label: t('documents:data.documentCardsInfo.canadaWillRegistry.label'),
          description: [
            t(
              'documents:data.documentCardsInfo.canadaWillRegistry.description.item1',
            ),
          ],
          // Triggered when user is paid AND value for noticeConnectCode exists
          trigger: {
            $where: () => hasCoreProductPayment && !!noticeConnectCode,
          },
          status: () =>
            toggles?.[CONSTANTS.toggleTypes.HAS_SEEN_CANADA_WILL_REGISTRY_CODE]
              ? StatusTypes.COMPLETE
              : StatusTypes.GET_STARTED,
          buttons: {
            [StatusTypes.GET_STARTED]: {
              [ButtonTypes.PRIMARY]: {
                label: t(
                  'documents:data.documentCardsInfo.buttonLabels.getMyCode',
                ),
                variant: 'contained',
                color: themeColorTypes.NEUTRAL,
                onClick: () => {
                  handleUpdateToggles({
                    token,
                    questionnaireId,
                    toggleOn: [
                      CONSTANTS.toggleTypes.HAS_SEEN_CANADA_WILL_REGISTRY_CODE,
                    ],
                  })
                },
                action: {
                  type: 'modal',
                  modalType: 'informational',
                  data: [
                    {
                      trigger: {
                        'answerStore.province':
                          CONSTANTS.provinces.BRITISH_COLUMBIA,
                      },
                      header: {
                        label: t(
                          'documents:data.documentCardsInfo.canadaWillRegistry.modal.label',
                        ),
                      },
                      body: [
                        {
                          children: [
                            {
                              markdown: canadaWillRegistryBcYes(
                                /*
                                 * We know the noticeConnectCode exists because this document
                                 * card will only be triggered if the noticeConnectCode exists.
                                 */
                                noticeConnectCode as string,
                                i18n.language,
                              ),
                            },
                          ],

                          buttons: [
                            {
                              label: t(
                                'documents:data.documentCardsInfo.canadaWillRegistry.modal.buttonLabel',
                              ),
                              url: 'https://www.canadawillregistry.org/consumers/',
                              color: themeColorTypes.ACCENT_2,
                              endIcon: (
                                <OpenInNewRoundedIcon sx={{ ml: '-0.15rem' }} />
                              ),
                            },
                          ],
                        },
                      ],
                    },
                    {
                      trigger: {
                        'answerStore.province': {
                          $ne: CONSTANTS.provinces.BRITISH_COLUMBIA,
                        },
                      },
                      header: {
                        label: t(
                          'documents:data.documentCardsInfo.canadaWillRegistry.modal.label',
                        ),
                      },
                      body: [
                        {
                          children: [
                            {
                              markdown: canadaWillRegistryBcNo(
                                /*
                                 * We know the noticeConnectCode exists because this document
                                 * card will only be triggered if the noticeConnectCode exists.
                                 */
                                noticeConnectCode as string,
                                i18n.language,
                              ),
                            },
                          ],

                          buttons: [
                            {
                              label: t(
                                'documents:data.documentCardsInfo.canadaWillRegistry.modal.buttonLabel',
                              ),
                              url: 'https://www.canadawillregistry.org/consumers/',
                              color: themeColorTypes.ACCENT_2,
                              endIcon: (
                                <OpenInNewRoundedIcon sx={{ ml: '-0.15rem' }} />
                              ),
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                endIcon: <ArrowForwardIcon sx={{ ml: '-0.15rem' }} />,
              },
            },
            [StatusTypes.COMPLETE]: {
              [ButtonTypes.PRIMARY]: {
                label: t(
                  'documents:data.documentCardsInfo.buttonLabels.seeMyCode',
                ),
                color: themeColorTypes.GREY,
                colorVariant: themeColorVariants.DARKER,
                action: {
                  type: 'modal',
                  modalType: 'informational',
                  data: [
                    {
                      trigger: {
                        'answerStore.province':
                          CONSTANTS.provinces.BRITISH_COLUMBIA,
                      },
                      header: {
                        label: t(
                          'documents:data.documentCardsInfo.canadaWillRegistry.modal.label',
                        ),
                      },
                      body: [
                        {
                          children: [
                            {
                              markdown: canadaWillRegistryBcYes(
                                /*
                                 * We know the noticeConnectCode exists because this document
                                 * card will only be triggered if the noticeConnectCode exists.
                                 */
                                noticeConnectCode as string,
                                i18n.language,
                              ),
                            },
                          ],

                          buttons: [
                            {
                              label: t(
                                'documents:data.documentCardsInfo.canadaWillRegistry.modal.buttonLabel',
                              ),
                              url: 'https://www.canadawillregistry.org/consumers/',
                              color: themeColorTypes.ACCENT_2,
                              endIcon: (
                                <OpenInNewRoundedIcon sx={{ ml: '-0.15rem' }} />
                              ),
                            },
                          ],
                        },
                      ],
                    },
                    {
                      trigger: {
                        'answerStore.province': {
                          $ne: CONSTANTS.provinces.BRITISH_COLUMBIA,
                        },
                      },
                      header: {
                        label: t(
                          'documents:data.documentCardsInfo.canadaWillRegistry.modal.label',
                        ),
                      },
                      body: [
                        {
                          children: [
                            {
                              markdown: canadaWillRegistryBcNo(
                                /*
                                 * We know the noticeConnectCode exists because this document
                                 * card will only be triggered if the noticeConnectCode exists.
                                 */
                                noticeConnectCode as string,
                                i18n.language,
                              ),
                            },
                          ],

                          buttons: [
                            {
                              label: t(
                                'documents:data.documentCardsInfo.canadaWillRegistry.modal.buttonLabel',
                              ),
                              url: 'https://www.canadawillregistry.org/consumers/',
                              color: themeColorTypes.ACCENT_2,
                              endIcon: (
                                <OpenInNewRoundedIcon sx={{ ml: '-0.15rem' }} />
                              ),
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                endIcon: <ArrowForwardIcon sx={{ ml: '-0.15rem' }} />,
              },
            },
          },
        },

        // ASSET LIST //
        {
          name: 'assetList',
          label: t('documents:data.documentCardsInfo.assetList.label'),
          description: [
            t('documents:data.documentCardsInfo.assetList.description.item1'),
          ],
          docType: CONSTANTS.docTypes.ASSET_LIST,
          status: ({ staticDocumentStatusOverride }) =>
            staticDocumentStatusOverride || StatusTypes.GET_STARTED,
          highlightPills: {
            [StatusTypes.GET_STARTED]: t(
              'documents:data.documentCardsInfo.highlightPills.notDownloaded',
            ),
            [StatusTypes.COMPLETE]: t(
              'documents:data.documentCardsInfo.highlightPills.downloaded',
            ),
          },
          buttons: {
            [StatusTypes.GET_STARTED]: {
              [ButtonTypes.PRIMARY]: {
                fullWidth: true,
                variant: 'contained',
                label: t(
                  'documents:data.documentCardsInfo.buttonLabels.download',
                ),
                color: themeColorTypes.NEUTRAL,
                endIcon: <FileDownloadOutlinedIcon sx={{ ml: '-0.1rem' }} />,
                action: {
                  type: 'modal',
                  modalType: 'staticDocDownload',
                  docType: CONSTANTS.docTypes.ASSET_LIST,
                },
              },
            },
            [StatusTypes.COMPLETE]: {
              [ButtonTypes.PRIMARY]: {
                label: t(
                  'documents:data.documentCardsInfo.buttonLabels.download',
                ),
                color: themeColorTypes.GREY,
                colorVariant: themeColorVariants.DARKER,
                startIcon: <FileDownloadOutlinedIcon sx={{ mr: '-0.15rem' }} />,
                action: {
                  type: 'modal',
                  modalType: 'staticDocDownload',
                  docType: CONSTANTS.docTypes.ASSET_LIST,
                },
              },
              [ButtonTypes.SECONDARY]: {
                label: t(
                  'documents:data.documentCardsInfo.buttonLabels.preview',
                ),
                color: themeColorTypes.GREY,
                action: {
                  type: 'modal',
                  omitPageNumbers: [],
                  modalType: 'preview',
                  previewModalType: 'static',
                  docType: CONSTANTS.docTypes.ASSET_LIST,
                },
                colorVariant: themeColorVariants.DARKER,
                startIcon: <VisibilityOutlinedIcon sx={{ mr: '-0.15rem' }} />,
              },
            },
          },
        },
      ],
    },

    //
    //
    // FINANCIAL PLANNING DOCUMENTS
    //
    //
    {
      label: t(sectionLabels(t).financialPlanning.tKey),
      topBanner: featureFlag(flagTypes.SCOTIA_DEMO) && <RbcAdvisorBanner />,
      cards: [
        // LIFE INSURANCE (NON-RBC) //
        /*
         * Keep in mind that there are two RBC life insurance
         * cards in the ESTATE PLANNING section above.
         * Changes made to this card may need to be made to those
         * two cards above as well.
         */
        {
          name: 'lifeInsurance',
          icon: UmbrellaIconNavy,
          label: t(
            'documents:data.documentCardsInfo.lifeInsuranceNonBank.label',
          ),
          trigger: {
            $where: () =>
              (!isLifeInsuranceAge(age) && _isEmpty(coverageAmounts)) ||
              // TAG: TD setup
              referrer?.ref === 'td',
          },
          description:
            answerStore?.hasLifeInsurance === 'yes'
              ? [
                  t(
                    'documents:data.documentCardsInfo.lifeInsuranceNonBank.description.case1.item1',
                  ),
                ]
              : [
                  t(
                    'documents:data.documentCardsInfo.lifeInsuranceNonBank.description.case2.item1',
                  ),
                ],
          status: () => StatusTypes.COMPLETE,
          buttons: {
            [StatusTypes.COMPLETE]: {
              [ButtonTypes.PRIMARY]: {
                label: t(
                  'documents:data.documentCardsInfo.buttonLabels.learnMore',
                ),
                color: themeColorTypes.GREY,
                colorVariant: themeColorVariants.DARKER,
                action: {
                  type: 'modal',
                  modalType: 'informational',
                  data: [
                    {
                      trigger: { 'answerStore.hasLifeInsurance': 'yes' },
                      header: {
                        icon: UmbrellaIconOrange,
                        label: t(
                          'documents:data.documentCardsInfo.lifeInsuranceNonBank.modal.label',
                        ),
                        subLabel: t(
                          'documents:data.documentCardsInfo.lifeInsuranceNonBank.modal.subLabel',
                        ),
                      },
                      body: [
                        {
                          children: [
                            { markdown: lifeInsuranceYes(i18n.language) },
                          ],
                        },
                      ],
                    },
                    {
                      trigger: {
                        'answerStore.hasLifeInsurance': { $ne: 'yes' },
                      },
                      header: {
                        icon: UmbrellaIconOrange,
                        label: t(
                          'documents:data.documentCardsInfo.lifeInsuranceNonBank.modal.label',
                        ),
                        subLabel: t(
                          'documents:data.documentCardsInfo.lifeInsuranceNonBank.modal.subLabel',
                        ),
                      },
                      body: [
                        {
                          children: [
                            { markdown: lifeInsuranceNo(i18n.language) },
                          ],
                        },
                      ],
                    },
                  ],
                },
                endIcon: <ArrowForwardIcon sx={{ ml: '-0.15rem' }} />,
              },
            },
          },
        },

        // RRSP //
        {
          name: 'rrsp',
          icon: VaultIconNavy,
          trigger: {
            $where: () =>
              investmentAccountOptions.includes(InvestmentAccountTypes.RRSP),
          },
          label: t('documents:data.documentCardsInfo.rrsp.label'),
          description: answerStore?.investmentAccounts?.includes(
            InvestmentAccountTypes.RRSP,
          )
            ? [
                t(
                  'documents:data.documentCardsInfo.rrsp.description.case1.item1',
                ),
              ]
            : [
                t(
                  'documents:data.documentCardsInfo.rrsp.description.case2.item1',
                ),
                t(
                  'documents:data.documentCardsInfo.rrsp.description.case2.item2',
                ),
              ],
          status: () => StatusTypes.COMPLETE,
          buttons: {
            [StatusTypes.COMPLETE]: {
              [ButtonTypes.PRIMARY]: {
                label: t(
                  'documents:data.documentCardsInfo.buttonLabels.learnMore',
                ),
                color: themeColorTypes.GREY,
                colorVariant: themeColorVariants.DARKER,
                action: {
                  type: 'modal',
                  modalType: 'informational',
                  data: [
                    {
                      trigger: {
                        'answerStore.investmentAccounts': {
                          $nin: [InvestmentAccountTypes.RRSP],
                        },
                      },
                      header: {
                        icon: VaultIconOrange,
                        label: t(
                          'documents:data.documentCardsInfo.rrsp.modal.label',
                        ),
                        subLabel: t(
                          'documents:data.documentCardsInfo.rrsp.modal.subLabel',
                        ),
                      },
                      body: [
                        {
                          children: [{ markdown: rrspNo(i18n.language) }],
                        },
                      ],
                    },
                    {
                      trigger: {
                        'answerStore.investmentAccounts': {
                          $in: [InvestmentAccountTypes.RRSP],
                        },
                      },
                      header: {
                        icon: VaultIconOrange,
                        label: t(
                          'documents:data.documentCardsInfo.rrsp.modal.label',
                        ),
                        subLabel: t(
                          'documents:data.documentCardsInfo.rrsp.modal.subLabel',
                        ),
                      },
                      body: [
                        {
                          children: [{ markdown: rrspYes(i18n.language) }],
                        },
                      ],
                    },
                  ],
                },
                endIcon: <ArrowForwardIcon sx={{ ml: '-0.15rem' }} />,
              },
            },
          },
        },

        // RRIF //
        {
          name: 'rrif',
          icon: DrinkIconNavy,
          trigger: {
            $or: [
              {
                'answerStore.investmentAccounts': {
                  $in: [InvestmentAccountTypes.RRIF],
                },
              },
              {
                $and: [
                  {
                    $where: () =>
                      investmentAccountOptions.includes(
                        InvestmentAccountTypes.RRIF,
                      ),
                  },
                  {
                    'answerStore.investmentAccounts': {
                      $in: [InvestmentAccountTypes.RRSP],
                    },
                  },
                ],
              },
            ],
          },
          label: t('documents:data.documentCardsInfo.rrif.label'),
          description: answerStore?.investmentAccounts?.includes(
            InvestmentAccountTypes.RRIF,
          )
            ? [
                t(
                  'documents:data.documentCardsInfo.rrif.description.case1.item1',
                ),
              ]
            : [
                t(
                  'documents:data.documentCardsInfo.rrif.description.case2.item1',
                ),
                t(
                  'documents:data.documentCardsInfo.rrif.description.case2.item2',
                ),
              ],
          status: () => StatusTypes.COMPLETE,
          buttons: {
            [StatusTypes.COMPLETE]: {
              [ButtonTypes.PRIMARY]: {
                label: t(
                  'documents:data.documentCardsInfo.buttonLabels.learnMore',
                ),
                color: themeColorTypes.GREY,
                colorVariant: themeColorVariants.DARKER,
                action: {
                  type: 'modal',
                  modalType: 'informational',
                  data: [
                    {
                      trigger: {
                        'answerStore.investmentAccounts': {
                          $in: [InvestmentAccountTypes.RRIF],
                        },
                      },
                      header: {
                        icon: DrinkIconOrange,
                        label: t(
                          'documents:data.documentCardsInfo.rrif.modal.label',
                        ),
                        subLabel: t(
                          'documents:data.documentCardsInfo.rrif.modal.subLabel',
                        ),
                      },
                      body: [
                        { children: [{ markdown: rrifYes(i18n.language) }] },
                      ],
                    },
                    {
                      trigger: {
                        'answerStore.investmentAccounts': {
                          $nin: [InvestmentAccountTypes.RRIF],
                        },
                      },
                      header: {
                        icon: DrinkIconOrange,
                        label: t(
                          'documents:data.documentCardsInfo.rrif.modal.label',
                        ),
                        subLabel: t(
                          'documents:data.documentCardsInfo.rrif.modal.subLabel',
                        ),
                      },
                      body: [
                        { children: [{ markdown: rrifNo(i18n.language) }] },
                      ],
                    },
                  ],
                },
                endIcon: <ArrowForwardIcon sx={{ ml: '-0.15rem' }} />,
              },
            },
          },
        },

        // RESP //
        {
          name: 'resp',
          icon: GraduationHatIconNavy,
          trigger: { 'answerStore.minorChildren': 'yes' },
          label: t('documents:data.documentCardsInfo.resp.label'),
          description:
            answerStore?.hasResps === 'yes'
              ? [
                  t(
                    'documents:data.documentCardsInfo.resp.description.case1.item1',
                  ),
                  t(
                    'documents:data.documentCardsInfo.resp.description.case1.item2',
                  ),
                ]
              : [
                  t(
                    'documents:data.documentCardsInfo.resp.description.case2.item1',
                  ),
                ],
          status: () => StatusTypes.COMPLETE,
          buttons: {
            [StatusTypes.COMPLETE]: {
              [ButtonTypes.PRIMARY]: {
                label: t(
                  'documents:data.documentCardsInfo.buttonLabels.learnMore',
                ),
                color: themeColorTypes.GREY,
                colorVariant: themeColorVariants.DARKER,
                action: {
                  type: 'modal',
                  modalType: 'informational',

                  data: [
                    {
                      trigger: { 'answerStore.hasResps': 'yes' },
                      header: {
                        icon: GraduationHatIconOrange,
                        label: t(
                          'documents:data.documentCardsInfo.resp.modal.label',
                        ),
                        subLabel: t(
                          'documents:data.documentCardsInfo.resp.modal.subLabel',
                        ),
                      },
                      body: [
                        { children: [{ markdown: respYes(i18n.language) }] },
                      ],
                    },
                    {
                      trigger: { 'answerStore.hasResps': { $ne: 'yes' } },
                      header: {
                        icon: GraduationHatIconOrange,
                        label: t(
                          'documents:data.documentCardsInfo.resp.modal.label',
                        ),
                        subLabel: t(
                          'documents:data.documentCardsInfo.resp.modal.subLabel',
                        ),
                      },
                      body: [
                        { children: [{ markdown: respNo(i18n.language) }] },
                      ],
                    },
                  ],
                },
                endIcon: <ArrowForwardIcon sx={{ ml: '-0.15rem' }} />,
              },
            },
          },
        },

        // TFSA //
        {
          name: 'tfsa',
          icon: WalletIconNavy,
          label: t('documents:data.documentCardsInfo.tfsa.label'),
          description: answerStore?.investmentAccounts?.includes(
            InvestmentAccountTypes.TFSA,
          )
            ? [
                t(
                  'documents:data.documentCardsInfo.tfsa.description.case1.item1',
                ),
              ]
            : [
                t(
                  'documents:data.documentCardsInfo.tfsa.description.case2.item1',
                ),
              ],
          status: () => StatusTypes.COMPLETE,
          buttons: {
            [StatusTypes.COMPLETE]: {
              [ButtonTypes.PRIMARY]: {
                label: t(
                  'documents:data.documentCardsInfo.buttonLabels.learnMore',
                ),
                color: themeColorTypes.GREY,
                colorVariant: themeColorVariants.DARKER,
                action: {
                  type: 'modal',
                  modalType: 'informational',
                  data: [
                    {
                      trigger: {
                        'answerStore.investmentAccounts': {
                          $in: [InvestmentAccountTypes.TFSA],
                        },
                      },
                      header: {
                        icon: WalletIconOrange,
                        label: t(
                          'documents:data.documentCardsInfo.tfsa.modal.label',
                        ),
                        subLabel: t(
                          'documents:data.documentCardsInfo.tfsa.modal.subLabel',
                        ),
                      },
                      body: [
                        { children: [{ markdown: tfsaYes(i18n.language) }] },
                      ],
                    },
                    {
                      trigger: {
                        'answerStore.investmentAccounts': {
                          $nin: [InvestmentAccountTypes.TFSA],
                        },
                      },
                      header: {
                        icon: WalletIconOrange,
                        label: t(
                          'documents:data.documentCardsInfo.tfsa.modal.label',
                        ),
                        subLabel: t(
                          'documents:data.documentCardsInfo.tfsa.modal.subLabel',
                        ),
                      },
                      body: [
                        { children: [{ markdown: tfsaNo(i18n.language) }] },
                      ],
                    },
                  ],
                },
                endIcon: <ArrowForwardIcon sx={{ ml: '-0.15rem' }} />,
              },
            },
          },
        },

        // FHSA //
        {
          name: 'fhsa',
          icon: HouseIconNavy,
          trigger: {
            $or: [
              {
                $and: [
                  {
                    'answerStore.investmentAccounts': {
                      $in: [InvestmentAccountTypes.FHSA],
                    },
                  },
                  { 'answerStore.canadianRealEstate': 'no' },
                ],
              },
              {
                $and: [
                  {
                    'answerStore.investmentAccounts': {
                      $nin: [InvestmentAccountTypes.FHSA],
                    },
                  },
                  { 'answerStore.canadianRealEstate': 'no' },
                  { 'answerStore.hasForeignProperty': 'no' },
                  { $where: () => age < 40 },
                ],
              },
            ],
          },
          label: t('documents:data.documentCardsInfo.fhsa.label'),
          description: answerStore?.investmentAccounts?.includes(
            InvestmentAccountTypes.FHSA,
          )
            ? [
                t(
                  'documents:data.documentCardsInfo.fhsa.description.case1.item1',
                ),
              ]
            : [
                t(
                  'documents:data.documentCardsInfo.fhsa.description.case2.item1',
                ),
                t(
                  'documents:data.documentCardsInfo.fhsa.description.case2.item2',
                ),
              ],
          status: () => StatusTypes.COMPLETE,
          buttons: {
            [StatusTypes.COMPLETE]: {
              [ButtonTypes.PRIMARY]: {
                label: t(
                  'documents:data.documentCardsInfo.buttonLabels.learnMore',
                ),
                color: themeColorTypes.GREY,
                colorVariant: themeColorVariants.DARKER,
                action: {
                  type: 'modal',
                  modalType: 'informational',
                  data: [
                    // TAG: TD setup
                    {
                      trigger: {
                        'referrer.ref': 'td',
                        'answerStore.investmentAccounts': {
                          $in: [InvestmentAccountTypes.FHSA],
                        },
                      },
                      header: {
                        icon: HouseIconOrange,
                        label: t(
                          'documents:data.documentCardsInfo.fhsa.modal.label',
                        ),
                        subLabel: t(
                          'documents:data.documentCardsInfo.fhsa.modal.subLabel',
                        ),
                      },
                      body: [
                        { children: [{ markdown: fhsaYes(i18n.language) }] },
                      ],
                    },
                    // TAG: TD setup
                    {
                      trigger: {
                        'referrer.ref': 'td',
                        'answerStore.investmentAccounts': {
                          $nin: [InvestmentAccountTypes.FHSA],
                        },
                      },
                      header: {
                        icon: HouseIconOrange,
                        label: t(
                          'documents:data.documentCardsInfo.fhsa.modal.label',
                        ),
                        subLabel: t(
                          'documents:data.documentCardsInfo.fhsa.modal.subLabel',
                        ),
                      },
                      body: [
                        { children: [{ markdown: fhsaNo(i18n.language) }] },
                      ],
                    },
                    {
                      trigger: {
                        'answerStore.investmentAccounts': {
                          $in: [InvestmentAccountTypes.FHSA],
                        },
                      },
                      header: {
                        icon: HouseIconOrange,
                        label: t(
                          'documents:data.documentCardsInfo.fhsa.modal.label',
                        ),
                        subLabel: t(
                          'documents:data.documentCardsInfo.fhsa.modal.subLabel',
                        ),
                      },
                      body: [
                        { children: [{ markdown: fhsaYes(i18n.language) }] },
                      ],
                      article: {
                        label: t(
                          'documents:data.documentCardsInfo.fhsa.modal.fhsaYesArticle.label',
                        ),
                        description: t(
                          'documents:data.documentCardsInfo.fhsa.modal.fhsaYesArticle.description',
                        ),
                        url: 'https://discover.rbcroyalbank.com/fhsa-9-questions-answered-about-the-new-first-home-savings-account/?utm_source=epilogue&utm_medium=referral&utm_campaign=invst_epilogue_yes_fhsa',
                        imgSrc:
                          'https://epilogue-assets-1.s3.ca-central-1.amazonaws.com/fhsa-blog-image.webp',
                      },
                    },
                    {
                      trigger: {
                        'answerStore.investmentAccounts': {
                          $nin: [InvestmentAccountTypes.FHSA],
                        },
                      },
                      header: {
                        icon: HouseIconOrange,
                        label: t(
                          'documents:data.documentCardsInfo.fhsa.modal.label',
                        ),
                        subLabel: t(
                          'documents:data.documentCardsInfo.fhsa.modal.subLabel',
                        ),
                      },
                      body: [
                        { children: [{ markdown: fhsaNo(i18n.language) }] },
                      ],
                      article: {
                        label: t(
                          'documents:data.documentCardsInfo.fhsa.modal.fhsaNoArticle.label',
                        ),
                        description: t(
                          'documents:data.documentCardsInfo.fhsa.modal.fhsaNoArticle.description',
                        ),
                        url: 'https://www.rbcroyalbank.com/investments/fhsa.html?utm_source=epilogue&utm_medium=referral&utm_campaign=invst_epilogue_no_fhsa',
                        imgSrc:
                          'https://epilogue-assets-1.s3.ca-central-1.amazonaws.com/fhsa-blog-image.webp',
                      },
                    },
                  ],
                },
                endIcon: <ArrowForwardIcon sx={{ ml: '-0.15rem' }} />,
              },
            },
          },
        },

        // MORTGAGE //
        {
          name: 'mortgage',
          icon: HouseIconNavy,
          trigger: {
            $and: [
              // TAG: TD setup
              { 'referrer.ref': { $ne: 'td' } },
              { 'answerStore.canadianRealEstate': 'yes' },
              { $where: () => featureFlag(flagTypes.SCOTIA_DEMO) },
            ],
          },
          label: t('documents:data.documentCardsInfo.mortgage.label'),
          description: [
            t('documents:data.documentCardsInfo.mortgage.description'),
          ],
          status: () => StatusTypes.COMPLETE,
          buttons: {
            [StatusTypes.COMPLETE]: {
              [ButtonTypes.PRIMARY]: {
                label: t(
                  'documents:data.documentCardsInfo.buttonLabels.learnMore',
                ),
                color: themeColorTypes.GREY,
                colorVariant: themeColorVariants.DARKER,
                action: {
                  type: 'navigate',
                  openInNewTab: true,
                  path: 'https://www.rbcroyalbank.com/dms/mortgages/switch/spring-2023/index.html',
                },
                endIcon: <ArrowForwardIcon sx={{ ml: '-0.15rem' }} />,
              },
            },
          },
        },
      ],
    },

    //
    //
    // MISCELLANEOUS
    //
    //
    {
      label: t(sectionLabels(t).miscellaneous.tKey),
      cards: [
        // CUSTOMER REFERRAL //
        {
          name: 'customReferralLink',
          icon: MegaphoneIconNavy,
          label: t('documents:data.documentCardsInfo.customReferralLink.label'),
          description: [
            t(
              'documents:data.documentCardsInfo.customReferralLink.description.item1',
            ),
          ],
          trigger: {
            $where: () => hasCoreProductPayment && !!customerReferralCode,
          },
          status: () => StatusTypes.COMPLETE,
          buttons: {
            [StatusTypes.COMPLETE]: {
              [ButtonTypes.PRIMARY]: {
                label: t(
                  'documents:data.documentCardsInfo.buttonLabels.seeMyLink',
                ),
                color: themeColorTypes.GREY,
                colorVariant: themeColorVariants.DARKER,
                action: {
                  type: 'modal',
                  modalType: 'custom',
                  data: { element: <ReferralLink /> },
                },
                endIcon: <ArrowForwardIcon sx={{ ml: '-0.15rem' }} />,
              },
            },
          },
        },

        // GIFTS //
        {
          name: 'giftAWill',
          icon: GiftIconNavy,
          label: t('documents:data.documentCardsInfo.giftAWill.label'),
          description: [
            t('documents:data.documentCardsInfo.giftAWill.description.item1'),
          ],
          trigger: { $where: () => hasCoreProductPayment },
          status: () => StatusTypes.COMPLETE,
          buttons: {
            [StatusTypes.COMPLETE]: {
              [ButtonTypes.PRIMARY]: {
                label: t(
                  'documents:data.documentCardsInfo.buttonLabels.buyNow',
                ),
                color: themeColorTypes.GREY,
                colorVariant: themeColorVariants.DARKER,
                endIcon: <ArrowForwardIcon sx={{ ml: '-0.15rem' }} />,
                action: {
                  type: 'navigate',
                  path: '/dashboard/add-ons/gift',
                },
              },
            },
          },
        },
      ],
    },
  ]
}
