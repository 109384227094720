import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'

import { motion } from 'framer-motion'
import { useTranslation } from 'react-i18next'

import growAnimation from '../../../../../../../common/animations/growAnimation'
import { ReactComponent as SuccessCheck } from '../../../../../../../common/assets/images/success-check.svg'
import Button from '../../../../../../../common/components/atoms/Button'
import Heading from '../../../../../../../common/components/atoms/Heading'
import Link from '../../../../../../../common/components/atoms/Link'
import NumberInput from '../../../../../../../common/components/atoms/NumberInput'
import Text from '../../../../../../../common/components/atoms/Text'
import TextInput from '../../../../../../../common/components/atoms/TextInput'
import amplitude from '../../../../../../../common/lib/amplitude'
import {
  errorHandler,
  PATHS,
  request,
} from '../../../../../../../common/request'
import {
  themeColorTypes,
  themeColorVariants,
} from '../../../../../../../common/styles/muiTheme'
import {
  emailRegex,
  phoneRegex,
} from '../../../../../utils/validation/patterns'
import LawyerReferralContext from '../../context/LawyerReferralContext'

const StyledSuccessCheck = styled(SuccessCheck)({
  width: '100%',
  height: '100%',
})

const LawyerForm = ({ lawyer }) => {
  const { _id: lawyerId, lawyerFirstName, emailBody } = lawyer

  const isTemplateEmailLawyer = !!emailBody

  const {
    lawyerReferralData,
    setLawyerReferralData,
    dispatchSetGlobalErrorMessage,
  } = useContext(LawyerReferralContext)

  const { referredLawyerIds } = lawyerReferralData

  const [name, setName] = useState(lawyerReferralData.name)
  const [email, setEmail] = useState(lawyerReferralData.email)
  const [phone, setPhone] = useState(lawyerReferralData.phone)

  const [working, setWorking] = useState(false)
  const [formValid, setFormValid] = useState(false)
  const [displayValidation, setDisplayValidation] = useState(false)
  const [success, setSuccess] = useState(referredLawyerIds.includes(lawyerId))

  const validEmail = emailRegex.test(email)
  const validPhoneNumber = phoneRegex.test(phone)

  useEffect(() => {
    setFormValid(name && validEmail && (!phone || validPhoneNumber))
  }, [name, email, phone, validEmail, validPhoneNumber])

  const handleSubmit = async () => {
    if (formValid) {
      setWorking(true)
      setDisplayValidation(false)

      const formData = { name, email, phone }

      try {
        await request({
          method: 'POST',
          url: `${PATHS.LAWYER_REFERRAL}/${lawyerId}`,
          data: formData,
        })
        amplitude.sendEvent('SubmittedLawyerReferralForm')
        setSuccess(true)
        setLawyerReferralData({
          ...formData,
          referredLawyerIds: [...referredLawyerIds, lawyerId],
        })
      } catch (error) {
        errorHandler({
          error,
          reportError: true,
          fallbackErrorMessage:
            'Unable to submit form. Please refresh your browser and try again.',
          onError: (resolvedErrorMessage) =>
            dispatchSetGlobalErrorMessage(resolvedErrorMessage),
        })
      }
      setWorking(false)
    } else {
      setDisplayValidation(true)
    }
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSubmit()
    }
  }

  const { t } = useTranslation()

  return (
    <Box
      width="100%"
      flexGrow="2"
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
    >
      {success ? (
        <Box
          width="100%"
          display="flex"
          alignItems="center"
          flexDirection="column"
          data-testid="lawyer-contact-success"
        >
          <motion.div {...growAnimation}>
            <Box
              sx={{
                width: '37px',
                mt: '0.4rem',
                mb: '1.6rem',
              }}
            >
              <StyledSuccessCheck />
            </Box>
          </motion.div>

          <Heading variant="h4" align="center">
            {t('molecules:LawyerReferral.components.LawyerForm.success.label')}
          </Heading>

          {isTemplateEmailLawyer ? (
            <Heading variant="h4" align="center">
              {t(
                'molecules:LawyerReferral.components.LawyerForm.success.subLabel.templateLawyer',
              )}
            </Heading>
          ) : (
            <Box mt="1rem">
              <Text size="sm" variant="paragraph" align="center">
                {t(
                  'molecules:LawyerReferral.components.LawyerForm.success.subLabel.standardLawyer',
                  { lawyerFirstName },
                )}
              </Text>

              <Text size="sm" variant="paragraph" align="center">
                <Link
                  openNewTab
                  underline="none"
                  href="https://epiloguewills.com/blog/what-to-expect-when-making-your-will-with-a-lawyer/"
                >
                  <Text
                    size="sm"
                    display="initial"
                    variant="paragraph"
                    color={themeColorTypes.ACCENT_1}
                    colorVariant={themeColorVariants.DARK}
                    sx={{ '&:hover': { textDecoration: 'underline' } }}
                  >
                    {t(
                      'molecules:LawyerReferral.components.LawyerForm.success.link.part1',
                    )}
                  </Text>
                </Link>{' '}
                {t(
                  'molecules:LawyerReferral.components.LawyerForm.success.link.part2',
                )}
              </Text>
            </Box>
          )}
        </Box>
      ) : (
        <Box width="100%">
          <Box mb="1rem">
            <Text size="xs" variant="paragraph" align="center">
              {t(
                'molecules:LawyerReferral.components.LawyerForm.default.label',
                { lawyerFirstName },
              )}
            </Text>
          </Box>

          {/*
           * To prevent a form-in-a-form error, we are using a Box/div here.
           * The role attribute allows the div to function the same as a form
           * in terms of accessibility
           * */}
          <Box role="form" onKeyDown={handleKeyDown}>
            <Box
              display="flex"
              rowGap="1rem"
              alignItems="center"
              flexDirection="column"
            >
              <TextInput
                value={name}
                disabled={working}
                validationMessage=""
                placeholder={t('common:fullName')}
                placeholder2="John Doe"
                onInputChange={(val) => setName(val)}
                isInvalid={!name && displayValidation}
              />

              <TextInput
                type="email"
                value={email}
                disabled={working}
                placeholder={t('common:emailAddress')}
                validationMessage=""
                placeholder2="john.doe@example.com"
                onInputChange={(val) => setEmail(val)}
                isInvalid={
                  (!email || (email && !validEmail)) && displayValidation
                }
              />

              <NumberInput
                name="phone"
                value={phone}
                format="phone"
                disabled={working}
                validationMessage=""
                placeholder2="555-555-5555"
                placeholder={`${t('common:phone')} (${t('common:optional')})`}
                onInputChange={(val) => setPhone(val)}
                isInvalid={!!(phone && !validPhoneNumber && displayValidation)}
              />

              <Box width="150px" marginTop="1.5rem">
                <Button
                  fullWidth
                  label={t('common:submit')}
                  working={working}
                  variant="contained"
                  onClick={handleSubmit}
                  spinnerPadding="0.375rem"
                  data-testid="submit-button"
                  spinnerColor={themeColorTypes.WHITE}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  )
}

LawyerForm.propTypes = {
  lawyer: PropTypes.shape({
    _id: PropTypes.string,
    imageUrl: PropTypes.string,
    emailBody: PropTypes.string,
    lawyerFirstName: PropTypes.string,
    lawyerLastName: PropTypes.string,
  }).isRequired,
}

export default LawyerForm
