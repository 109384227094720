import React, { useEffect } from 'react'

import { Box } from '@mui/material'

import { CONSTANTS } from '@epilogue/common'

import Button from '../../../../../../../common/components/atoms/Button'
import Heading from '../../../../../../../common/components/atoms/Heading'
import Modal from '../../../../../../../common/components/atoms/Modal'
import Text from '../../../../../../../common/components/atoms/Text'
import useUpdateToggles from '../../../../../../../common/hooks/useUpdateToggles'
import amplitude from '../../../../../../../common/lib/amplitude'
import {
  errorHandler,
  PATHS,
  request,
} from '../../../../../../../common/request'
import {
  themeColorTypes,
  themeColorVariants,
} from '../../../../../../../common/styles/muiTheme'

interface Props {
  show: boolean
  token: string
  onClose: () => void
  questionnaireId: string
}

const FollowUpSigningModal = ({
  show,
  token,
  onClose,
  questionnaireId,
}: Props) => {
  const { mutate: handleUpdateToggles } = useUpdateToggles()

  const handleAllDocsSigned = async () => {
    onClose()

    handleUpdateToggles({
      token,
      questionnaireId,
      toggleOn: [CONSTANTS.toggleTypes.HAS_SIGNED_CORE_DOCUMENTS],
    })

    /*
     * Regardless of the success or failure of the toggle update, we want to send the
     * 'all docs signed' request, which is why we don't need to 'await' handleUpdateToggles().
     *
     * If the 'all docs signed' request fails, we let it fail silently and do not
     * show an error to the user, but we do report it to the Epilogue dev team.
     */
    try {
      await request({ method: 'PUT', url: PATHS.DOCUMENT_ALL_DOCS_SIGNED })
    } catch (error) {
      errorHandler({ error, reportError: true })
    }
  }

  useEffect(() => {
    if (show) amplitude.sendEvent('ViewedFollowUpSigningModal')
  }, [show])

  return (
    <Modal
      show={show}
      preventClose
      maxWidth="md"
      onClose={onClose}
      data-testid="follow-up-signing-modal"
    >
      <Box sx={{ py: '2.1rem' }}>
        <Heading align="center" variant="h3">
          Have you signed your documents?
        </Heading>

        <Text align="center" variant="paragraph" sx={{ my: '1.3rem' }}>
          Your estate planning documents are not valid until you have printed
          them and signed them in accordance with the signing instructions
          we&apos;ve provided. Please let us know whether or not you have
          already completed the signing process.
        </Text>

        <Box
          sx={{
            mt: '2rem',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-evenly',
          }}
        >
          <Button
            variant="contained"
            onClick={() => {
              amplitude.sendEvent('ClickedFollowUpSigningModalSigned')
              handleAllDocsSigned()
            }}
            label="I've signed my documents"
            color={themeColorTypes.ACCENT_1}
            colorVariant={themeColorVariants.MAIN}
            data-testid="follow-up-signing-modal-button-signed"
          />

          <Button
            sx={{ ml: { min: '0.5rem', md: 0 } }}
            onClick={() => {
              amplitude.sendEvent('ClickedFollowUpSigningModalSignLater')
              onClose()
            }}
            variant="contained"
            color={themeColorTypes.ACCENT_2}
            label="I haven't signed them yet"
            colorVariant={themeColorVariants.MAIN}
            data-testid="follow-up-signing-modal-button-later"
          />
        </Box>
      </Box>
    </Modal>
  )
}

export default FollowUpSigningModal
