import _isEmpty from 'lodash/isEmpty'

import { CONSTANTS } from '@epilogue/common'

import charitableGiftsLabel from '../dynamicText/charitableGiftsLabel'
import familyLegacyAllToPartnerModal from '../interceptModals/familyLegacyAllToPartnerModal'
import forgotPartnerInterceptModal from '../interceptModals/forgotPartnerInterceptModal'
import incorrectAllToPartnerModal from '../interceptModals/incorrectAllToPartnerModal'
import distributionCharitableGiftDetailsSidebar from '../sidebarContent/distributionCharitableGiftDetailsSidebar'
import distributionInheritanceAgeSidebar from '../sidebarContent/distributionInheritanceAgeSidebar'
import distributionRemoteSidebar from '../sidebarContent/distributionRemoteSidebar'
import distributionSidebarFragments from '../sidebarContent/distributionSidebarFragments'
import distributionSpecificGiftDetailsSidebar from '../sidebarContent/distributionSpecificGiftDetailsSidebar'
import distributionWithChildrenIntroSidebar from '../sidebarContent/distributionWithChildrenIntroSidebar'
import willPowerSidebarFragments from '../sidebarContent/willPowerSidebarFragments'

const commonShareCharitableGiftDetailsLabel2 = ({
  answerStore: { charitableGiftDetails },
}) => {
  const charityCount =
    charitableGiftDetails.length > 1 ? 'multipleCharities' : 'singleCharity'

  return {
    tKey: `questionnaire.distribution:common.share-charitable-gift-details.label2.${charityCount}`,
  }
}

const formatCharityInterceptModalPercentage = (charitableGiftDetails = []) => {
  const percentage = charitableGiftDetails.reduce(
    (sum, gift) => (gift.isPercent ? sum + parseFloat(gift.amount) : sum),
    0,
  )
  // Shorten to 2 decimal places if not an integer, 0 if integer
  return Number.isInteger(percentage)
    ? percentage.toString()
    : percentage.toFixed(2)
}

const section = CONSTANTS.questionnaireSectionTypes.DISTRIBUTION.id

export default {
  id: section,
  description: { tKey: 'questionnaire.distribution:description' },
  trigger: {
    $and: [
      {
        hasPartner: { $exists: true },
        $or: [
          {
            $and: [
              { hasPartner: 'yes' },
              { partnerFirstName: { $exists: true } },
              { partnerLastName: { $exists: true } },
              { partnerGender: { $exists: true } },
            ],
          },
          { hasPartner: 'no' },
        ],
      },

      {
        $or: [
          { hasChildren: 'no' },
          {
            $and: [
              { hasChildren: 'yes' },
              { hasStepChildren: { $exists: true } },
            ],
          },
        ],
      },
    ],
  },
  questions: [
    // -- 🇨🇦💎🧠🇨🇦💎🧠 NO PARTNER / NO CHILDREN / HAS STEPCHILDREN 😈🍀🎃😈🍀🎃 -- //
    {
      id: 1576168035,
      pathname: 'no-partner-stepchild-intro',
      type: 'intro',
      section,
      altProgressText: '',
      highlightPillText: {
        tKey: 'questionnaire.distribution:common.introHighlightPillText',
      },
      label: {
        tKey: 'questionnaire.distribution:common.stepChildIntroLabel',
      },
      label2: {
        tKey: 'questionnaire.distribution:common.stepChildIntroLabel2',
      },
      trigger: {
        $and: [
          { hasPartner: 'no' },
          { hasChildren: 'no' },
          { hasStepChildren: 'yes' },
        ],
      },
    },

    {
      id: 1591808805,
      pathname: 'no-partner-stepchild-charitable-gifts',
      section,
      label: charitableGiftsLabel,
      label2: {
        tKey: 'questionnaire.distribution:no-partner-stepchild-charitable-gifts.label2',
      },
      subLabel: {
        tKey: 'questionnaire.distribution:no-partner-stepchild-charitable-gifts.subLabel',
      },
      fields: [
        {
          name: 'charitableGifts',
          type: 'quickRadio',
          reviewLabel: {
            tKey: 'questionnaire.distribution:common.charitableGiftsReviewLabel',
          },
          options: [
            { value: 'yes', label: { tKey: 'common:yes' } },
            { value: 'no', label: { tKey: 'common:no' } },
          ],
        },
      ],
      trigger: {
        $and: [
          { hasPartner: 'no' },
          { hasChildren: 'no' },
          { hasStepChildren: 'yes' },
          { 'charityPartner.name': { $exists: false } },
        ],
      },
      // TAG: Will Power
      sidebar: willPowerSidebarFragments,
    },

    {
      id: 1591808800,
      pathname: 'no-partner-stepchild-charitable-gift-details',
      type: 'charitableGiftDetails',
      section,
      sidebar: distributionCharitableGiftDetailsSidebar,
      label: {
        tKey: 'questionnaire.distribution:common.charitableGiftDetailsLabel',
      },
      label2: {
        tKey: 'questionnaire.distribution:common.charitableGiftDetailsLabel2',
      },
      subLabel: {
        tKey: 'questionnaire.distribution:common.charitableGiftDetailsSubLabel',
      },
      fields: [
        {
          name: 'charitableGiftDetails',
          type: 'text',
          reviewLabel: {
            tKey: 'questionnaire.distribution:common.charitableGiftDetailsReviewLabel',
          },
        },
      ],
      trigger: {
        $and: [
          { hasPartner: 'no' },
          { hasChildren: 'no' },
          { hasStepChildren: 'yes' },
          { charitableGifts: 'yes' },
          { 'charityPartner.name': { $exists: false } },
        ],
      },
    },

    {
      id: 1720108923,
      pathname: 'no-partner-stepchild-share-charitable-gift-details',
      section,
      label: {
        tKey: 'questionnaire.distribution:common.share-charitable-gift-details.label',
      },
      label2: commonShareCharitableGiftDetailsLabel2,
      fields: [
        {
          name: 'shareCharitableGiftDetails',
          type: 'quickRadio',
          reviewLabel: {
            tKey: 'questionnaire.distribution:common.share-charitable-gift-details.fields.shareCharitableGiftDetails.reviewLabel',
          },
          options: [
            {
              value: 'yes',
              label: { tKey: 'common:yes' },
              pill: { tKey: 'common:mostCommon' },
            },
            { value: 'no', label: { tKey: 'common:no' } },
          ],
        },
      ],
      trigger: {
        hasPartner: 'no',
        hasChildren: 'no',
        hasStepChildren: 'yes',
        charitableGifts: 'yes',
        'charityPartner.name': { $exists: false },
        'charitableGiftDetails.0': { $exists: true },
      },
    },

    {
      id: 1576168421,
      pathname: 'no-partner-stepchild-remote',
      section,
      type: 'remoteDistribution',
      sidebar: distributionRemoteSidebar,
      label: ({
        answerStore: { charitableGifts, charityPartnerGiftDetails },
      }) =>
        charitableGifts === 'yes' || charityPartnerGiftDetails
          ? {
              tKey: 'questionnaire.distribution:no-partner-stepchild-remote.label.case1',
            }
          : {
              tKey: 'questionnaire.distribution:no-partner-stepchild-remote.label.case2',
            },

      label2: {
        tKey: 'questionnaire.distribution:no-partner-stepchild-remote.label2',
      },

      subLabel: ({
        answerStore: { charitableGifts, charityPartnerGiftDetails },
      }) =>
        charitableGifts === 'yes' || charityPartnerGiftDetails
          ? {
              tKey: 'questionnaire.distribution:common.remoteSubLabel.case1',
            }
          : {
              tKey: 'questionnaire.distribution:common.remoteSubLabel.case2',
            },

      fields: [
        {
          name: 'remoteDistribution',
          type: 'text',
          reviewLabel: {
            tKey: 'questionnaire.distribution:no-partner-stepchild-remote.fields.remoteDistribution.reviewLabel',
          },
        },
      ],
      trigger: {
        $and: [
          { hasPartner: 'no' },
          { hasChildren: 'no' },
          { hasStepChildren: 'yes' },
          {
            $or: [
              { charitableGifts: 'no' },
              {
                charitableGifts: 'yes',
                'charitableGiftDetails.0': { $exists: true },
              },
            ],
          },
        ],
      },
    },

    {
      id: 1576168448,
      pathname: 'no-partner-stepchild-predeceased',
      type: 'predeceased',
      section,
      label: { tKey: 'questionnaire.distribution:common.predeceasedLabel' },
      fields: [
        {
          name: 'predeceased',
          type: 'text',
          reviewLabel: {
            tKey: 'questionnaire.distribution:common.predeceasedFieldReviewLabel',
          },
        },
      ],
      trigger: {
        $and: [
          { hasPartner: 'no' },
          { hasChildren: 'no' },
          { hasStepChildren: 'yes' },
          {
            $or: [
              // if there is more than one person named in the remoteDistribution
              {
                $and: [
                  {
                    remoteDistribution: {
                      $elemMatch: { type: 'person' },
                    },
                  },
                  {
                    'remoteDistribution.1': { $exists: true },
                  },
                ],
              },

              // if there is 1 person and 1 or more charities in remoteDistribution
              {
                $and: [
                  {
                    remoteDistribution: {
                      $elemMatch: { type: 'person' },
                    },
                  },
                  {
                    remoteDistribution: {
                      $elemMatch: { type: 'charity' },
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
    },

    {
      id: 1576871002,
      pathname: 'no-partner-stepchild-predeceased-backup',
      section,
      type: 'predeceasedBackup',

      label: ({ answerStore: { remoteDistribution } }) => ({
        tKey: 'questionnaire.distribution:common.predeceasedBackupLabel',
        options: { name: remoteDistribution[0].name },
      }),

      fields: [
        {
          name: 'predeceasedBackup',
          type: 'text',
          reviewLabel: {
            tKey: 'questionnaire.distribution:common.predeceasedFieldReviewLabel',
          },
        },
      ],
      trigger: {
        $and: [
          { hasPartner: 'no' },
          { hasChildren: 'no' },
          { hasStepChildren: 'yes' },

          // if there is only one PERSON in the remote distribution
          {
            remoteDistribution: {
              $elemMatch: { type: 'person' },
            },
          },
          {
            'remoteDistribution.1': { $exists: false },
          },
        ],
      },
    },

    {
      id: 1576168492,
      type: 'inheritanceAge',
      pathname: 'no-partner-stepchild-inheritance-age',
      section,
      label: { tKey: 'questionnaire.distribution:common.inheritanceAgeLabel' },
      subLabel: {
        tKey: 'questionnaire.distribution:common.inheritanceAgeSubLabel',
      },
      fields: [
        {
          name: 'inheritanceAge',
          type: 'text',
          reviewLabel: {
            tKey: 'questionnaire.distribution:common.inheritanceAgeReviewLabel',
          },
          clearAfterProvinceChange: true,
        },
      ],
      trigger: {
        $and: [
          { hasPartner: 'no' },
          { hasChildren: 'no' },
          { hasStepChildren: 'yes' },
          {
            // at least one PERSON in the remote distribution question
            remoteDistribution: {
              $elemMatch: { type: 'person' },
            },
          },
        ],
      },
    },

    {
      id: 1576168607,
      pathname: 'no-partner-stepchild-specific-gifts',
      section,
      label: { tKey: 'questionnaire.distribution:common.specificGiftsLabel' },
      label2: { tKey: 'questionnaire.distribution:common.specificGiftsLabel2' },
      subLabel: {
        tKey: 'questionnaire.distribution:common.specificGiftsSubLabel',
      },
      fields: [
        {
          name: 'specificGifts',
          type: 'quickRadio',
          reviewLabel: {
            tKey: 'questionnaire.distribution:common.specificGiftsReviewLabel',
          },
          options: [
            { value: 'yes', label: { tKey: 'common:yes' } },
            { value: 'no', label: { tKey: 'common:no' } },
          ],
        },
      ],
      trigger: {
        $and: [
          { hasPartner: 'no' },
          { hasChildren: 'no' },
          { hasStepChildren: 'yes' },
        ],
      },
    },

    {
      id: 1606777853,
      pathname: 'no-partner-stepchild-gift-details',
      type: 'gifts',
      section,
      label: { tKey: 'questionnaire.distribution:common.giftDetailsLabel' },
      subLabel: {
        tKey: 'questionnaire.distribution:common.giftDetailsSubLabel',
      },
      sidebar: distributionSpecificGiftDetailsSidebar,
      forbiddenTerms: [
        {
          allow: false,
          terms: [
            'rrsp',
            'rrif',
            'tfsa',
            'tax free sav',
            'tax-free sav',
            'registered retir',
          ],
          errMsg:
            'Registered accounts (like RRSPs, RRIFs, and TFSAs) are not usually dealt with in a Will. Your financial institution can help you name beneficiaries on these accounts or confirm whether you already have.',
        },
        {
          allow: false,
          terms: [
            'reer',
            'ferr',
            'celi',
            "épargne libre d'impôt",
            "epargne libre d'impot",
            "épargne libre-d'impôt",
            "epargne libre-d'impot",
            'retraite enregistrée',
            'retraite enregistree',
          ],
          errMsg:
            'Les comptes enregistrés (comme les REER, FERR et CELI) ne sont généralement pas traités dans un testament. Votre institution financière peut vous aider à désigner des bénéficiaires sur ces comptes ou confirmer si vous en avez déjà.',
        },

        {
          allow: false,
          terms: ['life insur'],
          errMsg:
            "Life insurance is not usually dealt with in a Will. Your insurance agent can confirm if you've already named beneficiaries on your policies.",
        },
        {
          allow: false,
          terms: ['assurance vie'],
          errMsg:
            "L'assurance vie n'est généralement pas traitée dans un testament. Votre agent d'assurance peut confirmer si vous avez déjà désigné des bénéficiaires sur vos polices.",
        },

        {
          allow: false,
          terms: ['estate', 'assets', 'everything i own'],
          errMsg:
            "You should only list smaller personal items such as jewelry or collectibles. If you'd like to leave a portion of your estate/assets to a specific person, please go back and do this in the distribtion question.",
        },
        {
          allow: false,
          terms: [
            'succession',
            'biens',
            'tout ce que je possède',
            'tout ce que je possede',
          ],
          errMsg:
            'Vous ne devez lister que les petits objets personnels comme les bijoux ou les objets de collection. Si vous souhaitez léguer une partie de votre succession/biens à une personne spécifique, veuillez revenir en arrière et le faire dans la question de distribution.',
        },
      ],
      fields: [
        {
          name: 'giftDetails',
          type: 'text',
          reviewLabel: {
            tKey: 'questionnaire.distribution:common.giftDetailsReviewLabel',
          },
        },
      ],
      trigger: {
        $and: [
          { hasPartner: 'no' },
          { hasChildren: 'no' },
          { hasStepChildren: 'yes' },
          { specificGifts: 'yes' },
        ],
      },
    },

    // -- 🇨🇦💎🧠🇨🇦💎🧠 HAS PARTNER / NO CHILDREN / HAS STEPCHILDREN 😈🍀🎃😈🍀🎃 -- //
    {
      id: 1576165542,
      pathname: 'partner-stepchild-intro',
      type: 'intro',
      section,
      altProgressText: '',
      highlightPillText: {
        tKey: 'questionnaire.distribution:common.introHighlightPillText',
      },
      label: {
        tKey: 'questionnaire.distribution:common.stepChildIntroLabel',
      },
      label2: {
        tKey: 'questionnaire.distribution:common.stepChildIntroLabel2',
      },
      trigger: {
        $and: [
          { hasPartner: 'yes' },
          { hasChildren: 'no' },
          { hasStepChildren: 'yes' },
          { partnerFirstName: { $exists: true } },
          { partnerLastName: { $exists: true } },
          { partnerGender: { $exists: true } },
        ],
      },
    },

    {
      id: 1591809926,
      pathname: 'partner-stepchild-charitable-gifts',
      section,
      label: charitableGiftsLabel,
      label2: { tKey: 'questionnaire.distribution:common.specificGiftsLabel2' },
      subLabel: {
        tKey: 'questionnaire.distribution:partner-stepchild-charitable-gifts.subLabel',
      },
      fields: [
        {
          name: 'charitableGifts',
          type: 'quickRadio',
          reviewLabel: {
            tKey: 'questionnaire.distribution:common.charitableGiftsReviewLabel',
          },
          options: [
            { value: 'yes', label: { tKey: 'common:yes' } },
            { value: 'no', label: { tKey: 'common:no' } },
          ],
        },
      ],
      trigger: {
        $and: [
          { hasPartner: 'yes' },
          { hasChildren: 'no' },
          { hasStepChildren: 'yes' },
          { 'charityPartner.name': { $exists: false } },
        ],
      },
      // TAG: Will Power
      sidebar: willPowerSidebarFragments,
    },

    {
      id: 1591809931,
      pathname: 'partner-stepchild-charitable-gift-details',
      type: 'charitableGiftDetails',
      section,
      sidebar: distributionCharitableGiftDetailsSidebar,
      label: {
        tKey: 'questionnaire.distribution:common.charitableGiftDetailsLabel',
      },
      label2: {
        tKey: 'questionnaire.distribution:common.charitableGiftDetailsLabel2',
      },
      subLabel: {
        tKey: 'questionnaire.distribution:common.charitableGiftDetailsSubLabel',
      },
      fields: [
        {
          name: 'charitableGiftDetails',
          type: 'text',
          reviewLabel: {
            tKey: 'questionnaire.distribution:common.charitableGiftDetailsReviewLabel',
          },
        },
      ],
      trigger: {
        $and: [
          { hasPartner: 'yes' },
          { hasChildren: 'no' },
          { hasStepChildren: 'yes' },
          { charitableGifts: 'yes' },
          { 'charityPartner.name': { $exists: false } },
        ],
      },
    },

    {
      id: 1720119095,
      pathname: 'partner-stepchild-share-charitable-gift-details',
      section,
      label: {
        tKey: 'questionnaire.distribution:common.share-charitable-gift-details.label',
      },
      label2: commonShareCharitableGiftDetailsLabel2,
      fields: [
        {
          name: 'shareCharitableGiftDetails',
          type: 'quickRadio',
          reviewLabel: {
            tKey: 'questionnaire.distribution:common.share-charitable-gift-details.fields.shareCharitableGiftDetails.reviewLabel',
          },
          options: [
            {
              value: 'yes',
              label: { tKey: 'common:yes' },
              pill: { tKey: 'common:mostCommon' },
            },
            { value: 'no', label: { tKey: 'common:no' } },
          ],
        },
      ],
      trigger: {
        hasPartner: 'yes',
        hasChildren: 'no',
        hasStepChildren: 'yes',
        charitableGifts: 'yes',
        'charityPartner.name': { $exists: false },
        'charitableGiftDetails.0': { $exists: true },
      },
    },

    {
      id: 1576511017,
      pathname: 'everything-to-partner-stepchild',
      section,

      label: ({
        answerStore: {
          charitableGifts,
          partnerFirstName,
          partnerLastName,
          charityPartnerGiftDetails,
        },
      }) => ({
        tKey: `questionnaire.distribution:everything-to-partner-stepchild.label.${
          charitableGifts === 'yes' || charityPartnerGiftDetails
            ? 'case1'
            : 'case2'
        }`,
        options: { partnerFirstName, partnerLastName },
      }),

      subLabel: ({ answerStore }) => {
        const {
          charitableGifts,
          charitableGiftDetails,
          charityPartnerGiftTiming,
          charityPartnerMirrorFamilyGift,
        } = answerStore
        return charitableGifts === 'yes' &&
          charitableGiftDetails.length > 0 &&
          (charityPartnerGiftTiming === 'familyLegacy' ||
            charityPartnerMirrorFamilyGift === 'yes')
          ? {
              tKey: 'questionnaire.distribution:everything-to-partner-stepchild.subLabel.case1',
            }
          : (charitableGifts === 'yes' && charitableGiftDetails.length > 0) ||
              charityPartnerGiftTiming === 'personal'
            ? {
                tKey: 'questionnaire.distribution:everything-to-partner-stepchild.subLabel.case2',
              }
            : ''
      },

      fields: [
        {
          name: 'allToPartner',
          type: 'quickRadio',

          reviewLabel: ({
            answerStore: { partnerFirstName, partnerLastName },
          }) => ({
            tKey: 'questionnaire.distribution:everything-to-partner-stepchild.fields.allToPartner.reviewLabel',
            options: { partnerFirstName, partnerLastName },
          }),

          options: [
            { value: 'yes', label: { tKey: 'common:yes' } },
            { value: 'no', label: { tKey: 'common:no' } },
          ],
        },
      ],

      interceptModal: [familyLegacyAllToPartnerModal],

      trigger: {
        $and: [
          { hasPartner: 'yes' },
          { hasChildren: 'no' },
          { hasStepChildren: 'yes' },
          { partnerLastName: { $exists: true } },
          { partnerFirstName: { $exists: true } },
          {
            $or: [
              { charitableGifts: 'no' },
              {
                charitableGifts: 'yes',
                'charitableGiftDetails.0': { $exists: true },
              },
            ],
          },
          {
            $or: [
              // checks if Charities section has NOT been triggered
              { 'charityPartner.name': { $exists: false } },
              // checks if user saw the Charities section but is not giving to a charity-partner
              { charityPartnerGiftInclude: 'no' },
              { charityPartnerMirrorFamilyGift: 'yes' },
              {
                $and: [
                  { charityPartnerGiftInclude: 'yes' },
                  { charityPartnerGiftTiming: { $exists: true } },
                  { charityPartnerGiftType: { $exists: true } },
                ],
              },
            ],
          },
        ],
      },
    },

    {
      id: 1576165873,
      pathname: 'partner-stepchild-remote',
      section,
      type: 'remoteDistribution',
      sidebar: distributionRemoteSidebar,

      label: ({ answerStore }) => {
        const {
          allToPartner,
          charitableGifts,
          partnerFirstName,
          charityPartnerGiftDetails,
        } = answerStore

        const modifier =
          charitableGifts === 'yes' || charityPartnerGiftDetails
            ? ' remaining'
            : ''

        return allToPartner === 'yes'
          ? {
              tKey: 'questionnaire.distribution:partner-stepchild-remote.label.case1',
              options: { partnerFirstName, modifier },
            }
          : {
              tKey: 'questionnaire.distribution:partner-stepchild-remote.label.case2',
              options: { modifier },
            }
      },

      label2: ({ answerStore }) => {
        const { allToPartner, partnerFirstName } = answerStore

        return allToPartner === 'yes'
          ? {
              tKey: 'questionnaire.distribution:partner-stepchild-remote.label2.case1',
            }
          : {
              tKey: 'questionnaire.distribution:partner-stepchild-remote.label2.case2',
              options: { partnerFirstName },
            }
      },

      subLabel: ({
        answerStore: { charitableGifts, charityPartnerGiftDetails },
      }) =>
        charitableGifts === 'yes' || charityPartnerGiftDetails
          ? {
              tKey: 'questionnaire.distribution:common.remoteSubLabel.case1',
            }
          : {
              tKey: 'questionnaire.distribution:common.remoteSubLabel.case2',
            },
      interceptModal: [forgotPartnerInterceptModal, incorrectAllToPartnerModal],
      fields: [
        {
          name: 'remoteDistribution',
          type: 'text',
          reviewLabel: ({ answerStore: { allToPartner, partnerFirstName } }) =>
            allToPartner === 'yes'
              ? {
                  tKey: 'questionnaire.distribution:partner-stepchild-remote.fields.remoteDistribution.reviewLabel.case1',
                  options: { partnerFirstName },
                }
              : {
                  tKey: 'questionnaire.distribution:partner-stepchild-remote.fields.remoteDistribution.reviewLabel.case2',
                },
        },
      ],
      trigger: {
        $and: [
          { hasPartner: 'yes' },
          { hasChildren: 'no' },
          { hasStepChildren: 'yes' },
          { allToPartner: { $exists: true } },
          { partnerFirstName: { $exists: true } },
          {
            $or: [
              { charitableGifts: 'no' },
              {
                charitableGifts: 'yes',
                'charitableGiftDetails.0': { $exists: true },
              },
            ],
          },
        ],
      },
    },

    {
      id: 1576166231,
      pathname: 'partner-stepchild-predeceased',
      type: 'predeceased',
      section,
      label: { tKey: 'questionnaire.distribution:common.predeceasedLabel' },
      fields: [
        {
          name: 'predeceased',
          type: 'text',
          reviewLabel: {
            tKey: 'questionnaire.distribution:common.predeceasedFieldReviewLabel',
          },
        },
      ],
      trigger: {
        $and: [
          { hasPartner: 'yes' },
          { hasChildren: 'no' },
          { hasStepChildren: 'yes' },
          {
            $or: [
              // if there is more than one person named in the remoteDistribution
              {
                $and: [
                  {
                    remoteDistribution: {
                      $elemMatch: { type: 'person' },
                    },
                  },
                  {
                    'remoteDistribution.1': { $exists: true },
                  },
                ],
              },

              // if there is 1 person and 1 or more charities in remoteDistribution
              {
                $and: [
                  {
                    remoteDistribution: {
                      $elemMatch: { type: 'person' },
                    },
                  },
                  {
                    remoteDistribution: {
                      $elemMatch: { type: 'charity' },
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
    },

    {
      id: 1576870959,
      pathname: 'partner-stepchild-predeceased-backup',
      section,
      type: 'predeceasedBackup',

      label: ({ answerStore: { remoteDistribution } }) => ({
        tKey: 'questionnaire.distribution:common.predeceasedBackupLabel',
        options: { name: remoteDistribution[0].name },
      }),

      fields: [
        {
          name: 'predeceasedBackup',
          type: 'text',
          reviewLabel: {
            tKey: 'questionnaire.distribution:common.predeceasedFieldReviewLabel',
          },
        },
      ],
      trigger: {
        $and: [
          { hasPartner: 'yes' },
          { hasChildren: 'no' },
          { hasStepChildren: 'yes' },

          // if there is only one PERSON in the remote distribution
          {
            remoteDistribution: {
              $elemMatch: { type: 'person' },
            },
          },
          {
            'remoteDistribution.1': { $exists: false },
          },
        ],
      },
    },

    {
      id: 1576166472,
      type: 'inheritanceAge',
      pathname: 'partner-stepchild-inheritance-age',
      section,
      label: { tKey: 'questionnaire.distribution:common.inheritanceAgeLabel' },
      subLabel: {
        tKey: 'questionnaire.distribution:common.inheritanceAgeSubLabel',
      },
      fields: [
        {
          name: 'inheritanceAge',
          type: 'text',
          reviewLabel: {
            tKey: 'questionnaire.distribution:common.inheritanceAgeReviewLabel',
          },
          clearAfterProvinceChange: true,
        },
      ],
      trigger: {
        $and: [
          { hasPartner: 'yes' },
          { hasChildren: 'no' },
          { hasStepChildren: 'yes' },
          {
            // at least one PERSON in the remote distribution question
            remoteDistribution: {
              $elemMatch: { type: 'person' },
            },
          },
        ],
      },
    },

    {
      id: 1576166576,
      pathname: 'partner-stepchild-specific-gifts',
      section,

      label: ({ answerStore: { partnerFirstName } }) => ({
        tKey: 'questionnaire.distribution:common.partnerSpecificGiftsLabel',
        options: { partnerFirstName },
      }),
      label2: { tKey: 'questionnaire.distribution:common.specificGiftsLabel2' },
      subLabel: {
        tKey: 'questionnaire.distribution:common.specificGiftsSubLabel',
      },
      fields: [
        {
          name: 'specificGifts',
          type: 'quickRadio',
          reviewLabel: {
            tKey: 'questionnaire.distribution:common.specificGiftsReviewLabel',
          },
          options: [
            { value: 'yes', label: { tKey: 'common:yes' } },
            { value: 'no', label: { tKey: 'common:no' } },
          ],
        },
      ],
      trigger: {
        $and: [
          { hasPartner: 'yes' },
          { hasChildren: 'no' },
          { hasStepChildren: 'yes' },
          { partnerFirstName: { $exists: true } },
        ],
      },
    },

    {
      id: 1606777237,
      pathname: 'partner-stepchild-gift-details',
      type: 'gifts',
      section,
      label: { tKey: 'questionnaire.distribution:common.giftDetailsLabel' },
      subLabel: {
        tKey: 'questionnaire.distribution:common.giftDetailsSubLabel',
      },
      sidebar: distributionSpecificGiftDetailsSidebar,
      forbiddenTerms: [
        {
          allow: false,
          terms: [
            'rrsp',
            'rrif',
            'tfsa',
            'tax free sav',
            'tax-free sav',
            'registered retir',
          ],
          errMsg:
            'Registered accounts (like RRSPs, RRIFs, and TFSAs) are not usually dealt with in a Will. Your financial institution can help you name beneficiaries on these accounts or confirm whether you already have.',
        },
        {
          allow: false,
          terms: [
            'reer',
            'ferr',
            'celi',
            "épargne libre d'impôt",
            "epargne libre d'impot",
            "épargne libre-d'impôt",
            "epargne libre-d'impot",
            'retraite enregistrée',
            'retraite enregistree',
          ],
          errMsg:
            'Les comptes enregistrés (comme les REER, FERR et CELI) ne sont généralement pas traités dans un testament. Votre institution financière peut vous aider à désigner des bénéficiaires sur ces comptes ou confirmer si vous en avez déjà.',
        },

        {
          allow: false,
          terms: ['life insur'],
          errMsg:
            "Life insurance is not usually dealt with in a Will. Your insurance agent can confirm if you've already named beneficiaries on your policies.",
        },
        {
          allow: false,
          terms: ['assurance vie'],
          errMsg:
            "L'assurance vie n'est généralement pas traitée dans un testament. Votre agent d'assurance peut confirmer si vous avez déjà désigné des bénéficiaires sur vos polices.",
        },

        {
          allow: false,
          terms: ['estate', 'assets', 'everything i own'],
          errMsg:
            "You should only list smaller personal items such as jewelry or collectibles. If you'd like to leave a portion of your estate/assets to a specific person, please go back and do this in the distribtion question.",
        },
        {
          allow: false,
          terms: [
            'succession',
            'biens',
            'tout ce que je possède',
            'tout ce que je possede',
          ],
          errMsg:
            'Vous ne devez lister que les petits objets personnels comme les bijoux ou les objets de collection. Si vous souhaitez léguer une partie de votre succession/biens à une personne spécifique, veuillez revenir en arrière et le faire dans la question de distribution.',
        },
      ],
      fields: [
        {
          name: 'giftDetails',
          type: 'text',
          reviewLabel: {
            tKey: 'questionnaire.distribution:common.giftDetailsReviewLabel',
          },
        },
      ],
      trigger: {
        $and: [
          { hasPartner: 'yes' },
          { hasChildren: 'no' },
          { hasStepChildren: 'yes' },
          { specificGifts: 'yes' },
        ],
      },
    },

    // -- 🇨🇦💎🧠🇨🇦💎🧠- HAS PARTNER / NO CHILDREN -😈🍀🎃😈🍀🎃 -- //
    {
      id: 1572899263,
      pathname: 'partner-intro',
      type: 'intro',
      section,
      altProgressText: '',
      highlightPillText: {
        tKey: 'questionnaire.distribution:common.introHighlightPillText',
      },
      label: { tKey: 'questionnaire.distribution:common.introLabel' },
      label2: { tKey: 'questionnaire.distribution:partner-intro.label2' },
      subLabel: { tKey: 'questionnaire.distribution:partner-intro.subLabel' },
      trigger: {
        $and: [
          { hasPartner: 'yes' },
          { hasChildren: 'no' },
          { partnerFirstName: { $exists: true } },
          { partnerLastName: { $exists: true } },
          { partnerGender: { $exists: true } },
          {
            $or: [
              { hasStepChildren: { $exists: false } },
              { hasStepChildren: 'no' },
            ],
          },
        ],
      },
    },

    {
      id: 1591801860,
      pathname: 'partner-charitable-gifts',
      section,
      label: charitableGiftsLabel,
      label2: { tKey: 'questionnaire.distribution:common.specificGiftsLabel2' },
      subLabel: {
        tKey: 'questionnaire.distribution:partner-charitable-gifts.subLabel',
      },
      fields: [
        {
          name: 'charitableGifts',
          type: 'quickRadio',
          reviewLabel: {
            tKey: 'questionnaire.distribution:common.charitableGiftsReviewLabel',
          },
          options: [
            { value: 'yes', label: { tKey: 'common:yes' } },
            { value: 'no', label: { tKey: 'common:no' } },
          ],
        },
      ],
      trigger: {
        $and: [
          { hasPartner: 'yes' },
          { hasChildren: 'no' },
          { 'charityPartner.name': { $exists: false } },
          {
            $or: [
              { hasStepChildren: { $exists: false } },
              { hasStepChildren: 'no' },
            ],
          },
        ],
      },
      // TAG: Will Power
      sidebar: willPowerSidebarFragments,
    },

    {
      id: 1591801868,
      pathname: 'partner-charitable-gift-details',
      type: 'charitableGiftDetails',
      section,
      sidebar: distributionCharitableGiftDetailsSidebar,
      label: {
        tKey: 'questionnaire.distribution:common.charitableGiftDetailsLabel',
      },
      label2: {
        tKey: 'questionnaire.distribution:common.charitableGiftDetailsLabel2',
      },
      subLabel: {
        tKey: 'questionnaire.distribution:common.charitableGiftDetailsSubLabel',
      },
      fields: [
        {
          name: 'charitableGiftDetails',
          type: 'text',
          reviewLabel: {
            tKey: 'questionnaire.distribution:common.charitableGiftDetailsReviewLabel',
          },
        },
      ],
      trigger: {
        $and: [
          { hasPartner: 'yes' },
          { hasChildren: 'no' },
          { charitableGifts: 'yes' },
          { 'charityPartner.name': { $exists: false } },
          {
            $or: [
              { hasStepChildren: { $exists: false } },
              { hasStepChildren: 'no' },
            ],
          },
        ],
      },
    },

    {
      id: 1720119388,
      pathname: 'partner-share-charitable-gift-details',
      section,
      label: {
        tKey: 'questionnaire.distribution:common.share-charitable-gift-details.label',
      },
      label2: commonShareCharitableGiftDetailsLabel2,
      fields: [
        {
          name: 'shareCharitableGiftDetails',
          type: 'quickRadio',
          reviewLabel: {
            tKey: 'questionnaire.distribution:common.share-charitable-gift-details.fields.shareCharitableGiftDetails.reviewLabel',
          },
          options: [
            {
              value: 'yes',
              label: { tKey: 'common:yes' },
              pill: { tKey: 'common:mostCommon' },
            },
            { value: 'no', label: { tKey: 'common:no' } },
          ],
        },
      ],
      trigger: {
        hasPartner: 'yes',
        hasChildren: 'no',
        charitableGifts: 'yes',
        'charityPartner.name': { $exists: false },
        'charitableGiftDetails.0': { $exists: true },
        $or: [
          { hasStepChildren: { $exists: false } },
          { hasStepChildren: 'no' },
        ],
      },
    },

    {
      id: 1576510336,
      pathname: 'everything-to-partner',
      section,

      label: ({
        answerStore: {
          charitableGifts,
          partnerFirstName,
          partnerLastName,
          charityPartnerGiftDetails,
        },
      }) => ({
        tKey: `questionnaire.distribution:everything-to-partner.label.${
          charitableGifts === 'yes' || charityPartnerGiftDetails
            ? 'case1'
            : 'case2'
        }`,
        options: { partnerFirstName, partnerLastName },
      }),

      subLabel: ({ answerStore }) => {
        const {
          charitableGifts,
          charitableGiftDetails,
          charityPartnerGiftTiming,
          charityPartnerMirrorFamilyGift,
        } = answerStore
        return charitableGifts === 'yes' &&
          charitableGiftDetails.length > 0 &&
          (charityPartnerGiftTiming === 'familyLegacy' ||
            charityPartnerMirrorFamilyGift === 'yes')
          ? {
              tKey: 'questionnaire.distribution:everything-to-partner.subLabel.case1',
            }
          : (charitableGifts === 'yes' && charitableGiftDetails.length > 0) ||
              charityPartnerGiftTiming === 'personal'
            ? {
                tKey: 'questionnaire.distribution:everything-to-partner.subLabel.case2',
              }
            : ''
      },

      fields: [
        {
          name: 'allToPartner',
          type: 'quickRadio',

          reviewLabel: ({
            answerStore: { partnerFirstName, partnerLastName },
          }) => ({
            tKey: 'questionnaire.distribution:everything-to-partner.fields.allToPartner.reviewLabel',
            options: { partnerFirstName, partnerLastName },
          }),

          options: [
            { value: 'yes', label: { tKey: 'common:yes' } },
            { value: 'no', label: { tKey: 'common:no' } },
          ],
        },
      ],

      interceptModal: [familyLegacyAllToPartnerModal],

      trigger: {
        $and: [
          { hasPartner: 'yes' },
          { hasChildren: 'no' },
          { partnerFirstName: { $exists: true } },
          { partnerLastName: { $exists: true } },
          {
            $or: [
              { charitableGifts: 'no' },
              {
                charitableGifts: 'yes',
                'charitableGiftDetails.0': { $exists: true },
              },
            ],
          },
          {
            $or: [
              { hasStepChildren: { $exists: false } },
              { hasStepChildren: 'no' },
            ],
          },
          {
            $or: [
              // checks if Charities section has NOT been triggered
              { 'charityPartner.name': { $exists: false } },
              // checks if user saw the Charities section but is not giving to a charity-partner
              { charityPartnerGiftInclude: 'no' },
              { charityPartnerMirrorFamilyGift: 'yes' },
              {
                $and: [
                  { charityPartnerGiftInclude: 'yes' },
                  { charityPartnerGiftTiming: { $exists: true } },
                  { charityPartnerGiftType: { $exists: true } },
                ],
              },
            ],
          },
        ],
      },
    },

    {
      id: 1572900375,
      pathname: 'partner-remote',
      section,
      type: 'remoteDistribution',
      sidebar: distributionRemoteSidebar,
      label: ({ answerStore }) => {
        const { allToPartner, charitableGifts, charityPartnerGiftDetails } =
          answerStore

        return allToPartner === 'yes'
          ? { tKey: 'questionnaire.distribution:partner-remote.label.case1' }
          : charitableGifts === 'yes' || charityPartnerGiftDetails
            ? { tKey: 'questionnaire.distribution:partner-remote.label.case2' }
            : { tKey: 'questionnaire.distribution:partner-remote.label.case3' }
      },

      label2: ({ answerStore }) => {
        const {
          allToPartner,
          charitableGifts,
          partnerFirstName,
          charityPartnerGiftDetails,
        } = answerStore

        return allToPartner === 'yes'
          ? charitableGifts === 'yes' || charityPartnerGiftDetails
            ? {
                tKey: 'questionnaire.distribution:partner-remote.label2.case1',
                options: { partnerFirstName },
              }
            : {
                tKey: 'questionnaire.distribution:partner-remote.label2.case2',
                options: { partnerFirstName },
              }
          : {
              tKey: 'questionnaire.distribution:partner-remote.label2.case3',
              options: { partnerFirstName },
            }
      },

      subLabel: ({
        answerStore: { charitableGifts, charityPartnerGiftDetails },
      }) =>
        charitableGifts === 'yes' || charityPartnerGiftDetails
          ? {
              tKey: 'questionnaire.distribution:common.remoteSubLabel.case1',
            }
          : {
              tKey: 'questionnaire.distribution:common.remoteSubLabel.case2',
            },

      interceptModal: [forgotPartnerInterceptModal, incorrectAllToPartnerModal],

      fields: [
        {
          name: 'remoteDistribution',
          type: 'text',
          reviewLabel: ({ answerStore: { allToPartner, partnerFirstName } }) =>
            allToPartner === 'yes'
              ? {
                  tKey: 'questionnaire.distribution:partner-remote.fields.remoteDistribution.reviewLabel.case1',
                  options: { partnerFirstName },
                }
              : {
                  tKey: 'questionnaire.distribution:partner-remote.fields.remoteDistribution.reviewLabel.case2',
                },
        },
      ],
      trigger: {
        $and: [
          { hasPartner: 'yes' },
          { hasChildren: 'no' },
          { allToPartner: { $exists: true } },
          { partnerFirstName: { $exists: true } },
          {
            $or: [
              { charitableGifts: 'no' },
              {
                charitableGifts: 'yes',
                'charitableGiftDetails.0': { $exists: true },
              },
            ],
          },
          {
            $or: [
              { hasStepChildren: { $exists: false } },
              { hasStepChildren: 'no' },
            ],
          },
        ],
      },
    },

    {
      id: 1572901170,
      pathname: 'partner-predeceased',
      type: 'predeceased',
      section,
      label: { tKey: 'questionnaire.distribution:common.predeceasedLabel' },
      fields: [
        {
          name: 'predeceased',
          type: 'text',
          reviewLabel: {
            tKey: 'questionnaire.distribution:common.predeceasedFieldReviewLabel',
          },
        },
      ],
      trigger: {
        $and: [
          { hasPartner: 'yes' },
          { hasChildren: 'no' },
          {
            $or: [
              { hasStepChildren: { $exists: false } },
              { hasStepChildren: 'no' },
            ],
          },
          {
            $or: [
              // if there is more than one person named in the remoteDistribution
              {
                $and: [
                  {
                    remoteDistribution: {
                      $elemMatch: { type: 'person' },
                    },
                  },
                  {
                    'remoteDistribution.1': { $exists: true },
                  },
                ],
              },

              // if there is 1 person and 1 or more charities in remoteDistribution
              {
                $and: [
                  {
                    remoteDistribution: {
                      $elemMatch: { type: 'person' },
                    },
                  },
                  {
                    remoteDistribution: {
                      $elemMatch: { type: 'charity' },
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
    },

    {
      id: 1576870330,
      pathname: 'partner-predeceased-backup',
      section,
      type: 'predeceasedBackup',

      label: ({ answerStore: { remoteDistribution, allToPartner } }) => ({
        tKey: `questionnaire.distribution:partner-predeceased-backup.label.case${allToPartner === 'yes' ? '1' : '2'}`,
        options: { name: remoteDistribution[0].name },
      }),

      fields: [
        {
          name: 'predeceasedBackup',
          type: 'text',
          reviewLabel: {
            tKey: 'questionnaire.distribution:common.predeceasedFieldReviewLabel',
          },
        },
      ],
      trigger: {
        $and: [
          { allToPartner: { $exists: true } },
          { hasPartner: 'yes' },
          { hasChildren: 'no' },
          {
            $or: [
              { hasStepChildren: { $exists: false } },
              { hasStepChildren: 'no' },
            ],
          },

          // if there is only one PERSON in the remote distribution
          {
            remoteDistribution: {
              $elemMatch: { type: 'person' },
            },
          },
          {
            'remoteDistribution.1': { $exists: false },
          },
        ],
      },
    },

    {
      id: 1572901377,
      type: 'inheritanceAge',
      pathname: 'partner-inheritance-age',
      section,
      label: { tKey: 'questionnaire.distribution:common.inheritanceAgeLabel' },
      subLabel: {
        tKey: 'questionnaire.distribution:common.inheritanceAgeSubLabel',
      },
      fields: [
        {
          name: 'inheritanceAge',
          type: 'text',
          reviewLabel: {
            tKey: 'questionnaire.distribution:common.inheritanceAgeReviewLabel',
          },
          clearAfterProvinceChange: true,
        },
      ],
      trigger: {
        $and: [
          { hasPartner: 'yes' },
          { hasChildren: 'no' },
          {
            $or: [
              { hasStepChildren: { $exists: false } },
              { hasStepChildren: 'no' },
            ],
          },
          {
            // at least one PERSON in the remote distribution question
            remoteDistribution: {
              $elemMatch: { type: 'person' },
            },
          },
        ],
      },
    },

    {
      id: 1572901835,
      pathname: 'partner-specific-gifts',
      section,

      label: ({ answerStore: { partnerFirstName } }) => ({
        tKey: 'questionnaire.distribution:common.partnerSpecificGiftsLabel',
        options: { partnerFirstName },
      }),

      label2: { tKey: 'questionnaire.distribution:common.specificGiftsLabel2' },
      subLabel: {
        tKey: 'questionnaire.distribution:partner-specific-gifts.subLabel',
      },
      fields: [
        {
          name: 'specificGifts',
          type: 'quickRadio',
          reviewLabel: {
            tKey: 'questionnaire.distribution:common.specificGiftsReviewLabel',
          },
          options: [
            { value: 'yes', label: { tKey: 'common:yes' } },
            { value: 'no', label: { tKey: 'common:no' } },
          ],
        },
      ],
      trigger: {
        $and: [
          { hasPartner: 'yes' },
          { hasChildren: 'no' },
          {
            $or: [
              { hasStepChildren: { $exists: false } },
              { hasStepChildren: 'no' },
            ],
          },
          { partnerFirstName: { $exists: true } },
        ],
      },
    },

    {
      id: 1606777668,
      pathname: 'partner-gift-details',
      type: 'gifts',
      section,
      label: { tKey: 'questionnaire.distribution:common.giftDetailsLabel' },
      subLabel: {
        tKey: 'questionnaire.distribution:common.giftDetailsSubLabel',
      },
      sidebar: distributionSpecificGiftDetailsSidebar,
      forbiddenTerms: [
        {
          allow: false,
          terms: [
            'rrsp',
            'rrif',
            'tfsa',
            'tax free sav',
            'tax-free sav',
            'registered retir',
          ],
          errMsg:
            'Registered accounts (like RRSPs, RRIFs, and TFSAs) are not usually dealt with in a Will. Your financial institution can help you name beneficiaries on these accounts or confirm whether you already have.',
        },
        {
          allow: false,
          terms: [
            'reer',
            'ferr',
            'celi',
            "épargne libre d'impôt",
            "epargne libre d'impot",
            "épargne libre-d'impôt",
            "epargne libre-d'impot",
            'retraite enregistrée',
            'retraite enregistree',
          ],
          errMsg:
            'Les comptes enregistrés (comme les REER, FERR et CELI) ne sont généralement pas traités dans un testament. Votre institution financière peut vous aider à désigner des bénéficiaires sur ces comptes ou confirmer si vous en avez déjà.',
        },

        {
          allow: false,
          terms: ['life insur'],
          errMsg:
            "Life insurance is not usually dealt with in a Will. Your insurance agent can confirm if you've already named beneficiaries on your policies.",
        },
        {
          allow: false,
          terms: ['assurance vie'],
          errMsg:
            "L'assurance vie n'est généralement pas traitée dans un testament. Votre agent d'assurance peut confirmer si vous avez déjà désigné des bénéficiaires sur vos polices.",
        },

        {
          allow: false,
          terms: ['estate', 'assets', 'everything i own'],
          errMsg:
            "You should only list smaller personal items such as jewelry or collectibles. If you'd like to leave a portion of your estate/assets to a specific person, please go back and do this in the distribtion question.",
        },
        {
          allow: false,
          terms: [
            'succession',
            'biens',
            'tout ce que je possède',
            'tout ce que je possede',
          ],
          errMsg:
            'Vous ne devez lister que les petits objets personnels comme les bijoux ou les objets de collection. Si vous souhaitez léguer une partie de votre succession/biens à une personne spécifique, veuillez revenir en arrière et le faire dans la question de distribution.',
        },
      ],
      fields: [
        {
          name: 'giftDetails',
          type: 'text',
          reviewLabel: {
            tKey: 'questionnaire.distribution:common.giftDetailsReviewLabel',
          },
        },
      ],
      trigger: {
        $and: [
          { specificGifts: 'yes' },
          { hasPartner: 'yes' },
          { hasChildren: 'no' },
          {
            $or: [
              { hasStepChildren: { $exists: false } },
              { hasStepChildren: 'no' },
            ],
          },
        ],
      },
    },

    // -- 🇨🇦💎🧠🇨🇦💎🧠- HAS PARTNER / HAS CHILDREN -😈🍀🎃😈🍀🎃 -- //
    {
      id: 1572899264,
      pathname: 'partner-children-intro',
      type: 'intro',
      section,
      altProgressText: '',
      highlightPillText: {
        tKey: 'questionnaire.distribution:common.introHighlightPillText',
      },

      label: { tKey: 'questionnaire.distribution:common.introLabel' },

      label2: ({ answerStore: { partnerFirstName, partnerGender } }) => {
        const thePartnerGender =
          partnerGender === 'male'
            ? 'male'
            : partnerGender === 'female'
              ? 'female'
              : 'other'

        return {
          tKey: `questionnaire.distribution:partner-children-intro.label2.${thePartnerGender}`,
          options: { partnerFirstName },
        }
      },

      sidebar: distributionWithChildrenIntroSidebar,

      subLabel: ({ charityPartner }) => ({
        tKey: `questionnaire.distribution:partner-children-intro.subLabel.case${!_isEmpty(charityPartner) ? '1' : '2'}`,
      }),

      fields: [
        {
          name: 'allAssetsToPartner',
          type: 'text',
          reviewLabel: {
            tKey: 'questionnaire.distribution:common.distributionIntroReviewLabel',
          },

          // an immutableValue can never be changed by the user
          immutableValue: ({ answerStore: { partnerFirstName } }) => ({
            tKey: 'questionnaire.distribution:partner-children-intro.fields.allAssetsToPartner.immutableValue',
            options: { partnerFirstName },
          }),
        },
      ],
      trigger: {
        $and: [
          { hasPartner: 'yes' },
          { hasChildren: 'yes' },
          { partnerGender: { $exists: true } },
          { partnerFirstName: { $exists: true } },
        ],
      },
    },

    {
      id: 1591798768,
      pathname: 'partner-children-charitable-gifts',
      section,
      label: charitableGiftsLabel,
      label2: { tKey: 'questionnaire.distribution:common.specificGiftsLabel2' },
      fields: [
        {
          name: 'charitableGifts',
          type: 'quickRadio',
          reviewLabel: {
            tKey: 'questionnaire.distribution:common.charitableGiftsReviewLabel',
          },
          options: [
            { value: 'yes', label: { tKey: 'common:yes' } },
            { value: 'no', label: { tKey: 'common:no' } },
          ],
        },
      ],
      trigger: {
        $and: [
          { hasPartner: 'yes' },
          { hasChildren: 'yes' },
          { 'charityPartner.name': { $exists: false } },
        ],
      },
      // TAG: Will Power
      sidebar: willPowerSidebarFragments,
    },

    {
      id: 1591798779,
      pathname: 'partner-children-charitable-gift-details',
      type: 'charitableGiftDetails',
      section,
      label: {
        tKey: 'questionnaire.distribution:partner-children-charitable-gift-details.label',
      },
      label2: {
        tKey: 'questionnaire.distribution:partner-children-charitable-gift-details.label2',
      },
      subLabel: {
        tKey: 'questionnaire.distribution:common.charitableGiftDetailsSubLabel',
      },
      interceptModal: [
        {
          label: ({ questionFragments, answerStore }) => {
            const { charitableGiftDetails } = questionFragments
            const { partnerFirstName } = answerStore
            return {
              tKey: 'questionnaire.distribution:common.childrenCharitableGiftDetailsInterceptModal.label.hasPartnerYes',
              options: {
                partnerFirstName,
                percentage: formatCharityInterceptModalPercentage(
                  charitableGiftDetails,
                ),
              },
            }
          },

          subLabel: {
            tKey: 'questionnaire.distribution:common.childrenCharitableGiftDetailsInterceptModal.subLabel',
          },
          primaryButtonLabel: {
            tKey: 'questionnaire.distribution:common.childrenCharitableGiftDetailsInterceptModal.primaryButtonLabel',
          },
          primaryButtonIcon: true,
          primaryButtonAction: {
            type: CONSTANTS.interceptActionTypes.NEXT_QUESTION,
          },
          secondaryButtonLabel: {
            tKey: 'questionnaire.distribution:common.childrenCharitableGiftDetailsInterceptModal.secondaryButtonLabel',
          },
          secondaryButtonAction: {
            type: CONSTANTS.interceptActionTypes.CLOSE_MODAL,
          },
          trigger: {
            $and: [
              { partnerFirstName: { $exists: true } },
              { charitableGiftDetails: { $exists: true, $ne: [] } },
              {
                // Checks if percentage gifts equals 20 or more
                $where() {
                  return (
                    this.charitableGiftDetails
                      .filter((gift) => gift.isPercent)
                      .reduce(
                        (sum, gift) => sum + parseFloat(gift.amount),
                        0,
                      ) >= 20
                  )
                },
              },
            ],
          },
        },
      ],
      sidebar: distributionCharitableGiftDetailsSidebar,
      fields: [
        {
          name: 'charitableGiftDetails',
          type: 'text',
          reviewLabel: {
            tKey: 'questionnaire.distribution:common.charitableGiftDetailsReviewLabel',
          },
        },
      ],
      trigger: {
        $and: [
          { hasPartner: 'yes' },
          { hasChildren: 'yes' },
          { charitableGifts: 'yes' },
          { partnerGender: { $exists: true } },
          { partnerFirstName: { $exists: true } },
          { 'charityPartner.name': { $exists: false } },
        ],
      },
    },

    {
      id: 1720119621,
      pathname: 'partner-children-share-charitable-gift-details',
      section,
      label: {
        tKey: 'questionnaire.distribution:common.share-charitable-gift-details.label',
      },
      label2: commonShareCharitableGiftDetailsLabel2,
      fields: [
        {
          name: 'shareCharitableGiftDetails',
          type: 'quickRadio',
          reviewLabel: {
            tKey: 'questionnaire.distribution:common.share-charitable-gift-details.fields.shareCharitableGiftDetails.reviewLabel',
          },
          options: [
            {
              value: 'yes',
              label: { tKey: 'common:yes' },
              pill: { tKey: 'common:mostCommon' },
            },
            { value: 'no', label: { tKey: 'common:no' } },
          ],
        },
      ],
      trigger: {
        hasPartner: 'yes',
        hasChildren: 'yes',
        charitableGifts: 'yes',
        'charityPartner.name': { $exists: false },
        'charitableGiftDetails.0': { $exists: true },
      },
    },

    {
      id: 1572903826,
      type: 'inheritanceAge',
      pathname: 'partner-children-inheritance-age',
      section,
      sidebar: distributionInheritanceAgeSidebar,

      label: ({ answerStore: { partnerFirstName } }) => ({
        tKey: 'questionnaire.distribution:partner-children-inheritance-age.label',
        options: { partnerFirstName },
      }),

      subLabel: {
        tKey: 'questionnaire.distribution:partner-children-inheritance-age.subLabel',
      },
      fields: [
        {
          name: 'inheritanceAge',
          type: 'text',
          reviewLabel: {
            tKey: 'questionnaire.distribution:partner-children-inheritance-age.fields.inheritanceAge.reviewLabel',
          },
          clearAfterProvinceChange: true,
        },
      ],
      trigger: {
        $and: [
          { hasPartner: 'yes' },
          { hasChildren: 'yes' },
          { partnerFirstName: { $exists: true } },
        ],
      },
    },

    {
      id: 1573914613,
      pathname: 'partner-step-children-distribution',
      type: 'stepChildrenDistribution',
      section,
      label: {
        tKey: 'questionnaire.distribution:partner-step-children-distribution.label',
      },
      label2: {
        tKey: 'questionnaire.distribution:partner-step-children-distribution.label2',
      },
      fields: [
        {
          name: 'stepChildrenDistribution',
          type: 'text',
          reviewLabel: {
            tKey: 'questionnaire.distribution:partner-step-children-distribution.fields.stepChildrenDistribution.reviewLabel',
          },
        },
      ],
      trigger: {
        $and: [
          { hasPartner: 'yes' },
          { hasChildren: 'yes' },
          { hasStepChildren: 'yes' },
        ],
      },
      sidebar: [
        {
          type: 'accordion',
          header: {
            tKey: 'questionnaire.distribution:partner-step-children-distribution.sidebar.item1.header',
          },
          children: [
            distributionSidebarFragments.stepChildDefinition,
            {
              type: 'text',
              body: {
                tKey: 'questionnaire.distribution:partner-step-children-distribution.sidebar.item1.children.item2.body',
              },
            },
          ],
        },
        distributionSidebarFragments.stepChildrenRequired,
      ],
    },

    {
      id: 1573068942,
      pathname: 'partner-children-remote',
      section,
      type: 'remoteDistribution',
      optional: true,
      sidebar: distributionRemoteSidebar,
      label: {
        tKey: 'questionnaire.distribution:partner-children-remote.label',
      },

      label2: ({
        answerStore: {
          partnerFirstName,
          charitableGifts,
          charityPartnerGiftDetails,
        },
      }) => [
        {
          tKey: 'questionnaire.distribution:partner-children-remote.label2.part1',
          options: { partnerFirstName },
        },
        {
          term: {
            tKey: 'questionnaire.distribution:partner-children-remote.label2.part2.term',
          },
          definition: {
            tKey: 'questionnaire.distribution:partner-children-remote.label2.part2.definition',
          },
        },
        {
          tKey: `questionnaire.distribution:partner-children-remote.label2.part3.${
            charitableGifts === 'yes' || charityPartnerGiftDetails
              ? 'case1'
              : 'case2'
          }`,
        },
      ],

      subLabel: ({
        answerStore: { charitableGifts, charityPartnerGiftDetails },
      }) =>
        charitableGifts === 'yes' || charityPartnerGiftDetails
          ? {
              tKey: 'questionnaire.distribution:partner-children-remote.subLabel.case1',
            }
          : {
              tKey: 'questionnaire.distribution:partner-children-remote.subLabel.case2',
            },

      fields: [
        {
          name: 'remoteDistribution',
          type: 'text',
          reviewLabel: {
            tKey: 'questionnaire.distribution:partner-children-remote.fields.remoteDistribution.reviewLabel',
          },
        },
      ],
      trigger: {
        $and: [
          { hasPartner: 'yes' },
          { hasChildren: 'yes' },
          { partnerFirstName: { $exists: true } },
          {
            $or: [
              { charitableGifts: 'no' },
              {
                charitableGifts: 'yes',
                'charitableGiftDetails.0': { $exists: true },
              },
            ],
          },
        ],
      },
    },

    {
      id: 1573069118,
      pathname: 'partner-children-predeceased',
      type: 'predeceased',
      section,
      label: {
        tKey: 'questionnaire.distribution:partner-children-predeceased.label',
      },
      fields: [
        {
          name: 'predeceased',
          type: 'text',
          reviewLabel: {
            tKey: 'questionnaire.distribution:common.predeceasedFieldReviewLabel',
          },
        },
      ],
      trigger: {
        $and: [
          { hasPartner: 'yes' },
          { hasChildren: 'yes' },
          {
            $or: [
              // if there is more than one person named in the remoteDistribution
              {
                $and: [
                  {
                    remoteDistribution: {
                      $elemMatch: { type: 'person' },
                    },
                  },
                  {
                    'remoteDistribution.1': { $exists: true },
                  },
                ],
              },

              // if there is 1 person and 1 or more charities in remoteDistribution
              {
                $and: [
                  {
                    remoteDistribution: {
                      $elemMatch: { type: 'person' },
                    },
                  },
                  {
                    remoteDistribution: {
                      $elemMatch: { type: 'charity' },
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
    },

    {
      id: 1573069359,
      pathname: 'partner-children-specific-gifts',
      section,

      label: ({ answerStore: { partnerFirstName } }) => ({
        tKey: 'questionnaire.distribution:common.partnerSpecificGiftsLabel',
        options: { partnerFirstName },
      }),

      label2: { tKey: 'questionnaire.distribution:common.specificGiftsLabel2' },
      subLabel: {
        tKey: 'questionnaire.distribution:common.specificGiftsSubLabel',
      },
      fields: [
        {
          name: 'specificGifts',
          type: 'quickRadio',
          reviewLabel: {
            tKey: 'questionnaire.distribution:common.specificGiftsReviewLabel',
          },
          options: [
            { value: 'yes', label: { tKey: 'common:yes' } },
            { value: 'no', label: { tKey: 'common:no' } },
          ],
        },
      ],
      trigger: {
        $and: [
          { hasPartner: 'yes' },
          { hasChildren: 'yes' },
          { partnerFirstName: { $exists: true } },
        ],
      },
    },

    {
      id: 1606778662,
      pathname: 'partner-children-gift-details',
      type: 'gifts',
      section,
      label: { tKey: 'questionnaire.distribution:common.giftDetailsLabel' },
      subLabel: {
        tKey: 'questionnaire.distribution:common.giftDetailsSubLabel',
      },
      forbiddenTerms: [
        {
          allow: false,
          terms: ['$', 'dollar'],
          errMsg: {
            tKey: 'questionnaire.distribution:common.dollarForbiddenTermMessage',
          },
        },
        {
          allow: false,
          terms: [
            'bank account',
            'chequing',
            'checking',
            'savings',
            'investments',
          ],
          errMsg:
            'It looks like this may be a gift of money or investments. The Will you create with Epilogue can only include gifts of personal items such as jewellery or collectibles. If you would like to include gifts of money or investments for particular individuals, you should contact a lawyer to prepare a custom Will for you.',
        },
        {
          allow: false,
          terms: ['resp', 'registered edu'],
          errMsg:
            'RESPs are not dealt with in this section. You will be asked about them later.',
        },
        {
          allow: false,
          terms: [
            'rrsp',
            'rrif',
            'tfsa',
            'tax free sav',
            'tax-free sav',
            'registered retir',
          ],
          errMsg:
            'Registered accounts (like RRSPs, RRIFs, and TFSAs) are not usually dealt with in a Will. Your financial institution can help you name beneficiaries on these accounts or confirm whether you already have.',
        },
        {
          allow: false,
          terms: ['life insur'],
          errMsg:
            "Life insurance is not usually dealt with in a Will. Your insurance agent can confirm if you've already named beneficiaries on your policies.",
        },
        {
          allow: true,
          terms: ['home', 'house', 'condo', 'cottage', 'real estate', 'cabin'],
          errMsg:
            'It looks like this may be a gift of real estate. The Will you create with Epilogue can only include gifts of personal items such as jewellery or collectibles. If you would like to include gifts of real estate (or other assets of substantial value) for particular individuals, you should contact a lawyer to prepare a custom Will for you.',
        },
        {
          allow: false,
          terms: ['estate', 'assets', 'everything i own'],
          errMsg:
            'You should only list smaller personal items such as jewelry or collectibles. Under your Will, your estate/assets will go your partner (if they outlive you) or your children (if your partner is not alive).',
        },

        {
          allow: false,
          terms: [
            'compte bancaire',
            'chèque',
            'cheque',
            'épargne',
            'epargne',
            'investissements',
          ],
          errMsg:
            "Il semble que ce soit un don d'argent ou d'investissements. Le testament que vous créez avec Epilogue ne peut inclure que des dons d'objets personnels tels que des bijoux ou des objets de collection. Si vous souhaitez inclure des dons d'argent ou d'investissements pour des personnes spécifiques, vous devriez contacter un avocat pour préparer un testament personnalisé.",
        },
        {
          allow: false,
          terms: ['reee', 'éducation enregistrée', 'education enregistree'],
          errMsg:
            'Les REEE ne sont pas traités dans cette section. Vous en serez interrogé plus tard.',
        },
        {
          allow: false,
          terms: [
            'reer',
            'ferr',
            'celi',
            "épargne libre d'impôt",
            "epargne libre d'impot",
            'retraite enregistrée',
            'retraite enregistree',
          ],
          errMsg:
            'Les comptes enregistrés (comme les REER, FERR et CELI) ne sont généralement pas traités dans un testament. Votre institution financière peut vous aider à désigner des bénéficiaires sur ces comptes ou confirmer si vous en avez déjà.',
        },
        {
          allow: false,
          terms: ['assurance vie'],
          errMsg:
            "L'assurance vie n'est généralement pas traitée dans un testament. Votre agent d'assurance peut confirmer si vous avez déjà désigné des bénéficiaires sur vos polices.",
        },
        {
          allow: true,
          terms: ['maison', 'logement', 'chalet', 'immobilier', 'cabane'],
          errMsg:
            "Il semble que ce soit un don immobilier. Le testament que vous créez avec Epilogue ne peut inclure que des dons d'objets personnels tels que des bijoux ou des objets de collection. Si vous souhaitez inclure des dons immobiliers (ou d'autres biens de valeur substantielle) pour des personnes spécifiques, vous devriez contacter un avocat pour préparer un testament personnalisé.",
        },
        {
          allow: false,
          terms: [
            'succession',
            'biens',
            'tout ce que je possède',
            'tout ce que je possede',
          ],
          errMsg:
            "Vous ne devez lister que les petits objets personnels comme les bijoux ou les objets de collection. Selon votre testament, votre succession/biens iront à votre partenaire (s'il vous survit) ou à vos enfants (si votre partenaire n'est pas vivant).",
        },
      ],
      fields: [
        {
          name: 'giftDetails',
          type: 'text',
          reviewLabel: {
            tKey: 'questionnaire.distribution:common.giftDetailsReviewLabel',
          },
        },
      ],
      trigger: {
        $and: [
          { specificGifts: 'yes' },
          { hasPartner: 'yes' },
          { hasChildren: 'yes' },
        ],
      },
      sidebar: [
        distributionSidebarFragments.listAllAssets,
        {
          type: 'accordion',
          header: {
            tKey: 'questionnaire.distribution:partner-children-gift-details.sidebar.item2.header',
          },
          children: [
            distributionSidebarFragments.residueDefinition,
            {
              type: 'text',
              body: {
                tKey: 'questionnaire.distribution:partner-children-gift-details.sidebar.item2.children.item2.body',
              },
            },
          ],
        },
        distributionSidebarFragments.describeAssets,
        distributionSidebarFragments.noLongerOwnedAssets,
        distributionSidebarFragments.doNotIncludeAssets,
        distributionSidebarFragments.giftTaxAccountsAndLifeInsurance,
        {
          type: 'accordion',
          header: {
            tKey: 'questionnaire.distribution:partner-children-gift-details.sidebar.item7.header',
          },
          children: [
            {
              type: 'text',
              body: {
                tKey: 'questionnaire.distribution:partner-children-gift-details.sidebar.item7.children.item1.body',
              },
            },
            {
              type: 'text',
              body: {
                tKey: 'questionnaire.distribution:partner-children-gift-details.sidebar.item7.children.item2.body',
              },
            },
            {
              type: 'text',
              body: {
                tKey: 'questionnaire.distribution:partner-children-gift-details.sidebar.item7.children.item3.body',
              },
            },
          ],
        },
        {
          type: 'accordion',
          header: {
            tKey: 'questionnaire.distribution:partner-children-gift-details.sidebar.item8.header',
          },
          children: [
            {
              type: 'text',
              body: {
                tKey: 'questionnaire.distribution:partner-children-gift-details.sidebar.item8.children.item1.body',
              },
            },
            {
              type: 'text',
              body: {
                tKey: 'questionnaire.distribution:partner-children-gift-details.sidebar.item8.children.item2.body',
              },
            },
            {
              type: 'text',
              body: {
                tKey: 'questionnaire.distribution:partner-children-gift-details.sidebar.item8.children.item3.body',
              },
            },
          ],
        },
      ],
    },

    // -- 🇨🇦💎🧠🇨🇦💎🧠- NO PARTNER / HAS CHILDREN -😈🍀🎃😈🍀🎃 -- //
    {
      id: 1573069672,
      pathname: 'children-intro',
      type: 'intro',
      section,
      altProgressText: '',
      highlightPillText: {
        tKey: 'questionnaire.distribution:common.introHighlightPillText',
      },
      label: { tKey: 'questionnaire.distribution:common.introLabel' },
      label2: { tKey: 'questionnaire.distribution:children-intro.label2' },
      sidebar: distributionWithChildrenIntroSidebar,
      subLabel: ({ charityPartner }) => ({
        tKey: `questionnaire.distribution:children-intro.subLabel.case${!_isEmpty(charityPartner) ? '1' : '2'}`,
      }),

      fields: [
        {
          name: 'allAssetsToChildren',
          type: 'text',
          reviewLabel: {
            tKey: 'questionnaire.distribution:common.distributionIntroReviewLabel',
          },
          // an immutableValue can never be changed by the user
          immutableValue: {
            tKey: 'questionnaire.distribution:children-intro.fields.allAssetsToChildren.immutableValue',
          },
        },
      ],
      trigger: {
        $and: [{ hasPartner: 'no' }, { hasChildren: 'yes' }],
      },
    },

    {
      id: 1573069673,
      type: 'inheritanceAge',
      pathname: 'children-inheritance-age',
      section,
      sidebar: distributionInheritanceAgeSidebar,
      label: {
        tKey: 'questionnaire.distribution:children-inheritance-age.label',
      },
      subLabel: {
        tKey: 'questionnaire.distribution:children-inheritance-age.subLabel',
      },
      fields: [
        {
          name: 'inheritanceAge',
          type: 'text',
          reviewLabel: {
            tKey: 'questionnaire.distribution:children-inheritance-age.fields.inheritanceAge.reviewLabel',
          },
          clearAfterProvinceChange: true,
        },
      ],
      trigger: {
        $and: [{ hasPartner: 'no' }, { hasChildren: 'yes' }],
      },
    },

    {
      id: 1573069674,
      pathname: 'children-step-children-distribution',
      type: 'stepChildrenDistribution',
      section,
      label: {
        tKey: 'questionnaire.distribution:children-step-children-distribution.label',
      },
      label2: {
        tKey: 'questionnaire.distribution:children-step-children-distribution.label2',
      },
      fields: [
        {
          name: 'stepChildrenDistribution',
          type: 'text',
          reviewLabel: {
            tKey: 'questionnaire.distribution:children-step-children-distribution.fields.stepChildrenDistribution.reviewLabel',
          },
        },
      ],
      trigger: {
        $and: [
          { hasPartner: 'no' },
          { hasChildren: 'yes' },
          { hasStepChildren: 'yes' },
        ],
      },
      sidebar: [
        {
          type: 'accordion',
          header: {
            tKey: 'questionnaire.distribution:children-step-children-distribution.sidebar.item1.header',
          },
          children: [
            distributionSidebarFragments.stepChildDefinition,
            {
              type: 'text',
              body: {
                tKey: 'questionnaire.distribution:children-step-children-distribution.sidebar.item1.children.item2.body',
              },
            },
          ],
        },
        distributionSidebarFragments.stepChildrenRequired,
      ],
    },

    {
      id: 1591801326,
      pathname: 'children-charitable-gifts',
      section,
      label: charitableGiftsLabel,
      label2: { tKey: 'questionnaire.distribution:common.specificGiftsLabel2' },
      fields: [
        {
          name: 'charitableGifts',
          type: 'quickRadio',
          reviewLabel: {
            tKey: 'questionnaire.distribution:common.charitableGiftsReviewLabel',
          },
          options: [
            { value: 'yes', label: { tKey: 'common:yes' } },
            { value: 'no', label: { tKey: 'common:no' } },
          ],
        },
      ],
      trigger: {
        $and: [
          { hasPartner: 'no' },
          { hasChildren: 'yes' },
          { 'charityPartner.name': { $exists: false } },
        ],
      },
      // TAG: Will Power
      sidebar: willPowerSidebarFragments,
    },

    {
      id: 1591801338,
      pathname: 'children-charitable-gift-details',
      type: 'charitableGiftDetails',
      section,
      sidebar: distributionCharitableGiftDetailsSidebar,
      label: {
        tKey: 'questionnaire.distribution:children-charitable-gift-details.label',
      },
      label2: {
        tKey: 'questionnaire.distribution:children-charitable-gift-details.label2',
      },
      subLabel: {
        tKey: 'questionnaire.distribution:common.charitableGiftDetailsSubLabel',
      },
      interceptModal: [
        {
          label: ({ questionFragments }) => {
            const { charitableGiftDetails } = questionFragments
            return {
              tKey: 'questionnaire.distribution:common.childrenCharitableGiftDetailsInterceptModal.label.hasPartnerNo',
              options: {
                percentage: formatCharityInterceptModalPercentage(
                  charitableGiftDetails,
                ),
              },
            }
          },

          subLabel: {
            tKey: 'questionnaire.distribution:common.childrenCharitableGiftDetailsInterceptModal.subLabel',
          },
          primaryButtonLabel: {
            tKey: 'questionnaire.distribution:common.childrenCharitableGiftDetailsInterceptModal.primaryButtonLabel',
          },
          primaryButtonIcon: true,
          primaryButtonAction: {
            type: CONSTANTS.interceptActionTypes.NEXT_QUESTION,
          },
          secondaryButtonLabel: {
            tKey: 'questionnaire.distribution:common.childrenCharitableGiftDetailsInterceptModal.secondaryButtonLabel',
          },
          secondaryButtonAction: {
            type: CONSTANTS.interceptActionTypes.CLOSE_MODAL,
          },
          trigger: {
            $and: [
              { charitableGiftDetails: { $exists: true, $ne: [] } },
              {
                // Checks if percentage gifts equals 20 or more
                $where() {
                  return (
                    this.charitableGiftDetails
                      .filter((gift) => gift.isPercent)
                      .reduce(
                        (sum, gift) => sum + parseFloat(gift.amount),
                        0,
                      ) >= 20
                  )
                },
              },
            ],
          },
        },
      ],
      fields: [
        {
          name: 'charitableGiftDetails',
          type: 'text',
          reviewLabel: {
            tKey: 'questionnaire.distribution:common.charitableGiftDetailsReviewLabel',
          },
        },
      ],
      trigger: {
        $and: [
          { hasPartner: 'no' },
          { hasChildren: 'yes' },
          { charitableGifts: 'yes' },
          { 'charityPartner.name': { $exists: false } },
        ],
      },
    },

    {
      id: 1720120844,
      pathname: 'children-share-charitable-gift-details',
      section,
      label: {
        tKey: 'questionnaire.distribution:common.share-charitable-gift-details.label',
      },
      label2: commonShareCharitableGiftDetailsLabel2,
      fields: [
        {
          name: 'shareCharitableGiftDetails',
          type: 'quickRadio',
          reviewLabel: {
            tKey: 'questionnaire.distribution:common.share-charitable-gift-details.fields.shareCharitableGiftDetails.reviewLabel',
          },
          options: [
            {
              value: 'yes',
              label: { tKey: 'common:yes' },
              pill: { tKey: 'common:mostCommon' },
            },
            { value: 'no', label: { tKey: 'common:no' } },
          ],
        },
      ],
      trigger: {
        hasPartner: 'no',
        hasChildren: 'yes',
        charitableGifts: 'yes',
        'charityPartner.name': { $exists: false },
        'charitableGiftDetails.0': { $exists: true },
      },
    },

    {
      id: 1573069675,
      pathname: 'children-remote',
      section,
      type: 'remoteDistribution',
      optional: true,
      sidebar: distributionRemoteSidebar,
      label: { tKey: 'questionnaire.distribution:children-remote.label' },

      label2: ({
        answerStore: {
          partnerFirstName,
          charitableGifts,
          charityPartnerGiftDetails,
        },
      }) => [
        {
          tKey: 'questionnaire.distribution:children-remote.label2.part1',
          options: { partnerFirstName },
        },
        {
          term: {
            tKey: 'questionnaire.distribution:children-remote.label2.part2.term',
          },
          definition: {
            tKey: 'questionnaire.distribution:children-remote.label2.part2.definition',
          },
        },
        {
          tKey: `questionnaire.distribution:children-remote.label2.part3.${
            charitableGifts === 'yes' || charityPartnerGiftDetails
              ? 'case1'
              : 'case2'
          }`,
        },
      ],

      subLabel: ({
        answerStore: { charitableGifts, charityPartnerGiftDetails },
      }) =>
        charitableGifts === 'yes' || charityPartnerGiftDetails
          ? {
              tKey: 'questionnaire.distribution:children-remote.subLabel.case1',
            }
          : {
              tKey: 'questionnaire.distribution:children-remote.subLabel.case2',
            },

      fields: [
        {
          name: 'remoteDistribution',
          type: 'text',
          reviewLabel: {
            tKey: 'questionnaire.distribution:children-remote.fields.remoteDistribution.reviewLabel',
          },
        },
      ],
      trigger: {
        $and: [
          { hasPartner: 'no' },
          { hasChildren: 'yes' },

          {
            $or: [
              { charitableGifts: 'no' },
              {
                charitableGifts: 'yes',
                'charitableGiftDetails.0': { $exists: true },
              },
            ],
          },
        ],
      },
    },

    {
      id: 1573069676,
      pathname: 'children-predeceased',
      type: 'predeceased',
      section,
      label: { tKey: 'questionnaire.distribution:common.predeceasedLabel' },
      fields: [
        {
          name: 'predeceased',
          type: 'text',
          reviewLabel: {
            tKey: 'questionnaire.distribution:common.predeceasedFieldReviewLabel',
          },
        },
      ],
      trigger: {
        $and: [
          { hasPartner: 'no' },
          { hasChildren: 'yes' },
          {
            $or: [
              // if there is more than one person named in the remoteDistribution
              {
                $and: [
                  {
                    remoteDistribution: {
                      $elemMatch: { type: 'person' },
                    },
                  },
                  {
                    'remoteDistribution.1': { $exists: true },
                  },
                ],
              },

              // if there is 1 person and 1 or more charities in remoteDistribution
              {
                $and: [
                  {
                    remoteDistribution: {
                      $elemMatch: { type: 'person' },
                    },
                  },
                  {
                    remoteDistribution: {
                      $elemMatch: { type: 'charity' },
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
    },

    {
      id: 1573069677,
      pathname: 'children-specific-gifts',
      section,
      label: { tKey: 'questionnaire.distribution:common.specificGiftsLabel' },
      label2: { tKey: 'questionnaire.distribution:common.specificGiftsLabel2' },
      subLabel: {
        tKey: 'questionnaire.distribution:children-specific-gifts.subLabel',
      },
      fields: [
        {
          name: 'specificGifts',
          type: 'quickRadio',
          reviewLabel: {
            tKey: 'questionnaire.distribution:common.specificGiftsReviewLabel',
          },
          options: [
            { value: 'yes', label: { tKey: 'common:yes' } },
            { value: 'no', label: { tKey: 'common:no' } },
          ],
        },
      ],
      trigger: {
        $and: [{ hasPartner: 'no' }, { hasChildren: 'yes' }],
      },
    },

    {
      id: 1606778597,
      pathname: 'children-gift-details',
      type: 'gifts',
      section,
      label: { tKey: 'questionnaire.distribution:common.giftDetailsLabel' },
      subLabel: {
        tKey: 'questionnaire.distribution:common.giftDetailsSubLabel',
      },
      forbiddenTerms: [
        {
          allow: false,
          terms: ['$', 'dollar'],
          errMsg: {
            tKey: 'questionnaire.distribution:common.dollarForbiddenTermMessage',
          },
        },
        {
          allow: false,
          terms: [
            'bank account',
            'chequing',
            'checking',
            'savings',
            'investments',
          ],
          errMsg:
            'It looks like this may be a gift of money or investments. The Will you create with Epilogue can only include gifts of personal items such as jewellery or collectibles. If you would like to include gifts of money or investments for particular individuals, you should contact a lawyer to prepare a custom Will for you.',
        },
        {
          allow: false,
          terms: ['resp', 'registered edu'],
          errMsg:
            'RESPs are not dealt with in this section. You will be asked about them later.',
        },
        {
          allow: false,
          terms: [
            'rrsp',
            'rrif',
            'tfsa',
            'tax free sav',
            'tax-free sav',
            'registered retir',
          ],
          errMsg:
            'Registered accounts (like RRSPs, RRIFs, and TFSAs) are not usually dealt with in a Will. Your financial institution can help you name beneficiaries on these accounts or confirm whether you already have.',
        },
        {
          allow: false,
          terms: ['life insur'],
          errMsg:
            "Life insurance is not usually dealt with in a Will. Your insurance agent can confirm if you've already named beneficiaries on your policies.",
        },
        {
          allow: true,
          terms: ['home', 'house', 'condo', 'cottage', 'real estate', 'cabin'],
          errMsg:
            'It looks like this may be a gift of real estate. The Will you create with Epilogue can only include gifts of personal items such as jewellery or collectibles. If you would like to include gifts of real estate (or other assets of substantial value) for particular individuals, you should contact a lawyer to prepare a custom Will for you.',
        },
        {
          allow: false,
          terms: ['estate', 'assets', 'everything i own'],
          errMsg:
            'You should only list smaller personal items such as jewelry or collectibles. Under your Will, your estate/assets will go your partner (if they outlive you) or your children (if your partner is not alive).',
        },

        {
          allow: false,
          terms: [
            'compte bancaire',
            'chèque',
            'cheque',
            'épargne',
            'epargne',
            'investissements',
          ],
          errMsg:
            "Il semble que ce soit un don d'argent ou d'investissements. Le testament que vous créez avec Epilogue ne peut inclure que des dons d'objets personnels tels que des bijoux ou des objets de collection. Si vous souhaitez inclure des dons d'argent ou d'investissements pour des personnes spécifiques, vous devriez contacter un avocat pour préparer un testament personnalisé.",
        },
        {
          allow: false,
          terms: ['reee', 'éducation enregistrée', 'education enregistree'],
          errMsg:
            'Les REEE ne sont pas traités dans cette section. Vous en serez interrogé plus tard.',
        },
        {
          allow: false,
          terms: [
            'reer',
            'ferr',
            'celi',
            "épargne libre d'impôt",
            "epargne libre d'impot",
            'retraite enregistrée',
            'retraite enregistree',
          ],
          errMsg:
            'Les comptes enregistrés (comme les REER, FERR et CELI) ne sont généralement pas traités dans un testament. Votre institution financière peut vous aider à désigner des bénéficiaires sur ces comptes ou confirmer si vous en avez déjà.',
        },
        {
          allow: false,
          terms: ['assurance vie'],
          errMsg:
            "L'assurance vie n'est généralement pas traitée dans un testament. Votre agent d'assurance peut confirmer si vous avez déjà désigné des bénéficiaires sur vos polices.",
        },
        {
          allow: true,
          terms: ['maison', 'logement', 'chalet', 'immobilier', 'cabane'],
          errMsg:
            "Il semble que ce soit un don immobilier. Le testament que vous créez avec Epilogue ne peut inclure que des dons d'objets personnels tels que des bijoux ou des objets de collection. Si vous souhaitez inclure des dons immobiliers (ou d'autres biens de valeur substantielle) pour des personnes spécifiques, vous devriez contacter un avocat pour préparer un testament personnalisé.",
        },
        {
          allow: false,
          terms: [
            'succession',
            'biens',
            'tout ce que je possède',
            'tout ce que je possede',
          ],
          errMsg:
            "Vous ne devez lister que les petits objets personnels comme les bijoux ou les objets de collection. Selon votre testament, votre succession/biens iront à votre partenaire (s'il vous survit) ou à vos enfants (si votre partenaire n'est pas vivant).",
        },
      ],
      fields: [
        {
          name: 'giftDetails',
          type: 'text',
          reviewLabel: {
            tKey: 'questionnaire.distribution:common.giftDetailsReviewLabel',
          },
        },
      ],
      trigger: {
        $and: [
          { hasPartner: 'no' },
          { hasChildren: 'yes' },
          { specificGifts: 'yes' },
        ],
      },
      sidebar: [
        distributionSidebarFragments.listAllAssets,
        {
          type: 'accordion',
          header: {
            tKey: 'questionnaire.distribution:children-gift-details.sidebar.item2.header',
          },
          children: [
            distributionSidebarFragments.residueDefinition,
            {
              type: 'text',
              body: {
                tKey: 'questionnaire.distribution:children-gift-details.sidebar.item2.children.item2.body',
              },
            },
          ],
        },
        distributionSidebarFragments.describeAssets,
        distributionSidebarFragments.noLongerOwnedAssets,
        distributionSidebarFragments.doNotIncludeAssets,
        distributionSidebarFragments.giftTaxAccountsAndLifeInsurance,
        {
          type: 'accordion',
          header: {
            tKey: 'questionnaire.distribution:children-gift-details.sidebar.item7.header',
          },
          children: [
            {
              type: 'text',
              body: {
                tKey: 'questionnaire.distribution:children-gift-details.sidebar.item7.children.item1.body',
              },
            },
            {
              type: 'text',
              body: {
                tKey: 'questionnaire.distribution:children-gift-details.sidebar.item7.children.item2.body',
              },
            },
            {
              type: 'text',
              body: {
                tKey: 'questionnaire.distribution:children-gift-details.sidebar.item7.children.item3.body',
              },
            },
          ],
        },
        {
          type: 'accordion',
          header: {
            tKey: 'questionnaire.distribution:children-gift-details.sidebar.item8.header',
          },
          children: [
            {
              type: 'text',
              body: {
                tKey: 'questionnaire.distribution:children-gift-details.sidebar.item8.children.item1.body',
              },
            },
            {
              type: 'text',
              body: {
                tKey: 'questionnaire.distribution:children-gift-details.sidebar.item8.children.item2.body',
              },
            },
            {
              type: 'text',
              body: {
                tKey: 'questionnaire.distribution:children-gift-details.sidebar.item8.children.item3.body',
              },
            },
          ],
        },
      ],
    },

    // -- 🇨🇦💎🧠🇨🇦💎🧠- NO PARTNER / NO CHILDREN -😈🍀🎃😈🍀🎃 -- //
    {
      id: 1573070173,
      pathname: 'simple-intro',
      type: 'intro',
      section,
      altProgressText: '',
      highlightPillText: {
        tKey: 'questionnaire.distribution:common.introHighlightPillText',
      },
      label: { tKey: 'questionnaire.distribution:common.introLabel' },
      label2: { tKey: 'questionnaire.distribution:simple-intro.label2' },
      subLabel: { tKey: 'questionnaire.distribution:simple-intro.subLabel' },
      trigger: {
        $and: [
          { hasPartner: 'no' },
          { hasChildren: 'no' },
          {
            $or: [
              { hasStepChildren: { $exists: false } },
              { hasStepChildren: 'no' },
            ],
          },
        ],
      },
    },

    {
      id: 1591803354,
      pathname: 'simple-charitable-gifts',
      section,
      label: charitableGiftsLabel,
      label2: { tKey: 'questionnaire.distribution:common.specificGiftsLabel2' },
      subLabel: {
        tKey: 'questionnaire.distribution:simple-charitable-gifts.subLabel',
      },
      fields: [
        {
          name: 'charitableGifts',
          type: 'quickRadio',
          reviewLabel: {
            tKey: 'questionnaire.distribution:common.charitableGiftsReviewLabel',
          },
          options: [
            { value: 'yes', label: { tKey: 'common:yes' } },
            { value: 'no', label: { tKey: 'common:no' } },
          ],
        },
      ],
      trigger: {
        $and: [
          { hasPartner: 'no' },
          { hasChildren: 'no' },
          { 'charityPartner.name': { $exists: false } },
          {
            $or: [
              { hasStepChildren: { $exists: false } },
              { hasStepChildren: 'no' },
            ],
          },
        ],
      },
      // TAG: Will Power
      sidebar: willPowerSidebarFragments,
    },

    {
      id: 1591803359,
      pathname: 'simple-charitable-gift-details',
      type: 'charitableGiftDetails',
      section,
      sidebar: distributionCharitableGiftDetailsSidebar,
      label: {
        tKey: 'questionnaire.distribution:common.charitableGiftDetailsLabel',
      },
      label2: {
        tKey: 'questionnaire.distribution:common.charitableGiftDetailsLabel2',
      },
      subLabel: {
        tKey: 'questionnaire.distribution:common.charitableGiftDetailsSubLabel',
      },
      fields: [
        {
          name: 'charitableGiftDetails',
          type: 'text',
          reviewLabel: {
            tKey: 'questionnaire.distribution:common.charitableGiftDetailsReviewLabel',
          },
        },
      ],
      trigger: {
        $and: [
          { hasPartner: 'no' },
          { hasChildren: 'no' },
          { charitableGifts: 'yes' },
          { 'charityPartner.name': { $exists: false } },
          {
            $or: [
              { hasStepChildren: { $exists: false } },
              { hasStepChildren: 'no' },
            ],
          },
        ],
      },
    },

    {
      id: 1720121082,
      pathname: 'simple-share-charitable-gift-details',
      section,
      label: {
        tKey: 'questionnaire.distribution:common.share-charitable-gift-details.label',
      },
      label2: commonShareCharitableGiftDetailsLabel2,
      fields: [
        {
          name: 'shareCharitableGiftDetails',
          type: 'quickRadio',
          reviewLabel: {
            tKey: 'questionnaire.distribution:common.share-charitable-gift-details.fields.shareCharitableGiftDetails.reviewLabel',
          },
          options: [
            {
              value: 'yes',
              label: { tKey: 'common:yes' },
              pill: { tKey: 'common:mostCommon' },
            },
            { value: 'no', label: { tKey: 'common:no' } },
          ],
        },
      ],
      trigger: {
        hasPartner: 'no',
        hasChildren: 'no',
        charitableGifts: 'yes',
        'charityPartner.name': { $exists: false },
        'charitableGiftDetails.0': { $exists: true },
        $or: [
          { hasStepChildren: { $exists: false } },
          { hasStepChildren: 'no' },
        ],
      },
    },

    {
      id: 1573070174,
      pathname: 'simple-remote',
      section,
      type: 'remoteDistribution',
      sidebar: distributionRemoteSidebar,
      label: ({
        answerStore: { charitableGifts, charityPartnerGiftDetails },
      }) =>
        charitableGifts === 'yes' || charityPartnerGiftDetails
          ? {
              tKey: 'questionnaire.distribution:simple-remote.label.case1',
            }
          : {
              tKey: 'questionnaire.distribution:simple-remote.label.case2',
            },

      subLabel: ({
        answerStore: { charitableGifts, charityPartnerGiftDetails },
      }) =>
        charitableGifts === 'yes' || charityPartnerGiftDetails
          ? {
              tKey: 'questionnaire.distribution:simple-remote.subLabel.case1',
            }
          : {
              tKey: 'questionnaire.distribution:simple-remote.subLabel.case2',
            },

      fields: [
        {
          name: 'remoteDistribution',
          type: 'text',
          reviewLabel: {
            tKey: 'questionnaire.distribution:common.distributionIntroReviewLabel',
          },
        },
      ],
      trigger: {
        $and: [
          { hasPartner: 'no' },
          { hasChildren: 'no' },
          {
            $or: [
              { charitableGifts: 'no' },
              {
                charitableGifts: 'yes',
                'charitableGiftDetails.0': { $exists: true },
              },
            ],
          },
          {
            $or: [
              { hasStepChildren: { $exists: false } },
              { hasStepChildren: 'no' },
            ],
          },
        ],
      },
    },

    {
      id: 1573070175,
      pathname: 'simple-predeceased',
      section,
      type: 'predeceased',
      label: { tKey: 'questionnaire.distribution:simple-predeceased.label' },
      fields: [
        {
          name: 'predeceased',
          type: 'text',
          reviewLabel: {
            tKey: 'questionnaire.distribution:common.predeceasedFieldReviewLabel',
          },
        },
      ],
      trigger: {
        $and: [
          { hasPartner: 'no' },
          { hasChildren: 'no' },
          {
            $or: [
              { hasStepChildren: { $exists: false } },
              { hasStepChildren: 'no' },
            ],
          },
          {
            $or: [
              // if there is more than one person named in the remoteDistribution
              {
                $and: [
                  {
                    remoteDistribution: {
                      $elemMatch: { type: 'person' },
                    },
                  },
                  {
                    'remoteDistribution.1': { $exists: true },
                  },
                ],
              },

              // if there is 1 person and 1 or more charities in remoteDistribution
              {
                $and: [
                  {
                    remoteDistribution: {
                      $elemMatch: { type: 'person' },
                    },
                  },
                  {
                    remoteDistribution: {
                      $elemMatch: { type: 'charity' },
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
    },

    {
      id: 1576857184,
      pathname: 'simple-predeceased-backup',
      section,
      type: 'predeceasedBackup',

      label: ({ answerStore: { remoteDistribution } }) => ({
        tKey: 'questionnaire.distribution:common.predeceasedBackupLabel',
        options: { name: remoteDistribution[0].name },
      }),

      fields: [
        {
          name: 'predeceasedBackup',
          type: 'text',
          reviewLabel: {
            tKey: 'questionnaire.distribution:common.predeceasedFieldReviewLabel',
          },
        },
      ],
      trigger: {
        $and: [
          { hasPartner: 'no' },
          { hasChildren: 'no' },
          {
            $or: [
              { hasStepChildren: { $exists: false } },
              { hasStepChildren: 'no' },
            ],
          },

          // if there is only one PERSON in the remote distribution
          {
            remoteDistribution: {
              $elemMatch: { type: 'person' },
            },
          },
          {
            'remoteDistribution.1': { $exists: false },
          },
        ],
      },
    },

    {
      id: 1573070176,
      type: 'inheritanceAge',
      pathname: 'simple-inheritance-age',
      section,
      label: { tKey: 'questionnaire.distribution:common.inheritanceAgeLabel' },
      subLabel: {
        tKey: 'questionnaire.distribution:common.inheritanceAgeSubLabel',
      },
      fields: [
        {
          name: 'inheritanceAge',
          type: 'text',
          reviewLabel: {
            tKey: 'questionnaire.distribution:common.inheritanceAgeReviewLabel',
          },
          clearAfterProvinceChange: true,
        },
      ],
      trigger: {
        $and: [
          { hasPartner: 'no' },
          { hasChildren: 'no' },
          {
            $or: [
              { hasStepChildren: { $exists: false } },
              { hasStepChildren: 'no' },
            ],
          },
          {
            // at least one PERSON in the remote distribution question
            remoteDistribution: {
              $elemMatch: { type: 'person' },
            },
          },
        ],
      },
    },

    {
      id: 1573070177,
      pathname: 'simple-specific-gifts',
      section,
      label: { tKey: 'questionnaire.distribution:common.specificGiftsLabel' },
      label2: { tKey: 'questionnaire.distribution:common.specificGiftsLabel2' },
      subLabel: {
        tKey: 'questionnaire.distribution:simple-specific-gifts.subLabel',
      },
      fields: [
        {
          name: 'specificGifts',
          type: 'quickRadio',
          reviewLabel: {
            tKey: 'questionnaire.distribution:common.specificGiftsReviewLabel',
          },
          options: [
            { value: 'yes', label: { tKey: 'common:yes' } },
            { value: 'no', label: { tKey: 'common:no' } },
          ],
        },
      ],
      trigger: {
        $and: [
          { hasPartner: 'no' },
          { hasChildren: 'no' },
          {
            $or: [
              { hasStepChildren: { $exists: false } },
              { hasStepChildren: 'no' },
            ],
          },
        ],
      },
    },

    {
      id: 1606777821,
      pathname: 'simple-gift-details',
      type: 'gifts',
      section,
      label: { tKey: 'questionnaire.distribution:common.giftDetailsLabel' },
      subLabel: {
        tKey: 'questionnaire.distribution:common.giftDetailsSubLabel',
      },
      sidebar: distributionSpecificGiftDetailsSidebar,
      forbiddenTerms: [
        {
          allow: false,
          terms: [
            'rrsp',
            'rrif',
            'tfsa',
            'tax free sav',
            'tax-free sav',
            'registered retir',
          ],
          errMsg:
            'Registered accounts (like RRSPs, RRIFs, and TFSAs) are not usually dealt with in a Will. Your financial institution can help you name beneficiaries on these accounts or confirm whether you already have.',
        },
        {
          allow: false,
          terms: [
            'reer',
            'ferr',
            'celi',
            "épargne libre d'impôt",
            "epargne libre d'impot",
            "épargne libre-d'impôt",
            "epargne libre-d'impot",
            'retraite enregistrée',
            'retraite enregistree',
          ],
          errMsg:
            'Les comptes enregistrés (comme les REER, FERR et CELI) ne sont généralement pas traités dans un testament. Votre institution financière peut vous aider à désigner des bénéficiaires sur ces comptes ou confirmer si vous en avez déjà.',
        },

        {
          allow: false,
          terms: ['life insur'],
          errMsg:
            "Life insurance is not usually dealt with in a Will. Your insurance agent can confirm if you've already named beneficiaries on your policies.",
        },
        {
          allow: false,
          terms: ['assurance vie'],
          errMsg:
            "L'assurance vie n'est généralement pas traitée dans un testament. Votre agent d'assurance peut confirmer si vous avez déjà désigné des bénéficiaires sur vos polices.",
        },

        {
          allow: false,
          terms: ['estate', 'assets', 'everything i own'],
          errMsg:
            "You should only list smaller personal items such as jewelry or collectibles. If you'd like to leave a portion of your estate/assets to a specific person, please go back and do this in the distribtion question.",
        },
        {
          allow: false,
          terms: [
            'succession',
            'biens',
            'tout ce que je possède',
            'tout ce que je possede',
          ],
          errMsg:
            'Vous ne devez lister que les petits objets personnels comme les bijoux ou les objets de collection. Si vous souhaitez léguer une partie de votre succession/biens à une personne spécifique, veuillez revenir en arrière et le faire dans la question de distribution.',
        },
      ],
      fields: [
        {
          name: 'giftDetails',
          type: 'text',
          reviewLabel: {
            tKey: 'questionnaire.distribution:common.giftDetailsReviewLabel',
          },
        },
      ],
      trigger: {
        $and: [
          { specificGifts: 'yes' },
          { hasPartner: 'no' },
          { hasChildren: 'no' },
          {
            $or: [
              { hasStepChildren: { $exists: false } },
              { hasStepChildren: 'no' },
            ],
          },
        ],
      },
    },

    // universal question for all distribution paths
    {
      id: 1572360389,
      pathname: 'pet-guardian-amount',
      section,
      optional: true,
      label: { tKey: 'questionnaire.distribution:pet-guardian-amount.label' },
      subLabel: {
        tKey: 'questionnaire.distribution:pet-guardian-amount.subLabel',
      },
      fields: [
        {
          name: 'petGuardianAmount',
          type: 'range',
          reviewLabel: {
            tKey: 'questionnaire.distribution:pet-guardian-amount.fields.petGuardianAmount.reviewLabel',
          },
          min: 0,
          max: 50000,
          step: 500,
          format: '$',
        },
      ],
      trigger: {
        $and: [{ hasPets: 'yes' }, { petGuardianName: { $exists: true } }],
      },
    },

    {
      id: 1572899916,
      pathname: 'review',
      type: 'sectionReview',
      section,
      label: { tKey: 'questionnaire.distribution:review.label' },
      subLabel: { tKey: 'questionnaire.distribution:review.subLabel' },
    },
  ],
}
