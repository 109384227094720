import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import _shuffle from 'lodash/shuffle'

import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { Box, Container } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import { useTranslation } from 'react-i18next'

import { ReactComponent as Clouds } from '../../../../../common/assets/images/clouds.svg'
import Button from '../../../../../common/components/atoms/Button'
import Heading from '../../../../../common/components/atoms/Heading'
import RadioGroup from '../../../../../common/components/atoms/RadioGroup'
import Text from '../../../../../common/components/atoms/Text'
import useBeforeUnload from '../../../../../common/hooks/useBeforeUnload'
import amplitude from '../../../../../common/lib/amplitude'
import { errorHandler, PATHS, request } from '../../../../../common/request'
// TAG: TD setup
import questionnaireSelectors from '../../../../Questionnaire/ducks/questionnaire/questionnaireSelectors'
import dashboardActions from '../../../ducks/dashboard/dashboardActions'
import dashboardSelectors from '../../../ducks/dashboard/dashboardSelectors'

const useStyles = makeStyles((theme) => ({
  pageContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  image: {
    marginBottom: '2rem',
  },

  radioButtons: {
    margin: '2rem 0 1.8rem 2.8rem',
    '& > div': {
      display: 'grid',
      columnGap: '2rem',
      gridTemplateColumns: '1fr 1fr',
      gap: '0.75rem',
    },
    [theme.breakpoints.down('md')]: {
      marginLeft: '0.8rem',
    },
    '& span': {
      fontWeight: 'bold',
    },
  },
}))

const skipValue = 'skip'
const dropOffValue = 'dropOff'

// TAG: TD setup
const howDidYouHearAboutUsOptions = (ref, t) => {
  const bankingItem =
    ref === 'td'
      ? {
          value: 'td',
          label: t('pages:HowDidYouHearAboutUs.options.td.label'),
        }
      : {
          value: 'rbc',
          label: t('pages:HowDidYouHearAboutUs.options.rbc.label'),
        }

  return _shuffle([
    bankingItem,
    {
      value: 'articleOrBlog',
      label: t('pages:HowDidYouHearAboutUs.options.articleOrBlog.label'),
    },
    {
      value: 'googleSearch',
      label: t('pages:HowDidYouHearAboutUs.options.googleSearch.label'),
    },
    {
      value: 'socialMedia',
      label: t('pages:HowDidYouHearAboutUs.options.socialMedia.label'),
    },
    {
      value: 'radioPodcast',
      label: t('pages:HowDidYouHearAboutUs.options.radioPodcast.label'),
    },
    {
      value: 'friendFamily',
      label: t('pages:HowDidYouHearAboutUs.options.friendFamily.label'),
    },
  ])
}

const HowDidYouHearAboutUs = ({
  // TAG: TD setup
  referrer,
  howDidYouHearAboutUs,
  dispatchSetUserProperties,
}) => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const hdyhauOptions = howDidYouHearAboutUsOptions(referrer?.ref, t)

  useEffect(() => {
    /*
     * Redirect the user to /dashboard if they already have a
     * value for 'howDidYouHearAboutUs' in the Redux store.
     */
    if (howDidYouHearAboutUs) navigate('/dashboard')

    amplitude.sendEvent('ViewedHowDidYouHearAboutUs', {
      // TAG: TD setup
      hdyhauOptions: hdyhauOptions.map((option) => option.value).join(', '),
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useBeforeUnload(() => {
    if (!howDidYouHearAboutUs) {
      const blobData = new Blob([JSON.stringify({ value: dropOffValue })], {
        type: 'application/json',
      })

      navigator.sendBeacon(`/api${PATHS.HOW_DID_YOU_HEAR_ABOUT_US}`, blobData)
    }
  })

  const setHowDidYouHearAboutUsSelection = async (value) => {
    try {
      await request({
        method: 'POST',
        url: PATHS.HOW_DID_YOU_HEAR_ABOUT_US,
        data: { value },
      })
    } catch (error) {
      errorHandler({
        error,
        reportError: true,
      })
    } finally {
      dispatchSetUserProperties({ howDidYouHearAboutUs: value })
      amplitude.sendEvent('SelectedHowDidYouHearAboutUs', {
        value,
      })
      navigate('/dashboard/add-ons/printing')
    }
  }

  const classes = useStyles()

  return (
    <Container className={classes.pageContainer}>
      <Clouds className={classes.image} />

      <Box textAlign="center">
        <Box pb="1.1rem">
          <Heading variant="h3">
            {t('pages:HowDidYouHearAboutUs.label')}
          </Heading>
        </Box>

        <Heading variant="h3">{t('pages:HowDidYouHearAboutUs.label2')}</Heading>

        <Box pt="1.1rem">
          <Text variant="paragraph">
            {t('pages:HowDidYouHearAboutUs.subLabel')}
          </Text>
        </Box>
      </Box>

      <RadioGroup
        onChange={setHowDidYouHearAboutUsSelection}
        name="howDidYouHearAboutUs"
        className={classes.radioButtons}
        // TAG: TD setup
        radioButtons={hdyhauOptions}
      />

      <Button
        label={t('pages:HowDidYouHearAboutUs.buttonLabel')}
        size="xxs"
        endIcon={<ArrowForwardIcon />}
        onClick={() => setHowDidYouHearAboutUsSelection(skipValue)}
      />
    </Container>
  )
}

HowDidYouHearAboutUs.propTypes = {
  howDidYouHearAboutUs: PropTypes.string,
  dispatchSetUserProperties: PropTypes.func.isRequired,
  // TAG: TD setup
  referrer: PropTypes.shape({ ref: PropTypes.string }).isRequired,
}

const mapStateToProps = (state) => ({
  howDidYouHearAboutUs: dashboardSelectors.getHowDidYouHearAboutUs(
    state.dashboard,
  ),
  // TAG: TD setup
  referrer: questionnaireSelectors.getReferrer(state.questionnaire),
})

const mapDispatchToProps = (dispatch) => ({
  dispatchSetUserProperties: (properties) =>
    dispatch(dashboardActions.setUserProperties(properties)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(HowDidYouHearAboutUs)
